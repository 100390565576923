import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import React from 'react'
import { clone } from '../../../common/helpers/clone'
import { Option, Uploads } from '../../../common/models'
import { getDefaultUploads } from './defaultEditors'
import DocumentUpload from './DocumentUpload'

interface Props {
  caasProfileKey: string
  uploads: Uploads[]
  documentTypeKeys: Option[]
  canAddOrRemoveUpload: boolean
  validateAll?: boolean
  edit?: boolean
  onChange(uploads: Uploads[], areValid: boolean[]): void
}

interface State {
  uploads: Uploads[]
  selectedUploadOption: carrierAccounts.models.UploadType
  areValid: boolean[]
}

export class UploadsEditContainer extends React.Component<Props, State> {
  public readonly state = {
    uploads: clone(this.props.uploads),
    selectedUploadOption: 'email' as carrierAccounts.models.UploadType,
    areValid: [] as boolean[],
  }

  public render() {
    return (
      <DocumentUpload
        caasProfileKey={this.props.caasProfileKey}
        edit={this.props.edit || false}
        uploads={this.state.uploads}
        onUploadChange={this.onUploadChange}
        onRemoveUpload={
          this.props.canAddOrRemoveUpload ? this.onRemoveUpload : undefined
        }
        documentTypeKeys={this.props.documentTypeKeys}
        selectedUploadOption={this.state.selectedUploadOption}
        changeSelectedUpload={this.changeSelectedUpload}
        addUpload={this.props.canAddOrRemoveUpload ? this.addUpload : undefined}
        validateAll={this.props.validateAll}
      />
    )
  }

  private onUploadChange = (
    upload: Uploads,
    index: number,
    isValid: boolean
  ) => {
    const updatedValid = this.state.areValid.slice()
    updatedValid[index] = isValid

    this.setState(state => {
      state.uploads[index] = upload

      this.props.onChange(state.uploads, updatedValid)

      return { uploads: state.uploads, areValid: updatedValid }
    })
  }

  private onRemoveUpload = (index: number) => {
    this.setState(state => {
      const uploads = state.uploads.filter((_, i) => index !== i)
      const areValid = state.areValid.filter((_, i) => index !== i)
      this.props.onChange(uploads, areValid)

      return {
        uploads,
        areValid,
      }
    })
  }

  private changeSelectedUpload = (option: Option) => {
    this.setState({
      selectedUploadOption: option.value as carrierAccounts.models.UploadType,
    })
  }

  private addUpload = () => {
    this.setState(state => {
      const uploads = [
        ...state.uploads,
        getDefaultUploads(this.state.selectedUploadOption),
      ]

      const areValid = [...this.state.areValid, false]
      this.props.onChange(uploads, areValid)

      return {
        uploads,
        areValid,
      }
    })
  }
}
