import { centralizedAuth } from '@cimpress/simple-auth-wrapper'
import * as logger from './primitiveLogger'

export const CLIENT_ID = 'iidGCVqKzo6O2yuApIkp9tUbzKWQpHxY'
const auth = new centralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: '/',
  emitInitialTokenExpired: false,
  expirationOffset: 60, // fire expiration event 60 seconds before the token expires
})

export function onTokenExpired(callback: () => void) {
  auth.on('tokenExpired', callback)
}

export default auth

export function bearerToken() {
  if (authorizationState.tokenExpired) {
    logger.log({
      message: 'User token expired',
      user: auth.getProfile()?.email,
      level: 50,
    })
    throw new Error('User token expired')
  }

  return `Bearer ${auth.getAccessToken()}`
}

const authorizationState = {
  tokenExpired: false,
  authorizing: false,
  authorized: false,
}

const onAuthorizationStateChangedCallbacks: Array<(
  authorized: boolean
) => void> = []

export function subscribeOnAuthorizationStateChanged(
  callback: (authorized: boolean) => void
) {
  onAuthorizationStateChangedCallbacks.push(callback)
}

function onAuthorizationSateChanged() {
  onAuthorizationStateChangedCallbacks.forEach(callback =>
    callback(authorizationState.authorized)
  )
}

export async function ensureUserLogin() {
  if (!authorizationState.authorized) {
    onTokenExpired(() => {
      authorizationState.tokenExpired = true
      ensureUserLogin()
    })
    if (!auth.isLoggedIn()) {
      await auth
        .ensureAuthentication({
          nextUri: window.location.pathname + window.location.search,
        })
        .then(async (authorized: boolean) => {
          authorizationState.authorized = authorized
          onAuthorizationSateChanged()
        })
        .catch((err: any) => {
          logger.log({
            message: 'Authorization error on application load',
            user: auth.getProfile()?.email,
            error: err,
            level: 50,
          })
        })
    } else {
      authorizationState.authorized = true
      onAuthorizationSateChanged()
      await forceTokenRenewal()
    }
  } else {
    if (authorizationState.tokenExpired) {
      await forceTokenRenewal()
    }
  }
}

async function forceTokenRenewal(): Promise<void> {
  const tokenRenewed = await auth.ensureAuthentication({
    nextUri: window.location.pathname + window.location.search,
    forceLogin: true,
  })
  if (tokenRenewed === false) {
    logger.log({
      message: 'Error refreshing user token',
      user: auth.getProfile()?.email,
      level: 50,
    })
  }
  authorizationState.tokenExpired = !tokenRenewed
  authorizationState.authorized = tokenRenewed
  onAuthorizationSateChanged()
}
