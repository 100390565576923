import * as React from 'react'
import Preloader from './components/Preloader'
import Fragment from './components/Fragment'
import { config } from './config/config'
import DevelopmentFragment from './components/DevelopmentFragment'

interface Props {
  src: string
}

export default function FragmentLoader(props: Props) {
  return config.logisticsEnvironment === 'fragments' ? (
    <DevelopmentFragment />
  ) : (
    <Fragment src={props.src} fallback={<Preloader />} />
  )
}
