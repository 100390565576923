import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as models from '../../../common/models'

export default function PrimaryContact(props: { contact?: models.Contact }) {
  const { t } = useTranslation()
  let contactInfo
  if (
    !props.contact ||
    (!props.contact.name && !props.contact.phone && !props.contact.email)
  ) {
    contactInfo = <>{t('locations.contact.noContact')}</>
  } else {
    const contactName = props.contact.name ? (
      <>
        {props.contact.name}
        <br />
      </>
    ) : (
      ''
    )

    const email = props.contact.email ? (
      <p style={{ marginBottom: '8px' }}>
        <a href={'mailto:' + props.contact.email}>
          <i className="fa fa-fw fa-envelope" /> {props.contact.email}
        </a>
      </p>
    ) : (
      ''
    )

    const phone = props.contact.phone ? (
      <p>
        <a href={'tel:' + props.contact.phone}>
          <i className="fa fa-fw fa-phone" /> {props.contact.phone}
        </a>
      </p>
    ) : (
      ''
    )

    contactInfo = (
      <>
        {contactName}
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          {email}
          {phone}
        </div>
      </>
    )
  }

  return (
    <>
      <h5 style={{ marginBottom: '10px' }}>
        {t('locations.contact.primaryContact')}
      </h5>
      {contactInfo}
    </>
  )
}
