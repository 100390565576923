import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import { clone } from '../../helpers/clone'
import { IsHidden, Uploads } from '../../models'
import { EnhancedCaasProfile } from '../../models-carriers'
import * as proxy from '../carrier-services-proxy'

export function addDefaultUploadsToCaasProfile(
  profile: proxy.ApiCaasProfile
): EnhancedCaasProfile {
  const { documentUploadMethods, ...profileWithoutUploads } = clone(profile)

  const updatedProfile: EnhancedCaasProfile = { ...profileWithoutUploads }
  if (updatedProfile.sequences && updatedProfile.documents) {
    for (const sequence of updatedProfile.sequences) {
      sequence._metadata.isDocumentSequence = updatedProfile.documents.some(d =>
        (d.sequenceKeys || []).some(key => key === sequence.key)
      )
    }
  }

  if (profile.documentUploadMethods) {
    updatedProfile.defaultDocumentUploads = []
    for (const configuration of profile.documentUploadMethods) {
      const mappedUploads = mapUpload(
        configuration.uploadMethods,
        configuration.documentTypeKeys
      )
      updatedProfile.defaultDocumentUploads = updatedProfile.defaultDocumentUploads.concat(
        mappedUploads
      )
    }
  }

  return updatedProfile
}

export function hideSharedSequences(
  profile: EnhancedCaasProfile
): EnhancedCaasProfile {
  if (!profile.sequences || !profile.sharedSequences) {
    return profile
  }

  const { sequences, ...restOfProfile } = clone(profile)

  return {
    ...restOfProfile,
    sequences: sequences!.map(seq => ({
      ...seq,
      _metadata: {
        isShared: profile.sharedSequences![seq.key] !== undefined,
        isDocumentSequence: seq._metadata.isDocumentSequence,
      },
    })),
  }
}

function mapUpload(
  methods: proxy.DefaultUploadMethods,
  documentTypeKeys: string[]
): Uploads[] {
  return new Array<Uploads>(
    ...(methods.email || []).map(x => mapEmailUpload(x, documentTypeKeys)),
    ...(methods.elms || []).map(x =>
      mapElmsPldUpload(x, 'elms', documentTypeKeys)
    ),
    ...(methods.pld || []).map(x =>
      mapElmsPldUpload(x, 'pld', documentTypeKeys)
    ),

    ...(methods.ftp || []).map(x => mapFtpUpload(x, documentTypeKeys))
  )
}

function mapFtpUpload(
  upload: proxy.DefaultFtpConfig,
  documentTypeKeys: string[]
): carrierAccounts.models.FtpUpload & IsHidden {
  const uploadType = determineFtpUploadType(upload)

  return {
    uploadType,
    documentTypes: documentTypeKeys,
    runInParallel: upload.runInParallel || false,
    host: upload.host || '',
    username: upload.username || '',
    password: upload.password || '',
    directory: upload.directory || '',
    port: upload.port || uploadType === 'sftp' ? 22 : 21,
    afterUploadMoveToDirectory: upload.moveToDirectory || '',
    authenticationMethod: 'password',
    isHidden: upload.isHidden,
  }
}

function mapElmsPldUpload(
  upload: proxy.DefaultElmsPldConfig,
  uploadType: 'elms' | 'pld',
  documentTypeKeys: string[]
): (carrierAccounts.models.ElmsUpload | carrierAccounts.models.PldUpload) &
  IsHidden {
  if (uploadType === 'elms') {
    return {
      documentTypes: documentTypeKeys,
      uploadType,
      url: upload.url || '',
      username: upload.username || '',
      password: upload.password || '',
      runInParallel: upload.runInParallel || false,
      isHidden: upload.isHidden,
    }
  } else {
    return {
      documentTypes: documentTypeKeys,
      uploadType,
      url: upload.url || '',
      username: upload.username || '',
      password: upload.password || '',
      runInParallel: upload.runInParallel || false,
      isHidden: upload.isHidden,
    }
  }
}

function mapEmailUpload(
  upload: proxy.DefaultEmailConfig,
  documentTypeKeys: string[]
): carrierAccounts.models.EmailUpload & IsHidden {
  const receivers: carrierAccounts.models.Receivers = {
    to: [],
    cc: [],
    bcc: [],
  }
  const result: carrierAccounts.models.EmailUpload & IsHidden = {
    uploadType: 'email',
    subject: upload.subject || '',
    template: upload.template || '',
    runInParallel: upload.runInParallel || false,
    documentTypes: documentTypeKeys,
    isHidden: upload.isHidden,
    receivers,
  }

  if (upload.receivers) {
    receivers.to = upload.receivers.to || []
    receivers.cc = upload.receivers.cc || []
    receivers.bcc = upload.receivers.bcc || []
    result.receivers = receivers
  }

  return result
}

function determineFtpUploadType(
  upload: proxy.DefaultFtpConfig
): 'ftp' | 'ftps' | 'sftp' {
  if (upload.secure) {
    return 'sftp'
  }
  if (upload.ftpType) {
    return 'ftps'
  }

  return 'ftp'
}
