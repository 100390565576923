import * as React from 'react'
import styles from './PickupTimeEditor.module.css'

interface PickupTimeEditorProps {
  value: string
  disabled?: boolean
  onChange(time: string): void
}

export function PickupTimeEditor(props: PickupTimeEditorProps) {
  const onTimeChange = (event: React.FormEvent<HTMLInputElement>) => {
    props.onChange(event.currentTarget.value)
  }

  return (
    <span className={styles['pickup-time-editor']}>
      <input
        type="time"
        className="form-control"
        id="timepicker"
        onChange={onTimeChange}
        value={props.value}
        required={true}
      />
    </span>
  )
}
