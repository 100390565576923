import * as React from 'react'
import moment from 'moment'
import { colors } from '@cimpress/react-components'
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle'
import { locations } from '@cimpress-technology/logistics-configuration-client'
import { useTranslation } from 'react-i18next'
import { groupBy } from 'underscore'
import { AdvancedUserContainer } from '../../../common/components/AdvancedUserContainer'
import OpenInNewTab from '../../../common/components/OpenInNewTab'
import Preloader from '../../../common/components/Preloader'
import { isActive } from '../../../common/hooks/useNotifications'
import CreateNotificationModal from './CreateNotificationModal'
import styles from './Notifications.module.css'

interface Props {
  loading: boolean
  notifications: locations.models.Notification[]
  dismissUnreads: () => Promise<void>
  createNotification: (
    notification: Omit<locations.models.CreateNotification, 'locationId'>
  ) => Promise<void>
}

export default function Notifications(props: Props) {
  const [
    showAddNotificationModal,
    setShowAddNotificationModal,
  ] = React.useState(false)

  const { t } = useTranslation()

  React.useEffect(() => {
    const dismiss = async () => {
      await props.dismissUnreads()
    }

    dismiss()
  }, [props])

  if (props.loading) {
    return <Preloader />
  }

  const groupByDay = Object.entries(
    groupBy(props.notifications, n => moment(n.createdAt).format('YYYY-MM-DD'))
  ).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime())

  const showModal = () => setShowAddNotificationModal(true)

  const closeModal = () => setShowAddNotificationModal(false)

  return (
    <div>
      <div className={`${styles.withButtonOnRight}`}>
        <AdvancedUserContainer enableTooltip={true}>
          <button
            name="save"
            className="btn btn-primary"
            type="submit"
            onClick={showModal}
          >
            {t('locations.notifications.addNotification')}
          </button>
        </AdvancedUserContainer>
      </div>
      {groupByDay.map(([day, notifications]) => (
        <div key={day}>
          <div className={`h7 ${styles.timestamp}`}>
            {moment(day).fromNow().toLocaleUpperCase()}
          </div>
          {notifications
            .sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            )
            .map(n => (
              <Notification key={n.id} notification={n} />
            ))}
        </div>
      ))}
      {showAddNotificationModal && (
        <CreateNotificationModal
          createNotification={props.createNotification}
          onClose={closeModal}
        />
      )}
    </div>
  )
}

interface NotificationProps {
  notification: locations.models.Notification
}

function Notification({ notification }: NotificationProps) {
  const [active] = React.useState(isActive(notification))

  const actionLink = notification.actionLink ? (
    <OpenInNewTab href={notification.actionLink.url}>
      {notification.actionLink.text || 'Go to link'}
    </OpenInNewTab>
  ) : null

  return (
    <div className={`${styles.notification} ${active ? styles.active : ''}`}>
      <NotificationIcon type={notification.type} />
      <div>{notification.content}</div>
      {actionLink}
    </div>
  )
}

function NotificationIcon(props: {
  type: locations.models.Notification['type']
}) {
  switch (props.type) {
    case 'alert':
      return <IconAlertTriangle color={colors.danger.base} weight="fill" />
    case 'warning':
      return <IconAlertTriangle color={colors.warning.base} weight="fill" />
    default:
      return null
  }
}
