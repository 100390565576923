import { locations } from '@cimpress-technology/logistics-configuration-client'
import { Button, Modal, Radio } from '@cimpress/react-components'
import { Form, Formik } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import FormikRadioGroup from '../../../common/components/FormikRadioGroup'
import FormikTextField from '../../../common/components/FormikTextField'
import { SnackbarController } from '../../../common/components/SnackbarController'

interface Props {
  createNotification: (
    notification: Omit<locations.models.CreateNotification, 'locationId'>
  ) => Promise<void>
  onClose: () => void
}

interface FormikProps {
  type: 'alert' | 'warning' | 'info'
  content: string
  url: string
  text: string
}

export default function CreateNotificationModal(props: Props) {
  const { t } = useTranslation()
  const [saving, setSaving] = React.useState(false)

  const onSave = async (formikProps: FormikProps) => {
    setSaving(true)
    try {
      await props.createNotification({
        type: formikProps.type,
        content: formikProps.content,
        ...(formikProps.url && {
          actionLink: {
            url: formikProps.url,
            ...(formikProps.text && { text: formikProps.text }),
          },
        }),
      })
      SnackbarController.show(
        t('locations.notifications.addNotificationSuccess'),
        'success'
      )
      props.onClose()
    } catch (error) {
      SnackbarController.show(
        t('locations.notifications.addNotificationFail'),
        'danger'
      )
      setSaving(false)
    }
  }

  const validationSchema = Yup.object().shape({
    content: Yup.string().required(t('validation.content.required')),
    url: Yup.string()
      .url(t('validation.url.notValid'))
      .when(['text'], {
        is: text => text,
        then: Yup.string().required(t('validation.url.required')),
      }),
    text: Yup.string(),
  })

  const onClose = () => {
    props.onClose()
  }

  const initialValues: FormikProps = {
    type: 'alert',
    content: '',
    url: '',
    text: '',
  }

  const content = (
    <Formik
      initialValues={initialValues}
      onSubmit={onSave}
      validationSchema={validationSchema}
    >
      {formikProps => {
        const handleSubmit = (e: any) => {
          formikProps.handleSubmit(e)
        }

        return (
          <Form>
            <h5>Type</h5>
            <FormikRadioGroup name="type">
              <Radio label="alert" value="alert" />
              <Radio label="warning" value="warning" />
              <Radio label="info" value="info" />
            </FormikRadioGroup>
            <h5>{t('locations.notifications.content')}</h5>
            <FormikTextField
              name="content"
              type="textarea"
              label={t('locations.notifications.content')}
              required={true}
            />
            <h5>{t('locations.notifications.actionLink')}</h5>
            <FormikTextField name="url" type="url" label={t('common.url')} />
            <FormikTextField
              name="text"
              type="textarea"
              label={t('common.text')}
            />
            <Button disabled={saving} type="primary" onClick={handleSubmit}>
              {t('common.create')}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )

  return (
    <Modal
      show={true}
      onRequestHide={onClose}
      closeButton={true}
      closeOnOutsideClick={false}
      title={t('locations.notifications.addNotification')}
      style={{ width: '526px' }}
    >
      {content}
    </Modal>
  )
}
