import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import { Button } from '@cimpress/react-components'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Link, RouteComponentProps } from 'react-router-dom'
import { SnackbarController } from '../../common/components/SnackbarController'
import { clone } from '../../common/helpers/clone'
import { logError } from '../../common/logger'
import { Location, SequenceDefinition } from '../../common/models'
import { SequenceDefinitionPair } from '../../common/models-carriers'
import { patchSequence } from '../../common/proxy/sequences-store'
import * as sequenceHelpers from './sequences/helpers'
import MultipleSequencesEditor from './sequences/MultipleSequencesEditor'

export interface Props
  extends RouteComponentProps<{
      locationId: string
      carrierAccountId: string
    }>,
    WithTranslation {
  logisticsLocation: Location
  carrierAccount: carrierAccounts.models.CarrierAccount
  sequenceDefinitionPairs: SequenceDefinitionPair[]
}

interface State {
  sequenceDefinitions: SequenceDefinition[]
  areValid: boolean[]
}

class EditSequencesPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      sequenceDefinitions: this.props.sequenceDefinitionPairs.map(pair =>
        clone(pair.sequenceDefinition)
      ),
      areValid: this.props.sequenceDefinitionPairs.map(sd => true),
    }
  }

  public render() {
    const sequences = this.props.sequenceDefinitionPairs.map(
      pair => pair.sequence
    )

    return (
      <div className="container">
        <div className="row" style={{ marginBottom: '32px' }}>
          <div
            className="col-md-9 col-lg-9 align-self-center"
            style={{ float: 'none', margin: 'auto' }}
          >
            <h1>
              {this.props.t('carrierAccounts.sequences.editTrackingRanges', {
                name: this.props.carrierAccount.name,
              })}
            </h1>
            <div className="card">
              <div className="card-block">
                <MultipleSequencesEditor
                  sequenceTypeToShow={'tracking'}
                  sequences={sequences}
                  sequenceDefinitions={this.state.sequenceDefinitions}
                  onSequenceDefinitionChange={this.onSequenceDefinitionChange}
                  areValid={this.state.areValid}
                />
              </div>
            </div>
            <div style={{ paddingTop: '16px', paddingBottom: '32px' }}>
              <span style={{ float: 'right' }}>
                <Link
                  to={`../../carrier-accounts/${this.props.carrierAccount.id}`}
                  className="btn btn-default"
                >
                  {this.props.t('common.cancel')}
                </Link>{' '}
                <Button
                  type="primary"
                  onClick={this.save}
                  disabled={
                    this.state.areValid.filter(v => v === false).length > 0
                  }
                >
                  {this.props.t('common.saveChanges')}
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private onSequenceDefinitionChange = (
    sequenceDefinitions: SequenceDefinition[],
    areValid: boolean[]
  ) => {
    this.setState({ sequenceDefinitions, areValid })
  }

  private save = async () => {
    try {
      const patches = this.state.sequenceDefinitions
        .map((sd, i) => ({
          id: sd.id!,
          patch: sequenceHelpers.createPatchSequence(
            sd,
            this.props.sequenceDefinitionPairs[i].sequenceDefinition
          ),
        }))
        .filter(ps => Object.entries(ps.patch).length !== 0)

      await Promise.all(patches.map(p => patchSequence(p.id, p.patch)))

      SnackbarController.show(
        this.props.t('carrierAccounts.sequences.updateSucceeded', {
          name: this.props.carrierAccount.name,
        }),
        'success'
      )
      this.props.history.push(
        `/location/${this.props.logisticsLocation.id}/carrier-accounts/${this.props.carrierAccount.id}`
      )
    } catch (err) {
      SnackbarController.show(
        this.props.t('carrierAccounts.sequences.updateFailed', {
          name: this.props.carrierAccount.name,
        }),
        'danger'
      )
      logError('Carrier Account sequences update failed', err)
    }
  }
}

export default withTranslation()(EditSequencesPage)
