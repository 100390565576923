import { colors } from '@cimpress/react-components'
import * as React from 'react'
import { Option } from '../common/models'
import { CarrierService } from '../common/models-carriers'
import { Modes } from './CarrierExplorerPageContainer'
import CarrierServiceDetail from './components/CarrierServiceDetail'
import CarrierServicesFilter from './components/CarrierServicesFilter'
import CarrierServicesList from './components/CarrierServicesList'

interface Props {
  carrierServices: CarrierService[]
  selectedMode: Modes
  countryCode: string

  fromCountryCode: string
  toCountryCode: string
  selectedCapabilities: string[]

  allCapabilities: Option[]
  onChange: (data: OnChangeData) => void
  onChangeMode: (e: React.ChangeEvent) => void
  onAddCapability: (e: Option) => void
  onRemoveCapability: (value: string) => void
  selectedCarrierService?: CarrierService
}

export interface OnChangeData {
  label: string
  value: string
  payload?: any
}

export default function CarrierExplorerPage(props: Props) {
  return (
    <div className="row">
      <div
        className="col-xs-12 col-md-5 col-lg-5"
        style={{ paddingRight: '0px' }}
      >
        <div className="card carrier-explorer-container">
          <div
            className="card-block carrier-explorer-services"
            style={{ padding: '0px' }}
          >
            <div
              style={{
                padding: '16px',
                borderBottom: 'solid 1px ' + colors.alloy,
              }}
            >
              <CarrierServicesFilter
                onChange={props.onChange}
                onChangeMode={props.onChangeMode}
                countryCode={props.countryCode}
                toCountryCode={props.toCountryCode}
                fromCountryCode={props.fromCountryCode}
                selectedMode={props.selectedMode}
                onAddCapability={props.onAddCapability}
                allCapabilities={props.allCapabilities}
                selectedCapabilities={props.selectedCapabilities}
                onRemoveCapability={props.onRemoveCapability}
              />
            </div>
            <div
              style={{
                backgroundColor: colors.silver,
                flex: '1 1 auto',
              }}
            >
              <CarrierServicesList
                carrierServices={props.carrierServices}
                selectedCarrierService={props.selectedCarrierService}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-md-7 col-lg-7" style={{ padding: '0px' }}>
        <CarrierServiceDetail service={props.selectedCarrierService} />
      </div>
    </div>
  )
}
