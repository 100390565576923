import SettingsModal from '@cimpress-technology/react-platform-settings'
import { getSettings } from '@cimpress-technology/react-platform-settings/lib/SettingsClient'
import * as React from 'react'
import i18n, { supportedLanguages } from '../../i18n'
import auth from '../auth'

interface Props {
  selectedLanguage: string
}

export function Preferences(props: Props) {
  const onSave = async () => {
    const settings = await getSettings(
      auth.getAccessToken(),
      supportedLanguages
    )
    i18n.changeLanguage(settings.global.language)
  }

  return (
    <SettingsModal
      authToken={auth.getAccessToken()}
      lang={props.selectedLanguage}
      supportedLanguages={supportedLanguages}
      onSave={onSave}
    />
  )
}
