import { ConfigSpec } from '../common/models-carriers'

export interface SpecVisitor {
  visitObject: (spec: ConfigSpec) => void
  visitBoolean: (spec: ConfigSpec) => void
  visitString: (spec: ConfigSpec) => void
  visitNumber: (spec: ConfigSpec) => void
}

export function traverse(spec: ConfigSpec, visitor: SpecVisitor) {
  const valueType = spec.value.valueType

  if (valueType === 'object') {
    visitor.visitObject(spec)
  } else if (valueType === 'boolean') {
    visitor.visitBoolean(spec)
  } else if (valueType === 'number') {
    visitor.visitNumber(spec)
  } else {
    visitor.visitString(spec)
  }
}

export function traverseSpecArray(
  specs: ConfigSpec[],
  visitor: SpecVisitor
): any {
  return specs.reduce((agg, spec) => {
    const config = {}
    traverse(spec, visitor)
    agg[spec.key] = config[spec.key]

    return agg
  }, {})
}
