import { Tooltip } from '@cimpress/react-components'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import IconCopyPaste from '@cimpress-technology/react-streamline-icons/lib/IconCopyPaste'
import AnchorButton from '../../../common/components/AnchorButton'
import Highlight from '../../../common/components/Highlight'
import { SnackbarController } from '../../../common/components/SnackbarController'
import useHover from '../../../common/hooks/useHover'
import { Location } from '../../../common/models'

interface Props {
  location: Location
  searchQuery: string
  children?: React.ReactNode
}

export default function AdvancedRowGroupByCountriesComponent(
  props: Props
): JSX.Element {
  const { t } = useTranslation()

  return (
    <tr>
      <Cell
        wrapper="td"
        id={props.location.id}
        name={props.location.name}
        searchQuery={props.searchQuery}
        tooltip={t('locations.selectLocationPage.copyLocationIdToClipboard')}
        style={{ width: '40%' }}
      >
        <Link to={'/location/' + props.location.id}>
          <Highlight text={props.location.name} highlight={props.searchQuery} />
        </Link>{' '}
        (
        <i>
          <Highlight text={props.location.id} highlight={props.searchQuery} />
        </i>
      </Cell>
      <td>
        {props.location.fulfillmentLocations.map(fl => (
          <Cell
            key={fl.id}
            wrapper="div"
            id={fl.id}
            name={fl.name}
            searchQuery={props.searchQuery}
            tooltip={t('locations.selectLocationPage.copyFlIdToClipboard')}
          >
            <Highlight text={fl.name} highlight={props.searchQuery} /> (
            <i>
              <Highlight text={fl.id} highlight={props.searchQuery} />
            </i>
          </Cell>
        ))}
      </td>
      <td>{props.children}</td>
    </tr>
  )
}

function copyToClipboard(text: string) {
  return async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (navigator.clipboard) {
      await navigator.clipboard!.writeText(text)
      SnackbarController.show(
        <Trans
          i18nKey="locations.selectLocationPage.textCopiedToClipboard"
          values={{ text }}
        />,
        'success',
        2000
      )
    }
  }
}

interface CellProps {
  wrapper: React.ElementType
  name: string
  id: string
  searchQuery: string
  tooltip: string
  style?: React.CSSProperties
  children?: React.ReactNode
}

function Cell(props: CellProps) {
  const { hovered, bind } = useHover()
  const Wrapper = props.wrapper

  const content = hovered ? (
    <Tooltip contents={props.tooltip}>
      <AnchorButton onClick={copyToClipboard(props.id)}>
        <IconCopyPaste style={{ marginLeft: '4px' }} />
      </AnchorButton>
    </Tooltip>
  ) : undefined

  return (
    <Wrapper style={props.style} {...bind}>
      {props.children}
      {content})
    </Wrapper>
  )
}
