import { colors } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import CountryPicker from '../../common/components/CountryPicker'
import { ValidationStatus } from '../../common/helpers/validation'
import { Option } from '../../common/models'
import { OnChangeData } from '../CarrierExplorerPage'
import { Modes } from '../CarrierExplorerPageContainer'
import CarrierServicesCapabilities from './CarrierServicesCapabilities'

interface Props {
  selectedMode: Modes
  countryCode: string

  fromCountryCode: string
  toCountryCode: string
  selectedCapabilities: string[]

  allCapabilities: Option[]
  onChange: (data: OnChangeData) => void
  onChangeMode: (e: React.ChangeEvent) => void
  onAddCapability: (e: Option) => void
  onRemoveCapability: (value: string) => void
}

export default function CarrierServicesFilter(props: Props) {
  const { t } = useTranslation()
  let selection
  if (props.selectedMode === 'withinCountry') {
    selection = (
      <CountryPicker
        name="countryCode"
        value={props.countryCode}
        onChange={props.onChange}
        required={false}
        validationStatus={ValidationStatus.NotValidated}
        includeAll={true}
      />
    )
  } else {
    selection = (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '48%' }}>
          <CountryPicker
            label="From"
            name="fromCountryCode"
            value={props.fromCountryCode}
            onChange={props.onChange}
            required={false}
            validationStatus={ValidationStatus.NotValidated}
            includeAll={true}
          />
        </div>
        <i
          className="fa fa-long-arrow-right"
          style={{
            fontSize: '16px',
            color: colors.slate,
            paddingTop: '20px',
            paddingLeft: '16px',
            paddingRight: '16px',
          }}
        />
        <div style={{ width: '48%' }}>
          <CountryPicker
            label="To"
            name="toCountryCode"
            value={props.toCountryCode}
            onChange={props.onChange}
            required={false}
            validationStatus={ValidationStatus.NotValidated}
            includeAll={true}
          />
        </div>
      </div>
    )
  }

  const withinCountryLabelClass =
    'btn btn-default' +
    (props.selectedMode === 'withinCountry' ? ' active' : '')

  const internationalLabelClass =
    'btn btn-default' +
    (props.selectedMode === 'international' ? ' active' : '')

  return (
    <>
      <h3>{t('carrierExplorer.findYourCarrierServices')}</h3>
      <div style={{ marginBottom: '8px', marginTop: '16px' }}>
        {t('carrierExplorer.showCarrierServicesThatDeliver')}
      </div>
      <div
        className="btn-group btn-group-justified"
        data-toggle="buttons"
        style={{ marginBottom: '8px' }}
      >
        <label className={withinCountryLabelClass}>
          <input
            type="radio"
            name="withinCountry"
            id="withinCountry"
            autoComplete="off"
            checked={props.selectedMode === 'withinCountry'}
            onChange={props.onChangeMode}
          />
          {t('carrierExplorer.inACountry')}
        </label>
        <label
          className={internationalLabelClass}
          style={{ whiteSpace: 'normal' }}
        >
          <input
            type="radio"
            name="international"
            id="international"
            autoComplete="off"
            checked={props.selectedMode === 'international'}
            onChange={props.onChangeMode}
          />
          {t('carrierExplorer.fromCountryToCountry')}
        </label>
      </div>
      {selection}
      <CarrierServicesCapabilities
        onAddCapability={props.onAddCapability}
        selectedCapabilities={props.selectedCapabilities}
        allCapabilities={props.allCapabilities}
        onRemoveCapability={props.onRemoveCapability}
      />
    </>
  )
}
