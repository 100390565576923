import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import DocumentTypeView from './DocumentTypeView'
import { getUploadLabel } from './uploadEditorFactory'
import styles from './UploadView.module.css'
import { CommonProps } from './uploadViewFactory'

interface Props extends CommonProps {
  upload: carrierAccounts.models.EmailUpload
}
export default function EmailUploadView(props: Props) {
  const { t } = useTranslation()
  const name = getUploadLabel(props.upload.uploadType)

  const to = formatEmailComponent(
    t('carrierAccounts.uploads.emailRecipientTo'),
    props.upload.receivers.to
  )
  const cc = formatEmailComponent(
    t('carrierAccounts.uploads.emailRecipientCc'),
    props.upload.receivers.cc
  )
  const bcc = formatEmailComponent(
    t('carrierAccounts.uploads.emailRecipientBcc'),
    props.upload.receivers.bcc
  )

  return (
    <>
      <h6 className={styles.uploadViewHeader}>
        {t('carrierAccounts.uploads.uploadTitle', { name })}
      </h6>
      <DocumentTypeView
        documentTypeList={props.documentTypeList}
        documentTypes={props.upload.documentTypes}
      />
      <div className={styles.uploadView}>
        <div>{t('carrierAccounts.uploads.emailSubject')}</div>
        <div>{props.upload.subject}</div>
      </div>
      <div className={styles.uploadView}>
        <div>{t('carrierAccounts.uploads.emailTemplate')}</div>
        <div>{props.upload.template}</div>
      </div>
      {to}
      {cc}
      {bcc}
    </>
  )
}

function formatEmail(email: carrierAccounts.models.EmailTarget) {
  if (email.name) {
    return `${email.name}<${email.address}>`
  }

  return email.address
}

function formatEmailComponent(
  label: string,
  emails?: carrierAccounts.models.EmailTarget[]
) {
  return emails && emails.length > 0 ? (
    <div className={styles.uploadView}>
      <div>{label}</div>
      <div>{emails.map(email => formatEmail(email)).join(', ')}</div>
    </div>
  ) : undefined
}
