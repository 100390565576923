import { calendars } from '@cimpress-technology/logistics-configuration-client'
import moment from 'moment'
import { PickupCalendar } from '../../calendars/models'

export function isSamePickup(
  p1: calendars.models.Pickup,
  p2: calendars.models.Pickup
) {
  return p1.time === p2.time && arraysAreSame(p1.tags, p2.tags)
}

function arraysAreSame(arr1: string[], arr2: string[]) {
  return (
    arr1.length === arr2.length && arr1.every(x => arr2.some(x2 => x2 === x))
  )
}

export function getDefaultPickupCalendar(): PickupCalendar {
  return {
    name: '',
    weeklySchedules: [
      {
        schedule: {
          monday: [{ time: '09:00', tags: [] }],
          tuesday: [{ time: '09:00', tags: [] }],
          wednesday: [{ time: '09:00', tags: [] }],
          thursday: [{ time: '09:00', tags: [] }],
          friday: [{ time: '09:00', tags: [] }],
          saturday: [],
          sunday: [],
        },
        validFrom: moment().format('YYYY-MM-DD'),
      },
    ],
    overwrites: {},
  }
}
