import { Phone, Radio } from '@cimpress/react-components'
import { countries, Country } from 'country-data'
import { Form, Formik, FormikProps, useField } from 'formik'
import { isValidNumber } from 'libphonenumber-js'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Prompt } from 'react-router'
import * as Yup from 'yup'
import AnchorButton from '../common/components/AnchorButton'
import FormikCountryPicker from '../common/components/FormikCountryPicker'
import FormikErrorFocus from '../common/components/FormikErrorFocus'
import FormikRadioGroup from '../common/components/FormikRadioGroup'
import FormikTextField from '../common/components/FormikTextField'
import { ServerErrorAlert } from '../common/components/ServerErrorAlert'
import ValidationErrorMessage from '../common/components/ValidationErrorMessage'
import { clone } from '../common/helpers/clone'
import * as models from '../common/models'
import useFulfillmentLocations from '../common/hooks/useFulfillmentLocations'
import CurrencyPicker from './CurrencyPicker'
import DateFormatPicker from './DateFormatPicker'
import FulfillmentLocationPickerContainer from './FulfillmentLocationPickerContainer'
import TimezonePicker from './TimezonePicker'

interface Props {
  location?: models.Location
  saving: boolean
  serverError?: models.ServerError
  children?: React.ReactNode
  onSave(location: models.Location): void
  onCancel(): void
}

const getEmptyLocation = () => ({
  address: {
    countryCode: '',
    locality: '',
    region: '',
    postalCode: '',
    street1: '',
    street2: '',
  },
  contact: { name: '', email: '', phone: '' },
  etag: '',
  fulfillmentLocations: [
    {
      id: '',
      name: '',
      editable: true,
      fulfiller: { fulfillerId: '' },
    },
  ],
  id: '',
  localeSettings: {
    currencyCode: '',
    dateFormat: '',
    lengthUnits: '' as 'cm' | 'in',
    timezone: '',
    weightUnits: '' as 'g' | 'kg' | 'lb',
  },
  name: '',
  editable: true,
  tagsEnabled: false,
  showDeliveryCalendar: false,
  transitCalendars: { mapping: {} },
  pickupCalendars: { mapping: {} },
  countryCalendars: { mapping: {} },
  deliveryCalendars: { calendars: [] },
  carrierAccounts: [],
  workingDaysCalendar: { id: '' },
})

export default function LocationEditor(props: Props) {
  const { t } = useTranslation()
  const [contactExpanded, setContactExpanded] = React.useState(
    props.location ? true : false
  )

  const { availableFLs, usedFLs } = useFulfillmentLocations()

  const onExpandContact = () => {
    setContactExpanded(true)
  }
  const validatedFields = [
    'name',
    // does not focus, needs support to deepBracketNotation for array access
    // 'fulfillmentLocations[0].id'
    'address.countryCode',
    'address.locality',
    // don't focus - cimpress components don't work with refs
    // 'contact.email', 'contact.phone'
    'address.postalCode',
    'address.street1',
  ]

  const focusableFields = validatedFields.map(name => ({
    name,
    ref: React.createRef(),
  }))
  const fieldReferences = new Map(focusableFields.map(f => [f.name, f.ref]))

  const onCancel = (_e: React.FormEvent<HTMLButtonElement>) => {
    props.onCancel()
    window.onbeforeunload = null
  }

  const mode = props.location ? 'edit' : 'new'

  const title =
    mode === 'edit'
      ? t('locations.locationEditor.editLocation')
      : t('locations.locationEditor.addLogisticsLocation')

  const showAddress = (formikProps: FormikProps<models.Location>) => {
    const isFlAddressWithErrors = () => {
      const currentFL = [...(availableFLs || []), ...usedFLs].find(
        fl => fl.id === formikProps.values.fulfillmentLocations[0].id
      )
      const fields = ['locality', 'countryCode', 'postalCode', 'street1']

      return fields.some(f => !currentFL!.address?.[f])
    }

    const show =
      mode === 'edit' ||
      (formikProps.values.fulfillmentLocations[0].id && isFlAddressWithErrors())

    return (
      show && (
        <div className="form-group form-group-edit">
          <h5>{t('locations.address')}</h5>
          <FormikTextField
            name="address.street1"
            label="Address line 1"
            required={true}
            ref={fieldReferences.get('address.street1')}
          />
          <FormikTextField
            name="address.street2"
            label="Address line 2"
            required={false}
            ref={fieldReferences.get('address.street2')}
          />

          <FormikTextField
            name="address.region"
            label="State / Province"
            required={false}
            ref={fieldReferences.get('address.region')}
          />

          <FormikTextField
            name="address.postalCode"
            label="Postal Code"
            required={true}
            ref={fieldReferences.get('address.postalCode')}
          />

          <FormikCountryPicker
            name="address.countryCode"
            required={true}
            selectReference={fieldReferences.get('address.countryCode')}
          />
          <FormikTextField
            name="address.locality"
            label={t('locations.city')}
            required={true}
            ref={fieldReferences.get('address.locality')}
          />
        </div>
      )
    )
  }

  const addPrimaryContact = (
    <div style={{ marginBottom: '32px', marginTop: '24px' }}>
      <AnchorButton onClick={onExpandContact}>
        <i className="fa fa-fw fa-plus" /> {t('locations.contact.addContact')}
      </AnchorButton>
    </div>
  )

  const primaryContact = (
    <div className="form-group form-group-edit">
      <h5>
        <Trans i18nKey="locations.contact.primaryContact" />
      </h5>
      <FormikTextField
        name="contact.name"
        label={t('locations.contact.fullname')}
      />
      <FormikTextField
        name="contact.email"
        label={t('locations.contact.email')}
        type="email"
      />
      <FormikPhone name="contact.phone" />
    </div>
  )

  const localeSettings = (
    <div className="form-group">
      <h5>
        <Trans i18nKey="locations.region.regionAndUnits" />
      </h5>
      <TimezonePicker name="localeSettings.timezone" />
      <DateFormatPicker name="localeSettings.dateFormat" />
      <CurrencyPicker name="localeSettings.currencyCode" />
      <h6>
        <Trans i18nKey="locations.region.lengthUnits" />
      </h6>
      <FormikRadioGroup name="localeSettings.lengthUnits">
        <Radio label="cm" value="cm" />
        <Radio label="in" value="in" />
      </FormikRadioGroup>
      <h6>
        <Trans i18nKey="locations.region.weightUnits" />
      </h6>
      <FormikRadioGroup name="localeSettings.weightUnits">
        <Radio label="g" value="g" />
        <Radio label="kg" value="kg" />
        <Radio label="lb" value="lb" />
      </FormikRadioGroup>
    </div>
  )

  const saveButtonText =
    mode === 'edit'
      ? t('locations.locationEditor.save')
      : t('locations.addLocation')
  const savingButtonText =
    mode === 'edit'
      ? t('locations.locationEditor.saving')
      : t('locations.locationEditor.addingLocation')

  const initialValues: models.Location = clone(
    props.location || getEmptyLocation()
  )

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.locationName.required')),
    fulfillmentLocations: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(t('validation.fulfillmentLocation.required')),
      })
    ),
    address: Yup.object().shape({
      countryCode: Yup.string().required(t('validation.country.required')),
      locality: Yup.string().required(t('validation.city.required')),
      postalCode: Yup.string().required(t('validation.postalCode.required')),
      street1: Yup.string().required(t('validation.street1.required')),
    }),
    contact: Yup.object().shape({
      email: Yup.string().email(t('validation.email.notValid')),
      phone: Yup.string().test(
        'contact phone is valid',
        t('validation.phone.notValid'),
        value => {
          return value === undefined || isValidNumber(value)
        }
      ),
    }),
  })
  const onSubmit = (values: models.Location) => {
    props.onSave(values)
  }

  return (
    <div className="row">
      <div className="col-xs-12 col-lg-12">
        <h3 style={{ marginBottom: '24px' }}>{title}</h3>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {formikProps => {
            const handleSubmit = (e: any) => {
              formikProps.handleSubmit(e)
            }
            const inSubmissionHandler =
              formikProps.isValidating === false &&
              formikProps.isSubmitting === true

            return (
              <Form
                className="content-wrapper"
                style={{ paddingBottom: '80px' }}
              >
                <Prompt
                  when={formikProps.dirty && !inSubmissionHandler}
                  message={t('locations.locationEditor.unsavedChanges')}
                />
                <FormikTextField
                  name="name"
                  label={t('locations.name')}
                  autoFocus={true}
                  required={true}
                  ref={fieldReferences.get('name')}
                />
                <FulfillmentLocationPickerContainer
                  name="fulfillmentLocations[0].id"
                  availableFLs={availableFLs}
                  usedFLs={usedFLs}
                  onChange={onFlChange(formikProps.setFieldValue)}
                  required={true}
                  selectReference={
                    fieldReferences.get('fulfillmentLocations[0].id')!
                  }
                  disabled={mode === 'edit'}
                  loadingText={formikProps.values.fulfillmentLocations[0].name}
                />
                {props.children}
                {showAddress(formikProps)}
                {!contactExpanded ? addPrimaryContact : ''}
                {contactExpanded ? primaryContact : ''}
                {mode === 'edit' ? localeSettings : ''}
                <div style={{ marginTop: '32px' }}>
                  <button
                    name="save"
                    className="btn btn-primary"
                    disabled={props.saving}
                    style={{ marginRight: '16px' }}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {props.saving ? savingButtonText : saveButtonText}
                  </button>
                  <button
                    name="cancel"
                    className="btn btn-default"
                    onClick={onCancel}
                    type="button"
                  >
                    <Trans i18nKey="common.cancel" />
                  </button>
                  <FormikErrorFocus focusableFields={focusableFields} />
                </div>
              </Form>
            )
          }}
        </Formik>
        <div style={{ marginBottom: '32px' }}>
          {props.serverError ? (
            <ServerErrorAlert serverError={props.serverError} />
          ) : null}
        </div>
      </div>
    </div>
  )
}

function onFlChange(setFieldValue: (field: string, value: any) => void) {
  return (fl?: models.FulfillmentLocation) => {
    if (fl) {
      const countryCode = fl.address?.countryCode ?? ''
      setFieldValue('address.countryCode', countryCode)

      const locality = fl.address?.locality ?? ''
      setFieldValue('address.locality', locality)

      setFieldValue(
        'localeSettings',
        getDefaultLocale(countryCode, fl.timezone ?? '')
      )

      const postalCode = fl.address?.postalCode ?? ''
      setFieldValue('address.postalCode', postalCode)

      const region = fl.address?.region ?? ''
      setFieldValue('address.region', region)

      const street1 = fl.address?.street1 ?? ''
      setFieldValue('address.street1', street1)

      const street2 = fl.address?.street2 ?? ''
      setFieldValue('address.street2', street2)
    }
  }
}

function getDefaultLocale(
  countryCode: string,
  timezone: string
): models.LocaleSettings {
  const currencyCode = (countries[countryCode] as Country).currencies[0]
  if (countryCode === 'US') {
    return {
      currencyCode,
      dateFormat: 'MM/DD/YYYY',
      lengthUnits: 'in',
      weightUnits: 'lb',
      timezone,
    }
  }

  return {
    currencyCode,
    dateFormat: 'DD.MM.YYYY',
    lengthUnits: 'cm',
    weightUnits: 'g',
    timezone,
  }
}

interface PhoneProps {
  name: string
}

function FormikPhone(props: PhoneProps) {
  const { t } = useTranslation()
  const [field, meta, helpers] = useField<string>(props.name)
  // eslint-disable-next-line id-blacklist
  const onChange = (data: { number: string; isValid: boolean }) => {
    helpers.setValue(data ? data.number : '')
  }

  return (
    <div className="form-group">
      <Phone
        initialValue={field.value}
        onChange={onChange}
        countrySelectMenuStyle={{
          minWidth: '450px',
        }}
        countrySelectLabel={t('locations.contact.countryCode')}
        phoneTextInputLabel={t('locations.contact.phone')}
        extensionTextInputLabel={t('locations.contact.ext')}
      />
      {meta.touched && <ValidationErrorMessage message={meta.error} />}
    </div>
  )
}
