import { Snackbar, SnackbarProps } from '@cimpress/react-components'
import * as React from 'react'

interface State {
  showingSnackbar?: React.ReactElement<SnackbarProps>
}

export class SnackbarController extends React.Component<{}, State> {
  private static instance: SnackbarController

  constructor(props: {}) {
    super(props)
    SnackbarController.instance = this
    this.state = {}
  }

  public static show(
    contents: React.ReactNode,
    bsStyle: 'danger' | 'warning' | 'info' | 'success' = 'info',
    delay = 5000
  ) {
    SnackbarController.instance.setState({
      showingSnackbar: (
        <Snackbar
          key={Date.now()}
          show={true}
          bsStyle={bsStyle}
          delay={delay}
          onHideSnackbar={this.onHideSnackbar}
        >
          {contents}
        </Snackbar>
      ),
    })
  }

  public static onHideSnackbar() {
    SnackbarController.instance.setState({ showingSnackbar: undefined })
  }

  public render() {
    return <>{this.state.showingSnackbar}</>
  }
}
