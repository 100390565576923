import { Table, Tooltip } from '@cimpress/react-components'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Column, Filter, RowInfo } from 'react-table'
import OpenInNewTab from '../../../common/components/OpenInNewTab'
import Preloader from '../../../common/components/Preloader'
import * as models from '../../../common/models'
import { LinkData } from '../view-location-page/QcmLink'

export type FlagStatus = 'on' | 'off'

interface Row {
  id: string
  name: string
  network: string
  flags: {
    [key: string]: FlagStatus
  }
  qcmLinks: LinkData[] | undefined
}

interface Props {
  flags: { [key: string]: string[] }
  locations: models.Location[]
  qcmLinks: Record<string, LinkData[]>
  loading: boolean
  toggleFeatureFlag: (
    locationId: string,
    locationName: string,
    featureFlagId: string,
    value: FlagStatus
  ) => Promise<void>
}

const labels: Array<{ key: FlagStatus; value: '✅' | '❌' }> = [
  { key: 'on', value: '✅' },
  { key: 'off', value: '❌' },
]

export default function MigrationStatusTable(props: Props) {
  const onOffFilterMethod = (filter: any, row: any) => {
    if (filter.value === 'all') {
      return true
    }

    return row[filter.id] === filter.value
  }

  const OnOffFilter = ({ filter, onChange }: any) => {
    const onValueChange = (e: any) => onChange(e.target.value)

    return (
      <select
        onChange={onValueChange}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}
      >
        <option value="all">Show All</option>
        {labels.map(label => (
          <option value={label.key} key={label.key}>
            {label.value}
          </option>
        ))}
      </select>
    )
  }

  const qcmEnabledFilterMethod = (filter: any, row: any) => {
    if (filter.value === 'all') {
      return true
    }

    const qcmLinks = row.qcmLinks as LinkData[] | undefined

    if (!qcmLinks) {
      return true
    }

    if (filter.value === 'qcm-on') {
      return qcmLinks.some(linkData => linkData.link)
    }

    if (filter.value === 'qcm-off') {
      return qcmLinks.every(linkData => !linkData.link)
    }

    return true
  }

  const QcmEnabledFilter = ({ filter, onChange }: any) => {
    const onValueChange = (e: any) => onChange(e.target.value)

    return (
      <select
        onChange={onValueChange}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}
      >
        <option value="all">Show All</option>
        <option value="qcm-on">QCM Enabled</option>
        <option value="qcm-off">No QCM</option>
      </select>
    )
  }

  const flagColumns: Array<Column<Row>> = Object.keys(props.flags).map(
    flagId => {
      return {
        Header: () => {
          const text = flagId.replace(/-/g, ' ')

          return (
            <Tooltip key={flagId} contents={text}>
              {text}
            </Tooltip>
          )
        },
        id: flagId,
        Cell: (row: RowInfo) => {
          const onClick = () =>
            props.toggleFeatureFlag(
              row.original.id,
              row.original.name,
              flagId,
              row.original.flags[flagId]
            )

          return (
            <div style={{ cursor: 'pointer' }} onClick={onClick}>
              {row.original.flags[flagId] === 'on' ? '✅' : '❌'}
            </div>
          )
        },
        filterMethod: onOffFilterMethod,
        Filter: OnOffFilter,
        accessor: row => row.flags[flagId],
        className: 'text-center',
      }
    }
  )

  const columns: Array<Column<Row>> = [
    {
      Header: 'Location',
      id: 'id',
      Cell: (row: RowInfo) => {
        return (
          <Link to={`/location/${row.original.id}`}>{row.original.id}</Link>
        )
      },
      accessor: 'id',
      sortable: false,
    },
    {
      Header: 'Name',
      id: 'name',
      accessor: 'name',
    },
    {
      Header: 'Network',
      id: 'network',
      accessor: 'network',
    },
    ...flagColumns,
    {
      Header: 'QCM Configurations',
      id: 'qcmLinks',
      Cell: (row: RowInfo) => {
        return row.original.qcmLinks ? (
          (row.original.qcmLinks as LinkData[]).map((linkData, i) => {
            if (linkData.link) {
              return (
                <div key={i}>
                  <OpenInNewTab href={linkData.link}>
                    {linkData.flName}
                  </OpenInNewTab>
                </div>
              )
            }

            return <span key={i}>{linkData.flName}</span>
          })
        ) : (
          <Preloader small={true} />
        )
      },
      accessor: 'qcmLinks',
      className: 'text-center',
      sortable: false,
      filterMethod: qcmEnabledFilterMethod,
      Filter: QcmEnabledFilter,
    },
  ]

  const data: Row[] = props.locations.map(l => {
    const flags = Object.entries(props.flags).reduce((acc, curr) => {
      const [flag, locationIds] = curr
      acc[flag] = locationIds?.includes(l.id) ? 'on' : 'off'

      return acc
    }, {} as Record<string, FlagStatus>)

    return {
      id: l.id,
      name: l.name,
      network:
        (l._links && l._links.network && l._links.network.name) || 'NO_NETWORK',
      flags,
      qcmLinks: props.qcmLinks[l.id],
    }
  })

  return (
    <Table
      pageSize={data.length ? data.length : undefined}
      columns={columns}
      minRows={10}
      data={data}
      filterable={true}
      defaultFilterMethod={filterCaseInsensitive}
      loading={props.loading}
      showPagination={false}
    />
  )
}

function filterCaseInsensitive(filter: Filter, row: any, column: any): boolean {
  const id = filter.pivotId || filter.id

  return row[id] !== undefined
    ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
    : true
}
