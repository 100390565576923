import { colors } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ValidationResult,
  ValidationStatus,
} from '../../common/helpers/validation'

interface Props {
  validationResult: ValidationResult
  children: any
}
export function ValidationContainer(props: Props) {
  const { t } = useTranslation()

  return (
    <div className="form-group">
      {props.children}
      <div
        style={{
          color: colors.danger.base,
          marginTop:
            props.validationResult.status === ValidationStatus.Invalid ? -9 : 0,
        }}
      >
        {props.validationResult.translationKey
          ? t(props.validationResult.translationKey)
          : props.validationResult.message}
      </div>
    </div>
  )
}
