import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import Preloader from '../common/components/Preloader'
import Fragment from '../common/components/Fragment'
import { NetworkBreadCrumbItem } from '../common/components/RouterWithBreadcrumbs'
import { config } from '../common/config/config'
import DevelopmentFragment from '../common/components/DevelopmentFragment'
import { useLogisticsLocation } from './LocationContext'

export default function LocationShippingFragment() {
  const { t } = useTranslation()
  const { logisticsLocation } = useLogisticsLocation()

  const FragmentView =
    config.logisticsEnvironment === 'fragments' ? (
      <DevelopmentFragment />
    ) : (
      <Fragment
        src="https://shipping-console-configuration.pikachu.cimpress.io/"
        fallback={<Preloader />}
      />
    )

  return (
    <>
      <BreadcrumbsItem to="/">{t('common.logisticsLocations')}</BreadcrumbsItem>
      <NetworkBreadCrumbItem />
      <BreadcrumbsItem to={`/location/${logisticsLocation.id}`}>
        {logisticsLocation.name}
      </BreadcrumbsItem>
      <BreadcrumbsItem to="#">{t('shipping.shipping')}</BreadcrumbsItem>
      {FragmentView}
    </>
  )
}
