import { colors, shapes, TextField } from '@cimpress/react-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ValidationContainer } from '../../../common/components/ValidationContainer'
import { get, set } from '../../../common/helpers/deepBracketNotation'
import {
  toBSStyle,
  validateField,
  ValidationResult,
  ValidationStatus,
} from '../../../common/helpers/validation'
import { SequenceRange } from '../../../common/models'
import {
  rangeValidatedFields,
  validateAllRangeFields,
} from './rangeEditorValidation'

interface Props {
  index: number
  initialState: SequenceRange
  onChange: (range: SequenceRange, isValid: boolean) => void
  onRemove: (index: number) => void
}
export function RangeEditor(props: Props) {
  const { t } = useTranslation()

  const [range, setRange] = React.useState(props.initialState)

  const propertyChange = (path: string, isNumber = false) => (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const value = isNumber
      ? event.currentTarget.value.length === 0
        ? undefined
        : Number(event.currentTarget.value)
      : event.currentTarget.value

    set(range, path, value)
    setRange({ ...range })
    setValidatedFields(new Set([...Array.from(validatedFields), path]))
    const validationResults = validateAllRangeFields(range)
    const isValid =
      validationResults.filter(vr => vr.status === ValidationStatus.Invalid)
        .length === 0
    props.onChange(range, isValid)
  }
  const handleRemove = () => {
    props.onRemove(props.index)
  }

  const [validatedFields, setValidatedFields] = React.useState(
    new Set<string>()
  )

  const doValidateField = (fieldName: string): ValidationResult => {
    if (!validatedFields.has(fieldName)) {
      return {
        fieldName,
        status: ValidationStatus.NotValidated,
        message: '',
      }
    }
    const validationField = rangeValidatedFields.find(
      field => field.name === fieldName
    )

    return validateField(validationField!, get(range, fieldName))
  }

  const onBlur = (fieldName: string) => () => {
    setValidatedFields(new Set([...Array.from(validatedFields), fieldName]))
  }

  return (
    <div className="row">
      <div className="col-md-3">
        <ValidationContainer validationResult={doValidateField('start')}>
          <TextField
            value={range.start}
            label={t('carrierAccounts.sequences.start')}
            onChange={propertyChange('start', true)}
            onBlur={onBlur('start')}
            bsStyle={toBSStyle(doValidateField('start'))}
            required={true}
            type="number"
            min={0}
            max={range.end}
          />
        </ValidationContainer>
      </div>
      <div className="col-md-3">
        <ValidationContainer validationResult={doValidateField('end')}>
          <TextField
            value={range.end}
            label={t('carrierAccounts.sequences.end')}
            onChange={propertyChange('end', true)}
            onBlur={onBlur('end')}
            bsStyle={toBSStyle(doValidateField('end'))}
            required={true}
            type="number"
            min={range.start}
          />
        </ValidationContainer>
      </div>
      <div className="col-md-2">
        <TextField
          value={range.prefix}
          label={t('carrierAccounts.sequences.prefix')}
          onChange={propertyChange('prefix')}
        />
      </div>
      <div className="col-md-2">
        <TextField
          value={range.suffix}
          label={t('carrierAccounts.sequences.suffix')}
          onChange={propertyChange('suffix')}
        />
      </div>
      <div className="col-md-2">
        <button onClick={handleRemove} className="btn remove">
          <shapes.hamburger.Close
            width="32px"
            color={colors.ocean.base}
            cropped={true}
          />
        </button>
      </div>
    </div>
  )
}
