import { colors, shapes } from '@cimpress/react-components'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import * as _ from 'underscore'
import CountryHeader from '../../common/components/CountryHeader'
import {
  CarrierService,
  CarrierServiceCapability,
} from '../../common/models-carriers'

interface Props {
  service?: CarrierService
}

function capabilityListElement(capability: CarrierServiceCapability) {
  return <li key={capability.key}>{capability.name}</li>
}

function CapabilityArea({ service }: { service: CarrierService }) {
  const { t } = useTranslation()
  if (service.capabilities && service.capabilities.length > 0) {
    const capabilityItems = service.capabilities!.map(capabilityListElement)

    return (
      <div>
        <h4>{t('carrierExplorer.theySupportCapabilities')}</h4>
        <ul style={{ listStylePosition: 'inside' }}>{capabilityItems}</ul>
      </div>
    )
  } else {
    return (
      <div>
        <h4>{t('carrierExplorer.noCapabilitiesSupported')}</h4>
      </div>
    )
  }
}

function ShipToCountryArea({ service }: { service: CarrierService }) {
  const { t } = useTranslation()
  const shipTo: string[] = (service.shippingRegion || []).reduce(
    (acc, item) => (item.shipTo ? _.uniq([...acc, ...item.shipTo]) : acc),
    []
  )

  if (shipTo && shipTo.length > 0) {
    const flags = shipTo.sort().map(code => (
      <li key={code}>
        <CountryHeader countryCode={code} />
      </li>
    ))

    return (
      <div>
        <h4>{t('carrierExplorer.supportedDestinations')}</h4>
        <ul style={{ fontSize: '20px', listStyle: 'none' }}>{flags}</ul>
      </div>
    )
  } else {
    return (
      <div>
        <h4>{t('carrierExplorer.noDestinationsSupported')}</h4>
      </div>
    )
  }
}

export default function CarrierServiceDetail(props: Props) {
  if (props.service) {
    const service = props.service

    return (
      <div
        className="card"
        style={{ height: 'calc(100vh - 96px)', overflow: 'auto' }}
      >
        <div style={{ margin: '16px' }}>
          <div className="row">
            <div className="col-xs-11">
              <h3>{service.name}</h3>
            </div>
            <div className="col-xs-1">
              <Link to="/carrier-explorer" type="button" className="close btn">
                <shapes.hamburger.Close
                  width="32px"
                  color={colors.ocean.base}
                  cropped={false}
                />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6">
              <CapabilityArea service={service} />
            </div>
            <div className="col-xs-6">
              <ShipToCountryArea service={service} />
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="robot-figure"
        style={{ textAlign: 'center', paddingTop: '50px' }}
      >
        <ReactSVG src="https://static.ux.cimpress.io/assets/images/robot-success-3.svg" />
        <p style={{ fontSize: '16px', color: colors.shale, marginTop: '20px' }}>
          <Trans i18nKey="carrierExplorer.carrierServiceDetailNoneSelected" />
        </p>
      </div>
    )
  }
}
