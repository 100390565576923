import { Alert } from '@cimpress/react-components'
import * as React from 'react'
import { ServerError, ServerErrorMessage } from '../models'

export function ServerErrorAlert(props: { serverError: ServerError }) {
  const details = props.serverError.detail
    ? props.serverError.detail.split(', ')
    : undefined

  const parseDetail = (detail: string) => {
    const [propertyName, message] = detail.split(':')

    return (
      <p key={propertyName + message}>
        {formatErrorMessage({ propertyName, message })}
      </p>
    )
  }

  return (
    <Alert
      title={props.serverError.title}
      dismissible={false}
      message={<>{details && details.map(parseDetail)}</>}
    />
  )
}

const formatErrorMessage = (e: ServerErrorMessage) => {
  if (!e.propertyName) {
    return e.message
  }

  return `${formatProperty(e.propertyName)} ${e.message}`
}

const formatProperty = (property: string) => {
  switch (property) {
    case '/name':
      return 'Name'
    case '/address/countryCode':
      return 'Country code'
    case '/address/locality':
      return 'Locality'
    case '/localeSettings/lengthUnits':
      return 'Length units'
    case '/localeSettings/weightUnits':
      return 'Weight units'
    case '/fulfillmentLocations':
      return ''
    default:
      return property
  }
}
