import { Select, TextField } from '@cimpress/react-components'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as uuid from 'uuid'
import AnchorButton from '../../../common/components/AnchorButton'
import { ValidationContainer } from '../../../common/components/ValidationContainer'
import { get, set } from '../../../common/helpers/deepBracketNotation'
import {
  toBSStyle,
  validateField,
  ValidationResult,
  ValidationStatus,
} from '../../../common/helpers/validation'
import {
  Option,
  SequenceDefinition,
  SequenceRange,
} from '../../../common/models'
import { Sequence } from '../../../common/models-carriers'
import { RangeEditor } from './RangeEditor'
import { validateAllRangeFields } from './rangeEditorValidation'
import {
  sequenceValidatedFields,
  validateAllFields,
} from './sequenceEditorValidation'

interface Props {
  sequence: Sequence
  index: number

  initialState: SequenceDefinition

  onChange: (
    sequenceDefinition: SequenceDefinition,
    index: number,
    isValid?: boolean
  ) => void
}

export function SequenceEditor(props: Props) {
  const { t } = useTranslation()

  const [sequenceDefinition, setSequenceDefinition] = React.useState(
    props.initialState
  )

  const [replacementRangeKeys, setReplacementRangeKeys] = React.useState(
    props.initialState.replacementRanges.map(_ => uuid.v4())
  )

  const handleOnChange = (
    changedSequenceDefinition: SequenceDefinition,
    index: number
  ) => {
    const validationResults = validateAllFields(changedSequenceDefinition)
    const rangeValidationResults = changedSequenceDefinition.replacementRanges.map(
      range => validateAllRangeFields(range)
    )
    const allRangesValid = !rangeValidationResults.some(rangeResults =>
      rangeResults.some(r => r.status === ValidationStatus.Invalid)
    )
    const isValid =
      validationResults.filter(vr => vr.status === ValidationStatus.Invalid)
        .length === 0

    props.onChange(changedSequenceDefinition, index, isValid && allRangesValid)
  }

  const propertyChange = (path: string, isNumber = false) => (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const value = isNumber
      ? event.currentTarget.value.length === 0
        ? undefined
        : Number(event.currentTarget.value)
      : event.currentTarget.value
    set(sequenceDefinition, path, value)
    ensureCurrentIsWithin(sequenceDefinition)
    setSequenceDefinition({ ...sequenceDefinition })

    setValidatedFields(new Set([...Array.from(validatedFields), path]))
    handleOnChange(sequenceDefinition, props.index)
  }

  const onStrategyChange = (changeData: Option) => {
    set(sequenceDefinition, 'replacementStrategy', changeData.value)
    setSequenceDefinition({ ...sequenceDefinition })
    handleOnChange(sequenceDefinition, props.index)
  }

  const onRangeRemove = (index: number) => {
    const replacementRanges = sequenceDefinition.replacementRanges.filter(
      (_, i) => i !== index
    )
    setSequenceDefinition({ ...sequenceDefinition, replacementRanges })

    setReplacementRangeKeys(replacementRangeKeys.filter((_, i) => i !== index))
  }

  const onAddReplacement = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault()
    setSequenceDefinition({
      ...sequenceDefinition,
      replacementRanges: [
        ...sequenceDefinition.replacementRanges,
        {
          start: 0,
          end: 100,
          prefix: '',
          suffix: '',
        },
      ],
    })
    setReplacementRangeKeys([...replacementRangeKeys, uuid.v4()])
  }

  const name =
    props.sequence.name || props.sequence.description || props.sequence.key

  const replacementRangesRendered = sequenceDefinition.replacementRanges.map(
    (range, index) => {
      const replacementRangeIndex = index

      const onReplacementRangeChange = (
        updatedRange: SequenceRange,
        isValid: boolean
      ) => {
        const replacementRanges = sequenceDefinition.replacementRanges.slice()
        replacementRanges.splice(replacementRangeIndex, 1, updatedRange)

        const newSequenceDefinition = {
          ...sequenceDefinition,
          replacementRanges,
        }

        setSequenceDefinition(newSequenceDefinition)
        handleOnChange(newSequenceDefinition, props.index)
      }

      return (
        <RangeEditor
          key={replacementRangeKeys[index]}
          initialState={range}
          onChange={onReplacementRangeChange}
          onRemove={onRangeRemove}
          index={index}
        />
      )
    }
  )

  const [validatedFields, setValidatedFields] = useState(new Set<string>())

  const doValidateField = (fieldName: string): ValidationResult => {
    if (!validatedFields.has(fieldName)) {
      return {
        fieldName,
        status: ValidationStatus.NotValidated,
        message: '',
      }
    }
    const validationField = sequenceValidatedFields.find(
      field => field.name === fieldName
    )

    return validateField(validationField!, get(sequenceDefinition, fieldName))
  }

  const onBlur = (fieldName: string) => () => {
    setValidatedFields(new Set([...Array.from(validatedFields), fieldName]))
  }

  const options = [
    {
      value: 'retireSequence',
      label: t('carrierAccounts.sequences.retire'),
    },
    {
      value: 'reuseSequence',
      label: t('carrierAccounts.sequences.reuse'),
    },
  ]

  return (
    <>
      <h4>{name}</h4>
      <div className="row">
        <div className="col-md-4">
          <ValidationContainer
            validationResult={doValidateField('activeRange.start')}
          >
            <TextField
              value={sequenceDefinition.activeRange.start}
              label={t('carrierAccounts.sequences.start')}
              onChange={propertyChange('activeRange.start', true)}
              onBlur={onBlur('activeRange.start')}
              bsStyle={toBSStyle(doValidateField('activeRange.start'))}
              required={true}
              type="number"
              min={0}
              max={sequenceDefinition.current}
            />
          </ValidationContainer>
        </div>
        <div className="col-md-4">
          <ValidationContainer validationResult={doValidateField('current')}>
            <TextField
              value={sequenceDefinition.current}
              label={t('carrierAccounts.sequences.current')}
              onChange={propertyChange('current', true)}
              onBlur={onBlur('current')}
              bsStyle={toBSStyle(doValidateField('current'))}
              required={true}
              type="number"
              min={sequenceDefinition.activeRange.start}
              max={sequenceDefinition.activeRange.end}
            />
          </ValidationContainer>
        </div>
        <div className="col-md-4">
          <ValidationContainer
            validationResult={doValidateField('activeRange.end')}
          >
            <TextField
              value={sequenceDefinition.activeRange.end}
              label={t('carrierAccounts.sequences.end')}
              onChange={propertyChange('activeRange.end', true)}
              onBlur={onBlur('activeRange.end')}
              bsStyle={toBSStyle(doValidateField('activeRange.end'))}
              required={true}
              type="number"
              min={sequenceDefinition.current}
            />
          </ValidationContainer>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <TextField
            value={sequenceDefinition.activeRange.prefix}
            label={t('carrierAccounts.sequences.prefix')}
            onChange={propertyChange('activeRange.prefix')}
          />
        </div>
        <div className="col-md-6">
          <TextField
            value={sequenceDefinition.activeRange.suffix}
            label={t('carrierAccounts.sequences.suffix')}
            onChange={propertyChange('activeRange.suffix')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Select
            label={t('carrierAccounts.sequences.strategy')}
            value={options.filter(
              o => o.value === sequenceDefinition.replacementStrategy
            )}
            options={options}
            required={true}
            onChange={onStrategyChange}
            isClearable={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h6>{t('carrierAccounts.sequences.replacementRanges')}</h6>
        </div>
      </div>
      {replacementRangesRendered}
      <div className="row" style={{ marginBottom: '24px' }}>
        <div className="col-md-12">
          <AnchorButton onClick={onAddReplacement}>
            {t('carrierAccounts.sequences.addReplacementRange')}
          </AnchorButton>
        </div>
      </div>
    </>
  )
}

function ensureCurrentIsWithin(sequenceDefinition: SequenceDefinition) {
  if (sequenceDefinition.current < sequenceDefinition.activeRange.start) {
    sequenceDefinition.current = sequenceDefinition.activeRange.start
  }
  if (sequenceDefinition.current > sequenceDefinition.activeRange.end) {
    sequenceDefinition.current = sequenceDefinition.activeRange.end
  }

  if (
    sequenceDefinition.activeRange.start > sequenceDefinition.activeRange.end
  ) {
    sequenceDefinition.activeRange.start = sequenceDefinition.activeRange.end
    sequenceDefinition.current = sequenceDefinition.activeRange.end
  }
}
