import { Button } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as models from '../../../common/models'
import { AddLocationToNetworkModal } from './AddLocationToNetworkModal'
import useLocationsContext from './LocationsContext'
import styles from './LocationsListGroupByNetworks.module.css'
import AdvancedRowGroupByCountriesComponent from './AdvancedRowGroupByCountriesComponent'

interface Props {
  locations: models.Location[]
  searchQuery: string
}

export default function LocationsListGroupByNetworks(props: Props) {
  const [locationToAdd, setLocationToAdd] = React.useState<models.Location>()

  const { networks } = useLocationsContext()
  const { t } = useTranslation()

  if (props.locations.length === 0) {
    return <h5>{t('locations.selectLocationPage.noLocationFound')}</h5>
  }

  const groupedByNetworks = groupBy(props.locations)

  const networkIdToNetworkMap = Object.fromEntries(
    networks.map(n => [n.apiNetwork.id, n])
  )

  const onAddToNetwork = networks.some(network => network.editable)
    ? (location: models.Location) => {
        setLocationToAdd(location)
      }
    : undefined

  const closeModal = () => {
    setLocationToAdd(undefined)
  }

  return (
    <>
      {Array.from(groupedByNetworks.networks.entries())
        .sort(([, [nameA]], [, [nameB]]) => nameA.localeCompare(nameB))
        .map(([key, [networkName, locations]]) => (
          <NetworkPanel
            key={key}
            searchQuery={props.searchQuery}
            network={networkIdToNetworkMap[key]}
            networkName={networkName}
            locations={locations}
          />
        ))}

      <NetworkPanel
        searchQuery={props.searchQuery}
        locations={groupedByNetworks.noNetworks}
        addToNetwork={onAddToNetwork}
      />

      <AddLocationToNetworkModal
        location={locationToAdd}
        onCloseModal={closeModal}
      />
    </>
  )
}

interface NetworkPanelProps {
  searchQuery: string
  network?: models.Network
  networkName?: string
  locations: models.Location[]
  addToNetwork?: (location: models.Location) => void
}

function NetworkPanel(props: NetworkPanelProps) {
  const { t } = useTranslation()

  return (
    <div style={{ marginBottom: '20px' }}>
      <div className={styles.networkNameRow}>
        <h5>
          {(props.network && props.network.apiNetwork.name) ||
            props.networkName ||
            t('locations.selectLocationPage.noNetwork')}
        </h5>
        {props.network && (
          <Link to={`/network/${props.network.apiNetwork.id}`}>
            {t('networks.goToNetworkOverview')}
          </Link>
        )}
      </div>
      <div className="panel">
        <table className="table table-hover" style={{ marginBottom: 0 }}>
          <tbody>
            {props.locations
              .sort((l1, l2) => l1.name.localeCompare(l2.name, 'en'))
              .map(location => {
                const onClickAddToNetwork = () => {
                  if (props.addToNetwork) {
                    props.addToNetwork(location)
                  }
                }

                const addToNetworkButton =
                  props.addToNetwork && location.editable ? (
                    <Button type="link" onClick={onClickAddToNetwork}>
                      {t('locations.selectLocationPage.addToNetwork')}
                    </Button>
                  ) : undefined

                return (
                  <AdvancedRowGroupByCountriesComponent
                    key={location.id}
                    location={location}
                    searchQuery={props.searchQuery}
                  >
                    {addToNetworkButton}
                  </AdvancedRowGroupByCountriesComponent>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

function groupBy(
  locations: models.Location[]
): {
  networks: Map<string, [string, models.Location[]]>
  noNetworks: models.Location[]
} {
  const noNetworks: models.Location[] = []

  const networks = locations.reduce((acc, location) => {
    if (location._links && location._links.network) {
      const networkId = location._links.network.id
      if (!acc.has(networkId)) {
        acc.set(networkId, [location._links.network.name, []])
      }
      acc.get(networkId)![1].push(location)
    } else {
      noNetworks.push(location)
    }

    return acc
  }, new Map<string, [string, models.Location[]]>())

  return { networks, noNetworks }
}
