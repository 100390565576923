import * as React from 'react'
import { colors, Tooltip } from '@cimpress/react-components'
import IconView from '@cimpress-technology/react-streamline-icons/lib/IconView'
import IconViewOff from '@cimpress-technology/react-streamline-icons/lib/IconViewOff'
import { Link } from 'react-router-dom'
import { i18n as i18nType, TFunction } from 'i18next'
import { Preferences } from './Preferences'
import AnchorButton from './AnchorButton'
import { AdvancedUserContainer } from './AdvancedUserContainer'

export default function generateApplinks(
  isAdvancedUser: boolean,
  toggleAdvancedUser: () => void,
  enableAdvancedUserToggle: boolean,
  i18n: i18nType,
  t: TFunction
) {
  const appLinks = [
    {
      id: 'contact-us',
      content: <Link to="/contact-us">{t('contactUs')}</Link>,
    },
    {
      id: 'divider',
      content: (
        <AdvancedUserContainer>
          <div className="link-divider" />
        </AdvancedUserContainer>
      ),
    },
    {
      id: 'preferences',
      content: (
        <AdvancedUserContainer>
          <Preferences key="preferences" selectedLanguage={i18n.language} />
        </AdvancedUserContainer>
      ),
    },
  ]

  if (enableAdvancedUserToggle) {
    appLinks.push({
      id: 'advanced',
      content: (
        <AnchorButton
          className="clickable"
          style={{ padding: '0' }}
          onClick={toggleAdvancedUser}
        >
          <Tooltip
            contents={t('enableAdvancedUserToggleTooltip')}
            direction="left"
          >
            {isAdvancedUser ? (
              <IconViewOff
                color={colors.ocean.base}
                weight="regular"
                style={{ margin: '16px' }}
              />
            ) : (
              <IconView
                color={colors.ocean.base}
                weight="regular"
                style={{ margin: '16px' }}
              />
            )}
          </Tooltip>
        </AnchorButton>
      ),
    })
  }

  return appLinks
}
