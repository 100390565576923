import { colors } from '@cimpress/react-components'
import * as React from 'react'
interface Props {
  message?: string
}

export default function ValidationErrorMessage(props: Props) {
  return (
    <div
      style={{
        color: colors.danger.base,
        marginTop: props.message ? -9 : 0,
      }}
    >
      {props.message}
    </div>
  )
}
