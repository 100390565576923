import { clone } from '../../../common/helpers/clone'
import { Option, SequenceDefinition, Uploads } from '../../../common/models'
import {
  ConfigSpec,
  EnhancedCaasProfile,
  Sequence,
} from '../../../common/models-carriers'
import { IncompleteCarrierAccount } from '../../../common/proxy/backend-proxy'
import {
  getDocumentTypeKeys,
  initConfigArray,
} from '../../../common/proxy/carrier-services-store'
import { defaultSequenceDefinition } from '../sequences/default-sequence-definition'

export interface CarrierAccountData {
  carrierAccountName: string
  carrier?: string
  carrierServices?: string[]
  accountDetails?: any
  accountDetailsSpecs?: ConfigSpec[]
  documentTypeKeys: Option[]
  sequences?: Sequence[]
  sequenceDefinitions?: SequenceDefinition[]
  sharedSequences: {
    [key: string]: {
      id: string
    }
  }
  defaultDocumentUploads?: Uploads[]
  profile?: EnhancedCaasProfile
  uploads: Uploads[]
}

export interface ExistingCarrierAccountData {
  incompleteCarrierAccount: IncompleteCarrierAccount
  configSpec: ConfigSpec[]
  documentTypeKeys: Option[]
  sequences?: Sequence[]
  sequenceDefinitions?: SequenceDefinition[]
  defaultDocumentUploads?: Uploads[]
  sharedSequences: {
    [key: string]: { id: string }
  }
  profile: EnhancedCaasProfile
}

export function getCarrierAccountData(
  caasProfiles: EnhancedCaasProfile[],
  carrierKey?: string,
  carrierServices?: string[]
): CarrierAccountData {
  if (!carrierKey) {
    return {
      carrierAccountName: '',
      accountDetails: {},
      documentTypeKeys: [],
      sequences: [],
      sharedSequences: {},
      uploads: [],
    }
  }

  const profile = caasProfiles.find(cat => cat.key === carrierKey)!
  if (profile === undefined) {
    throw new Error(`Unable to find profile with key ${carrierKey}`)
  }
  const accountDetailsSpecs = getConfigSpec(profile)

  const accountDetails = initConfigArray(accountDetailsSpecs!)

  const documentTypeKeys = getDocumentTypeKeys(profile)
  const sequences = profile.sequences
  const defaultDocumentUploads = profile.defaultDocumentUploads
  const hasDefaultUploads = defaultDocumentUploads
  const uploads = hasDefaultUploads ? defaultDocumentUploads! : []
  const sequenceDefinitions = (sequences || []).map(defaultSequenceDefinition)
  const sharedSequences = profile.sharedSequences || {}

  return {
    carrierAccountName: profile.name,
    carrier: profile.key,
    carrierServices,
    accountDetails,
    accountDetailsSpecs,
    documentTypeKeys,
    sequences,
    sequenceDefinitions,
    sharedSequences,
    defaultDocumentUploads,
    profile,
    uploads,
  }
}

export function isCarrierSelectCompleted(data: CarrierSelection) {
  return data.carrier && data.carrierServices && data.carrierServices.length > 0
}

export interface CarrierSelection {
  carrier?: string
  carrierServices?: string[]
}

function getConfigSpec(caasProfile: EnhancedCaasProfile) {
  const configSpecs = caasProfile.accountConfigSpec
  if (!configSpecs) {
    return undefined
  }

  const objectSpecs = configSpecs.filter(
    configSpec => configSpec.value.valueType === 'object'
  )
  objectSpecs.forEach(
    objectSpec =>
      (objectSpec.value.items = [
        ...(objectSpec.value.items || []).filter(
          configSpec => configSpec.value.valueType !== 'object'
        ),
        ...(objectSpec.value.items || []).filter(
          configSpec => configSpec.value.valueType === 'object'
        ),
      ])
  )
  const rest = configSpecs.filter(
    configSpec => configSpec.value.valueType !== 'object'
  )

  return [...objectSpecs, ...rest]
}

export function getExistingCarrierAccountData(
  existingData: ExistingCarrierAccountData
): CarrierAccountData {
  const incompleteCarrierAccount = clone(existingData.incompleteCarrierAccount)

  return {
    carrier: incompleteCarrierAccount.createCarrierAccount.carrierKey,
    carrierServices:
      incompleteCarrierAccount.createCarrierAccount.carrierServiceKeys,
    carrierAccountName: incompleteCarrierAccount.createCarrierAccount.name,
    accountDetailsSpecs: existingData.configSpec,
    accountDetails:
      incompleteCarrierAccount.createCarrierAccount.accountDetails,
    documentTypeKeys: existingData.documentTypeKeys,
    sequences: existingData.sequences,
    sequenceDefinitions: existingData.sequenceDefinitions,
    sharedSequences: existingData.sharedSequences || {},
    defaultDocumentUploads: existingData.defaultDocumentUploads,
    profile: existingData.profile,
    uploads: clone(
      existingData.incompleteCarrierAccount.createCarrierAccount.uploads
    ),
  }
}
