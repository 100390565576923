import * as React from 'react'
import styles from './LinkIcon.module.css'

interface Props {
  icon: React.ComponentClass<IconProps>
}

export default function LinkIcon(props: Props) {
  return (
    <span className={styles.svgLinkIcon}>
      <props.icon size="2x" />
    </span>
  )
}
