import * as coam from '@cimpress-technology/coam-sapidus'
import * as React from 'react'
import { useParams } from 'react-router'
import { locations } from '@cimpress-technology/logistics-configuration-client'
import { v4 } from 'uuid'
import * as jsonPatch from 'fast-json-patch'
import Preloader from '../common/components/Preloader'
import FourOhFourPage from '../common/FourOhFourPage'
import { Location, ReportsCustomData, Report, Network } from '../common/models'
import {
  getLocation,
  updateLocation as updateLogisticsLocation,
  getNetwork,
  updateNetwork,
} from '../common/proxy/locations-store'
import { checkPermission } from '../common/proxy/networks-store'
import { bearerToken } from '../common/auth'
import { clone } from '../common/helpers/clone'
import { LocationContext, Provider } from './LocationContext'
import LocationPage from './LocationPage'

export default function LocationPageContainer() {
  const { locationId } = useParams<{ locationId: string }>()
  const [currentLocation, setCurrentLocation] = React.useState<Location>()
  const [loading, setLoading] = React.useState(true)
  const [isNetworkAdmin, setIsNetworkAdmin] = React.useState(false)
  const [reports, setReports] = React.useState<Report[]>([])
  const [network, setNetwork] = React.useState<Network>()

  React.useEffect(() => {
    const fetchLocation = async () => {
      setLoading(true)
      const [logisticsLocation, additionalReports] = await Promise.all([
        getLocation(locationId),
        locations.getCustomData<ReportsCustomData>(
          bearerToken(),
          v4(),
          locationId,
          'reports'
        ),
      ])
      const networkRef =
        logisticsLocation &&
        logisticsLocation._links &&
        logisticsLocation._links.network
      const [isEditable, fetchedNetwork] = networkRef
        ? await Promise.all([
            checkPermission(
              networkRef.id,
              coam.models.LogisticsNetworkPermissions.Update
            ),
            getNetwork(networkRef.id),
          ])
        : [false, undefined]

      setIsNetworkAdmin(isEditable)
      setCurrentLocation(logisticsLocation)
      setReports(additionalReports?.data.reports ?? [])
      setNetwork(fetchedNetwork)
      setLoading(false)
    }

    fetchLocation()
  }, [locationId])

  const updateLocation = async (
    change:
      | locations.models.Location
      | ((location: locations.models.Location) => void),
    correlationId?: string
  ): Promise<void> => {
    const corrId = correlationId ?? v4()
    let locationCopy
    if (typeof change === 'function') {
      locationCopy = clone(currentLocation)
      change(locationCopy!)
    } else {
      locationCopy = change
    }

    const patches = jsonPatch
      .compare(currentLocation!, locationCopy!)
      .filter(patch => !patch.path.includes('href'))

    if (patches.length > 0) {
      await updateLogisticsLocation(
        locationId,
        currentLocation!.etag,
        patches,
        corrId
      )
    }

    await reloadLocation()
  }

  const reloadLocation = async () => {
    const location = await getLocation(locationId)
    setCurrentLocation(location)
  }

  if (loading) {
    return <Preloader />
  }

  if (!currentLocation) {
    return <FourOhFourPage />
  }

  const linkResourceToLocation = (
    resourceId: string,
    resourceType: coam.models.ResourceTypes
  ) => {
    return locations.linkResourceToLocationGroup(
      bearerToken(),
      v4(),
      currentLocation.id,
      resourceId,
      resourceType
    )
  }

  const context: LocationContext = {
    logisticsLocation: currentLocation,
    updateLocation,
    loading,
    isNetworkAdmin,
    reports,
    linkResourceToLocation,
    ...(network && {
      network,
      linkResourceToNetwork: (
        resourceId: string,
        resourceType: coam.models.ResourceTypes
      ) => {
        return locations.linkResourceToNetworkGroup(
          bearerToken(),
          v4(),
          network.apiNetwork.id,
          resourceId,
          resourceType
        )
      },
      updateNetwork: async (
        change:
          | locations.models.LogisticsNetworkWithLinks
          | ((n: locations.models.LogisticsNetworkWithLinks) => void)
      ): Promise<void> => {
        await updateNetwork(network.apiNetwork, change)
        await reloadLocation()
      },
    }),
  }

  return (
    <Provider value={context}>
      <LocationPage />
    </Provider>
  )
}
