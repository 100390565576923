import * as React from 'react'
import { useTranslation } from 'react-i18next'
import IconDeliveryTruck2 from '@cimpress-technology/react-streamline-icons/lib/IconDeliveryTruck2'
export function NoCarrierAccounts(): JSX.Element {
  const { t } = useTranslation()

  return (
    <div className="row">
      <div style={{ textAlign: 'center' }}>
        <div>
          <IconDeliveryTruck2
            style={{ height: '125px', fill: 'var(--alloy)' }}
          />
        </div>
        <span style={{ fontSize: '16px', color: 'var(--shale)' }}>
          {t('carrierAccounts.noCarrierAccountsForLocation')}
        </span>
      </div>
    </div>
  )
}
