import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { ValidationResult } from '../../../common/helpers/validation'
import { ConfigSpec, SpecEvent } from '../../../common/models-carriers'
import { getSpecComponent } from './SpecComponentFactory'

export interface Props extends WithTranslation {
  configSpecs?: ConfigSpec[]
  config: any
  edit: boolean

  onChange?: (specEvent: SpecEvent) => void

  onValidate?: (path: string, spec: ConfigSpec) => ValidationResult
  onBlur?: (path: string) => void

  refMap?: Map<string, React.RefObject<any>>
  children?: React.ReactNode
}

function CarrierConfiguration(props: Props) {
  const configSpecs = props.configSpecs || []

  const objectSpecs = configSpecs.filter(
    configSpec => configSpec.value.valueType === 'object'
  )
  const rest = configSpecs.filter(
    configSpec => configSpec.value.valueType !== 'object'
  )

  let otherInformation
  if (objectSpecs.length === 0 || rest.length === 0) {
    otherInformation = undefined
  } else {
    otherInformation = props.edit ? (
      <h4>{props.t('carrierAccounts.specComponents.otherInformation')}</h4>
    ) : (
      <h5>{props.t('carrierAccounts.specComponents.otherInformation')}</h5>
    )
  }

  return (
    <>
      {objectSpecs.map(configSpec =>
        getSpecComponent(
          configSpec,
          props.config,
          props.edit,
          props.onChange,
          props.onValidate,
          props.onBlur,
          props.edit ? 1 : 2,
          '',
          props.refMap
        )
      )}
      {otherInformation}
      {rest.map(configSpec =>
        getSpecComponent(
          configSpec,
          props.config,
          props.edit,
          props.onChange,
          props.onValidate,
          props.onBlur,
          props.edit ? 1 : 2,
          '',
          props.refMap
        )
      )}
      {props.children}
    </>
  )
}

export default withTranslation()(CarrierConfiguration)
