import { TextField } from '@cimpress/react-components'
import { FieldMetaProps, useField } from 'formik'
import * as React from 'react'
import ValidationErrorMessage from './ValidationErrorMessage'

interface Props {
  name: string
  label: string
  required?: boolean
  ref?: React.RefObject<any>
  autoFocus?: boolean
  type?:
    | 'text'
    | 'number'
    | 'password'
    | 'url'
    | 'search'
    | 'email'
    | 'tel'
    | 'textarea'
}

export default React.forwardRef((props: Props, ref: React.RefObject<any>) => {
  const [field, meta] = useField<string>(props.name)

  return (
    <div className="form-group">
      <TextField
        autoFocus={props.autoFocus}
        name={field.name}
        label={props.label}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        bsStyle={toBSStyle(meta)}
        required={props.required}
        ref={ref}
      />
      {meta.touched && <ValidationErrorMessage message={meta.error} />}
    </div>
  )
})

function toBSStyle(
  meta: FieldMetaProps<string>
): 'success' | 'error' | undefined {
  if (!meta.touched) {
    return undefined
  } else if (meta.error) {
    return 'error'
  } else {
    return 'success'
  }
}
