import * as React from 'react'
import { ConfigSpec } from '../../../common/models-carriers'
import styles from './CarrierConfiguration.module.css'

interface Props {
  spec: ConfigSpec
  value: string
}

export default function ViewSpecComponent(props: Props) {
  let value: React.ReactNode = props.value
  if (typeof value === 'boolean') {
    value = <i className={`fa fa-fw ${value ? 'fa-check' : 'fa-times'}`} />
  }

  return (
    <div className={styles.viewSpec}>
      <div>{props.spec.name}</div>
      <div>{value}</div>
    </div>
  )
}
