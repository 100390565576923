import { Modal } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import IconTruckConfigure from '@cimpress-technology/react-streamline-icons/lib/IconTruckConfigure'
import AnchorButton from '../../../common/components/AnchorButton'
import OpenInNewTab from '../../../common/components/OpenInNewTab'
import Preloader from '../../../common/components/Preloader'
import { config } from '../../../common/config/config'
import * as qcmStore from '../../../common/proxy/qcm-store'
import { useLogisticsLocation } from '../../LocationContext'
import styles from './QcmLink.module.css'
import LinkIcon from './LinkIcon'

export interface LinkData {
  id: string
  link: string | undefined
  flName: string
  fulfillerName: string | undefined
}

export default function QcmLink(): JSX.Element {
  const { logisticsLocation } = useLogisticsLocation()
  const [links, setLinks] = React.useState<LinkData[]>([])
  const [loading, setLoading] = React.useState(true)
  const [showModal, setShowModal] = React.useState(false)
  const { t } = useTranslation()
  React.useEffect(() => {
    const fetchData = async () => {
      const l = await Promise.all(
        logisticsLocation.fulfillmentLocations.map(async fl => ({
          id: fl.id,
          link: await qcmStore.getQcmLink(fl.id),
          flName: fl.name,
          fulfillerName: fl.fulfiller.name,
        }))
      )
      setLinks(l)
      setLoading(false)
    }
    fetchData()
  }, [logisticsLocation])
  if (loading) {
    return <Preloader small={true} />
  }
  if (links.length === 1) {
    const href = links[0].link ?? config.qcm

    return (
      <OpenInNewTab href={href}>
        <LinkIcon icon={IconTruckConfigure} />
        {t('locations.viewLocationPage.ccmConfiguration')}
      </OpenInNewTab>
    )
  }

  const openModal = () => setShowModal(true)
  const hideModal = () => setShowModal(false)

  return (
    <>
      <AnchorButton onClick={openModal}>
        <LinkIcon icon={IconTruckConfigure} />
        {t('locations.viewLocationPage.ccmConfiguration')}
      </AnchorButton>
      <Modal
        show={showModal}
        closeButton={true}
        onRequestHide={hideModal}
        title={t('locations.viewLocationPage.selectCcmConfigurationTitle')}
        footer={
          <button className="btn btn-default" onClick={hideModal}>
            {t('common.close')}
          </button>
        }
      >
        <div className={styles.description}>
          {t('locations.viewLocationPage.selectCcmConfigurationText')}
        </div>
        <div className="list-group">
          {links.map(l => {
            const name = `${l.flName} • ${l.fulfillerName}`
            if (l.link) {
              return (
                <OpenInNewTab
                  key={l.id}
                  href={l.link}
                  className="list-group-item"
                >
                  {name}
                </OpenInNewTab>
              )
            }

            return (
              <span key={l.id} className="list-group-item">
                {name}
              </span>
            )
          })}
        </div>
      </Modal>
    </>
  )
}
