import axios from 'axios'

const CIMBA_URL = 'https://nala.cimpress.io'

export interface Filter {
  filter: string
  filterValue: string
}

interface GenerateLinkResponse {
  signedEmbedUrl: string
}

export async function generateLink(
  bearerToken: string,
  configurationId: string,
  filters?: Filter[]
) {
  const response = await axios.post<GenerateLinkResponse>(
    `${CIMBA_URL}/v0/embed/generateLink`,
    {
      configurationId,
      filters,
    },
    {
      headers: {
        authorization: bearerToken,
      },
    }
  )

  return response.data
}
