import * as React from 'react'
import { ValidationResult } from '../../../common/helpers/validation'
import { ConfigSpec, SpecEvent } from '../../../common/models-carriers'
import { SpecVisitor, traverse } from '../../specVisitor'
import BooleanSpecComponent from './BooleanSpecComponent'
import NumberSpecComponent from './NumberSpecComponent'
import ObjectSpecComponent from './ObjectSpecComponent'
import StringSpecComponent from './StringSpecComponent'
import ViewSpecComponent from './ViewSpecComponent'

interface EventHandlers {
  onChange?: (specEvent: SpecEvent) => void
  onValidate?: (path: string, spec: ConfigSpec) => ValidationResult
  onBlur?: (path: string) => void
}

const ComponentSpecVisitor = (
  config: any,
  edit: boolean,
  eventHandlers: EventHandlers,
  rootPath: string,
  components: React.ReactNodeArray,
  refMap: Map<string, React.RefObject<any>>,
  level = 0
): SpecVisitor => {
  return {
    visitObject: (spec: ConfigSpec) => {
      const handleChange = (event: SpecEvent) => {
        event.path = `${spec.key}/${event.path}`

        eventHandlers.onChange!(event)
      }

      const handleValidate = (
        path: string,
        configSpec: ConfigSpec
      ): ValidationResult => {
        return eventHandlers.onValidate!(`${spec.key}/${path}`, configSpec)
      }

      const handleBlur = (path: string) => {
        return eventHandlers.onBlur!(`${spec.key}/${path}`)
      }

      const childrenComponents: React.ReactNodeArray = []

      if (spec.value.items) {
        spec.value.items.map(item =>
          traverse(
            item,
            ComponentSpecVisitor(
              config[spec.key],
              edit,
              {
                onBlur: handleBlur,
                onValidate: handleValidate,
                onChange: handleChange,
              },
              rootPath !== '' ? `${rootPath}/${spec.key}` : spec.key,
              childrenComponents,
              refMap!,
              level + 1
            )
          )
        )
      }

      components.push(
        <ObjectSpecComponent
          spec={spec}
          obj={config[spec.key]}
          onChange={eventHandlers.onChange!}
          key={spec.key}
          level={level}
          edit={edit}
          onValidate={eventHandlers.onValidate!}
          onBlur={eventHandlers.onBlur!}
          refMap={refMap}
        >
          {childrenComponents}
        </ObjectSpecComponent>
      )
    },
    visitBoolean: (spec: ConfigSpec) => {
      if (spec.category === 'custom') {
        return
      } else if (edit) {
        components.push(
          <BooleanSpecComponent
            spec={spec}
            value={config[spec.key]}
            onChange={eventHandlers.onChange!}
            key={spec.key}
          />
        )
      } else {
        components.push(
          <ViewSpecComponent
            spec={spec}
            value={config[spec.key]}
            key={spec.key}
          />
        )
      }
    },
    visitString: (spec: ConfigSpec) => {
      if (spec.category === 'custom') {
        return
      } else if (edit) {
        const ref = React.createRef()
        refMap.set(rootPath !== '' ? `${rootPath}/${spec.key}` : spec.key, ref)

        components.push(
          <StringSpecComponent
            spec={spec}
            value={config[spec.key]}
            onChange={eventHandlers.onChange!}
            key={spec.key}
            onValidate={eventHandlers.onValidate!}
            onBlur={eventHandlers.onBlur!}
            specRef={ref}
          />
        )
      } else {
        if (spec.value.isSensitive) {
          components.push(
            <ViewSpecComponent spec={spec} value="***" key={spec.key} />
          )
        } else {
          components.push(
            <ViewSpecComponent
              spec={spec}
              value={config[spec.key]}
              key={spec.key}
            />
          )
        }
      }
    },
    visitNumber: (spec: ConfigSpec) => {
      if (spec.category === 'custom') {
        return
      } else if (edit) {
        const ref = React.createRef()
        refMap.set(rootPath !== '' ? `${rootPath}/${spec.key}` : spec.key, ref)

        components.push(
          <NumberSpecComponent
            spec={spec}
            value={config[spec.key]}
            onChange={eventHandlers.onChange!}
            key={spec.key}
            onValidate={eventHandlers.onValidate!}
            onBlur={eventHandlers.onBlur!}
            specRef={ref}
          />
        )
      } else {
        components.push(
          <ViewSpecComponent
            spec={spec}
            value={config[spec.key]}
            key={spec.key}
          />
        )
      }
    },
  }
}

export function getSpecComponent(
  spec: ConfigSpec,
  obj: any,
  edit: boolean,
  onChange?: (specEvent: SpecEvent) => void,
  onValidate?: (path: string, spec: ConfigSpec) => ValidationResult,
  onBlur?: (path: string) => void,
  level = 0,
  path = '',
  refMap?: Map<string, React.RefObject<any>>
): React.ReactNode | undefined {
  const components: React.ReactNodeArray = []

  const componentSpecVisitor = ComponentSpecVisitor(
    obj,
    edit,
    { onChange, onBlur, onValidate },
    path,
    components,
    refMap!,
    level
  )
  traverse(spec, componentSpecVisitor)

  return components[0]
}
