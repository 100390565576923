import * as React from 'react'
import * as _ from 'underscore'
import { anyWholeWordStartsWithIgnoringCaseIndex } from '../../common/helpers/filters'
import './Highlight.css'

interface Props {
  text: string
  highlight: string
}

export default function Highlight(props: Props) {
  if (_.isEmpty(props.highlight.trim())) {
    return <>{props.text}</>
  }

  return chop(props.text, props.highlight.trim())
}

function chop(text: string, input: string): JSX.Element {
  const match = anyWholeWordStartsWithIgnoringCaseIndex(text, input)
  if (!match) {
    return <>{text}</>
  } else {
    const mark = match.index
    const len = match[0].length

    return (
      <>
        {text.substr(0, mark)}
        <span className={'search-highlight'}>{text.substr(mark, len)}</span>
        {text.substr(mark + len)}
      </>
    )
  }
}
