import * as React from 'react'
import { useHistory } from 'react-router'
import styles from './ClickableTableRow.module.css'

interface Props {
  href: string
  children: React.ReactNode
}

export function ClickableTableRow(props: Props): JSX.Element {
  const history = useHistory()
  const onClick = (e: React.MouseEvent) => {
    history.push(props.href)
    e.preventDefault()
  }

  return (
    <tr onClick={onClick} className={styles.tablerow}>
      {props.children}
    </tr>
  )
}
