import { Button, Modal } from '@cimpress/react-components'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { EnhancedCaasProfile } from '../../../common/models-carriers'
import {
  CarrierSelection,
  isCarrierSelectCompleted,
} from './carrierAccountData'
import CarrierSelectStep from './CarrierSelectStep'

interface Props extends WithTranslation {
  carrierAccountTypes: EnhancedCaasProfile[]
  carrierAccountData: CarrierSelection
  onClose: () => void
  onSelect: () => void
  onChange: (carrierAccountData: CarrierSelection) => void
  show: boolean
}
function CarrierSelectModal(props: Props) {
  const footer = (
    <>
      <Button type="default" onClick={props.onClose} key="cancel-btn">
        Cancel
      </Button>
      <Button
        type="primary"
        onClick={props.onSelect}
        key="save-btn"
        disabled={!isCarrierSelectCompleted(props.carrierAccountData)}
      >
        {props.t('carrierAccounts.addCarrierFlow.selectCarrier')}
      </Button>
    </>
  )

  return (
    <Modal
      show={props.show}
      onRequestHide={props.onClose}
      closeButton={true}
      title={props.t('carrierAccounts.addCarrierFlow.selectCarrier')}
      footer={footer}
    >
      <div style={{ marginBottom: '12px' }}>
        <div>
          <CarrierSelectStep
            caasProfiles={props.carrierAccountTypes}
            data={props.carrierAccountData}
            onChange={props.onChange}
          />
        </div>
      </div>
    </Modal>
  )
}

export default withTranslation()(CarrierSelectModal)
