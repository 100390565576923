import * as React from 'react'
import { Select } from '@cimpress/react-components'
import { useTranslation } from 'react-i18next'
import { Option } from '../../../common/models'
import EmbeddedLooker from '../../../common/components/EmbeddedLooker'
import {
  DASHBOARD_SHIPPING_OPERATIONS,
  DASHBOARD_CARRIER_PERFORMANCE,
} from '../../../common/proxy/cimba-models'
import { useLogisticsLocation } from '../../LocationContext'

export default function ReportViewer() {
  const { t } = useTranslation()
  const { reports } = useLogisticsLocation()

  const [report, setReport] = React.useState(DASHBOARD_SHIPPING_OPERATIONS)

  const options = [
    {
      label: t('fulfillerOverview.reports.shippingOperations'),
      value: DASHBOARD_SHIPPING_OPERATIONS,
    },
    {
      label: t('fulfillerOverview.reports.carrierPerformance'),
      value: DASHBOARD_CARRIER_PERFORMANCE,
    },
    ...reports.map(r => ({
      label: r.name,
      value: r.configurationId,
    })),
  ]

  const value = options.find(o => o.value === report)

  const onChange = (newValue: Option) => {
    setReport(newValue.value)
  }

  return (
    <>
      <div className="row">
        <div className="col-xs-12 col-md-5">
          <Select
            label={t('fulfillerOverview.selectADashboard')}
            value={value}
            isClearable={false}
            onChange={onChange}
            options={options}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <EmbeddedLooker configurationId={report} />
        </div>
      </div>
    </>
  )
}
