import { Button } from '@cimpress/react-components'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { SequenceDefinition, Uploads } from '../../../common/models'
import * as sequenceHelpers from '../sequences/helpers'
import MultipleSequencesEditor from '../sequences/MultipleSequencesEditor'
import { UploadsEditContainer } from '../uploads/UploadsEditContainer'

import { isSequenceValid } from '../sequences/sequenceEditorValidation'
import { isUploadValid } from '../uploads/uploadEditorValidation'
import { CarrierAccountData } from './carrierAccountData'
import StepContainer from './StepContainer'
import { StepDefinition, Steps } from './steps'

export function showDocumentsStep(carrierAccountData: CarrierAccountData) {
  const showSequences =
    carrierAccountData.sequences &&
    carrierAccountData.sequences.some(seq =>
      sequenceHelpers.showAsUploadSequence(seq)
    )

  const showUploads =
    carrierAccountData.defaultDocumentUploads &&
    carrierAccountData.defaultDocumentUploads
      .map(isUploadValid)
      .some(u => u === false)

  return showSequences || showUploads
}

interface Props extends WithTranslation {
  carrierAccountData: CarrierAccountData
  canAddOrRemoveUpload: boolean
  previousStep: StepDefinition
  nextStep: StepDefinition

  onUploadsChange: (uploads: Uploads[], areUploadsValid: boolean[]) => void

  onSequenceDefinitionChange: (
    sequenceDefinitions: SequenceDefinition[]
  ) => void

  saveIncompleteAccount: () => Promise<void>

  onStepChanged: (step: Steps) => void
}

function DocumentsStep(props: Props) {
  const showSequenceEditor =
    props.carrierAccountData.sequences &&
    props.carrierAccountData.sequences.some(seq =>
      sequenceHelpers.showAsUploadSequence(seq)
    )

  const [highlightAllErrors, setHighlightAllErrors] = React.useState(false)
  const [uploadResults, setUploadResults] = React.useState<boolean[]>([])
  const [sequenceResults, setSequenceResults] = React.useState<boolean[]>([])

  const back = () => {
    props.onStepChanged(props.previousStep.key)
  }

  const onUploadsChange = (uploads: Uploads[], areUploadsValid: boolean[]) => {
    setUploadResults(areUploadsValid)

    return props.onUploadsChange(uploads, areUploadsValid)
  }

  const onSequenceDefinitionChange = (
    sequenceDefinitions: SequenceDefinition[],
    areSequencesValid: boolean[]
  ) => {
    setSequenceResults(areSequencesValid)

    return props.onSequenceDefinitionChange(sequenceDefinitions)
  }

  const configureUploads = () => {
    setHighlightAllErrors(true)
    const areValid = [
      ...props.carrierAccountData.uploads.map(isUploadValid),
      ...props.carrierAccountData.sequenceDefinitions!.map(isSequenceValid),
    ].every(Boolean)

    if (areValid) {
      return props.onStepChanged(props.nextStep.key)
    }
  }

  const areDocumentUploadsEditable =
    props.carrierAccountData.defaultDocumentUploads &&
    props.carrierAccountData.defaultDocumentUploads
      .map(isUploadValid)
      .some(u => u === false)

  const footer = (
    <>
      {props.previousStep && (
        <Button type="default" onClick={back}>
          <i className="fa fa-angle-left" /> {props.previousStep.contents}
        </Button>
      )}{' '}
      <Link to="../carrier-accounts" className="btn btn-link">
        {props.t('common.cancel')}
      </Link>
      <span style={{ float: 'right' }}>
        <Button type="link" onClick={props.saveIncompleteAccount}>
          {props.t('carrierAccounts.addCarrierFlow.saveAndFinishLater')}
        </Button>{' '}
        <Button
          type="primary"
          onClick={configureUploads}
          disabled={
            highlightAllErrors &&
            [...uploadResults, ...sequenceResults].some(
              value => value === false
            )
          }
        >
          {props.t('carrierAccounts.addCarrierFlow.configureUploads')}
          <i className="fa fa-angle-right icon-on-right" />
        </Button>
      </span>
    </>
  )

  return (
    <StepContainer header="Documents" footer={footer}>
      <UploadsEditContainer
        caasProfileKey={props.carrierAccountData.carrier!}
        uploads={props.carrierAccountData.uploads}
        edit={areDocumentUploadsEditable}
        documentTypeKeys={props.carrierAccountData.documentTypeKeys}
        onChange={onUploadsChange}
        canAddOrRemoveUpload={props.canAddOrRemoveUpload}
        validateAll={highlightAllErrors}
      />
      {showSequenceEditor ? (
        <MultipleSequencesEditor
          sequenceTypeToShow={'upload'}
          sequences={props.carrierAccountData.sequences!}
          onSequenceDefinitionChange={onSequenceDefinitionChange}
          sequenceDefinitions={props.carrierAccountData.sequenceDefinitions!}
          areValid={sequenceResults}
        />
      ) : undefined}
    </StepContainer>
  )
}

export default withTranslation()(DocumentsStep)
