import * as React from 'react'
import { ValidationStatus } from '../../common/helpers/validation'
import './ValidatedSelect.css'

interface Props {
  validationStatus: ValidationStatus
  children?: React.ReactChild
}

export function ValidatedSelect(props: Props) {
  return (
    <div className={getClassName(props.validationStatus)}>{props.children}</div>
  )
}

const getClassName = (
  validationStatus: ValidationStatus
): string | undefined => {
  switch (validationStatus) {
    case ValidationStatus.NotValidated:
      return undefined
    case ValidationStatus.Invalid:
      return 'select-invalid'
    case ValidationStatus.Valid:
      return 'select-valid'
  }
}
