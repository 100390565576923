import { FourOhFour as CimpressFourOhFour } from '@cimpress/react-components'
import * as React from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function FourOhFourPage() {
  return (
    <main className="App-content container">
      <div className="row">
        <CimpressFourOhFour
          message={
            <div>
              <Trans i18nKey={'somethingWentWrong'}>
                Oops! Something has gone wrong and the page you were looking for
                could not be displayed. Try <Link to="/">the homepage</Link>.
              </Trans>
            </div>
          }
        />
      </div>
    </main>
  )
}
