import * as React from 'react'
import { locations } from '@cimpress-technology/logistics-configuration-client'
import * as uuid from 'uuid'
import { bearerToken } from '../auth'
import { useLogisticsLocation } from '../../locations/LocationContext'

export function isActive(notification: locations.models.Notification) {
  return (
    notification.locationState === 'active' &&
    notification.userState === 'active'
  )
}

export function useNotifications() {
  const { logisticsLocation } = useLogisticsLocation()
  const [notifications, setNotifications] = React.useState<
    locations.models.Notification[]
  >([])
  const [loading, setLoading] = React.useState(true)

  const unreads = notifications.filter(isActive)

  const dismissUnreads = async () => {
    if (unreads.length > 0) {
      const token = bearerToken()
      await Promise.all(
        unreads.map(u =>
          locations.dismissNotification(token, uuid.v4(), u.locationId, u.id)
        )
      )

      return getAllNotifications()
    }
  }

  const createNotification = async (
    notification: Omit<locations.models.CreateNotification, 'locationId'>
  ) => {
    const token = bearerToken()
    await locations.createNotification(token, uuid.v4(), {
      ...notification,
      locationId: logisticsLocation.id,
    })

    return getAllNotifications()
  }

  const getAllNotifications = React.useCallback(async () => {
    const response = await locations.getAllNotifications(
      bearerToken(),
      uuid.v4(),
      logisticsLocation.id
    )
    setNotifications(response.items)
    setLoading(false)
  }, [logisticsLocation.id])

  React.useEffect(() => {
    getAllNotifications()
  }, [getAllNotifications, logisticsLocation.id])

  return {
    loading,
    notifications,
    unreads,
    dismissUnreads,
    createNotification,
  }
}
