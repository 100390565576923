import { Select } from '@cimpress/react-components'
import { useField } from 'formik'
import moment from 'moment-timezone'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Option } from '../common/models'

const allowedDateFormats = ['DD.MM.YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD']

interface Props {
  name: string
}
export default function DateFormatPicker(props: Props) {
  const { t } = useTranslation()
  const [field, , helpers] = useField(props.name)

  const options = allowedDateFormats.map(dateFormat => ({
    label: `${dateFormat} (${moment().format(dateFormat)})`,
    value: dateFormat,
  }))

  const onChange = (data: Option) => {
    helpers.setValue(data ? (data.value as string) : '')
  }

  return (
    <Select
      label={t('locations.dateFormat')}
      name={field.name}
      value={options.filter(o => o.value === field.value)}
      options={options}
      onChange={onChange}
    />
  )
}
