import { Button } from '@cimpress/react-components'
import React, { useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ValidationResult } from '../../../common/helpers/validation'
import { ConfigSpec } from '../../../common/models-carriers'
import { getValidationErrors } from '../../carrierAccountValidation'
import {
  CarrierConfigurationContainer,
  CarrierConfigurationContainerHandles,
} from '../spec-components/CarrierConfigurationContainer'
import { CarrierAccountData } from './carrierAccountData'
import StepContainer from './StepContainer'
import { StepDefinition, Steps } from './steps'

export function showCarrierAccountStep(configSpecs?: ConfigSpec[]) {
  return configSpecs !== undefined && configSpecs.length > 0
}

interface Props extends WithTranslation {
  saveIncompleteAccount: () => Promise<void>
  carrierAccountData: CarrierAccountData
  nextStep: StepDefinition

  onCarrierAccountChange: (accountDetails: any) => void
  onStepChanged: (step: Steps) => void
}

function CarrierAccountStep(props: Props) {
  const [valid, setValid] = useState(false)
  const [highlightAllErrors, setHighlighAllErrors] = useState(false)

  const carrierConfigurationContainerRef = React.useRef<
    CarrierConfigurationContainerHandles
  >()

  // Reset state on carrier change
  React.useEffect(() => {
    setValid(false)
    setHighlighAllErrors(false)
  }, [props.carrierAccountData.carrier])

  const configureCarrier = () => {
    setHighlighAllErrors(true)
    if (carrierConfigurationContainerRef.current) {
      carrierConfigurationContainerRef.current.jumpToFirstError()
    }
    const isValid =
      getValidationErrors(
        props.carrierAccountData.accountDetailsSpecs!,
        props.carrierAccountData.accountDetails
      ).length === 0

    if (isValid) {
      return props.onStepChanged(props.nextStep.key)
    }
  }

  const onCarrierAccountChange = (
    accountDetails: any,
    validationResults: ValidationResult[]
  ) => {
    setValid(validationResults.length === 0)
    props.onCarrierAccountChange(accountDetails)
  }

  const footer = (
    <>
      <Link to="../carrier-accounts" className="btn btn-link">
        {props.t('common.cancel')}
      </Link>
      <span
        style={{
          float: 'right',
        }}
      >
        <Button type="link" onClick={props.saveIncompleteAccount}>
          {props.t('carrierAccounts.addCarrierFlow.saveAndFinishLater')}
        </Button>{' '}
        <Button
          type="primary"
          onClick={configureCarrier}
          disabled={highlightAllErrors && !valid}
        >
          {props.t('carrierAccounts.addCarrierFlow.configureCarrier')}
          <i className="fa fa-angle-right icon-on-right" />
        </Button>
      </span>
    </>
  )

  return (
    <StepContainer header="Account Details" footer={footer}>
      <CarrierConfigurationContainer
        ref={carrierConfigurationContainerRef}
        key={props.carrierAccountData.carrier!}
        edit={true}
        accountDetails={props.carrierAccountData.accountDetails}
        configSpecs={props.carrierAccountData.accountDetailsSpecs}
        onCarrierAccountChange={onCarrierAccountChange}
        highlightAllErrors={highlightAllErrors}
      />
    </StepContainer>
  )
}

export default withTranslation()(CarrierAccountStep)
