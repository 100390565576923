import { Tooltip } from '@cimpress/react-components'
import * as React from 'react'
import { useUserContext } from '../UserContext'

interface Props {
  enableTooltip?: boolean
  tooltipStyle?: React.CSSProperties
  wrapper?: React.ReactElement
  fallback?: React.ReactElement
  children?: React.ReactNode
}

export function AdvancedUserContainer(props: Props) {
  const { isAdvancedUser } = useUserContext()
  if (!isAdvancedUser) {
    return props.fallback ? props.fallback : null
  }

  if (props.enableTooltip) {
    const content = (
      <Tooltip
        contents={'Available only for Logistics Technology members'}
        key="message-tooltip"
        style={props.tooltipStyle}
      >
        {props.children}
      </Tooltip>
    )

    if (props.wrapper) {
      return React.cloneElement(props.wrapper, [], content)
    }

    return content
  }

  return <>{props.children}</>
}
