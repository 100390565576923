import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import { colors } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import AnchorButton from '../../common/components/AnchorButton'
import { ClickableTableRow } from '../../common/components/ClickableTableRow'
import { useLogisticsLocation } from '../../locations/LocationContext'
import style from './CarrierAccountsList.module.css'

interface Props {
  carrierAccount: carrierAccounts.models.CarrierAccountWithLink
  carrierLogo?: string
}

export function CompleteCarrierAccountRow(props: Props): JSX.Element {
  const { t } = useTranslation()
  const { logisticsLocation } = useLogisticsLocation()

  const logo = props.carrierLogo ? (
    <div
      style={{
        background: `url(${process.env.PUBLIC_URL}/images/carrier-logos/${props.carrierLogo}) center center no-repeat`,
        backgroundSize: '56px',
        backgroundColor: colors.white,
      }}
    />
  ) : (
    <div />
  )

  const serviceCount = props.carrierAccount.carrierServiceKeys.length

  return (
    <ClickableTableRow
      href={`/location/${logisticsLocation.id}/carrier-accounts/${props.carrierAccount.id}`}
    >
      <td className={style.carrierLogo}>{logo}</td>
      <td className={style.carrierName}>
        <AnchorButton>{props.carrierAccount.name}</AnchorButton>
      </td>
      <td className={style.carrierServiceCount}>
        {t('carrierAccounts.carrierServiceCount', {
          count: serviceCount,
        })}
      </td>
    </ClickableTableRow>
  )
}
