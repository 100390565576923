import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation } from 'react-i18next'
import AdvancedAudit from '../../locations/components/view-location-page/AdvancedAudit'
import useWideContainer from '../../common/components/useContainer'
import { useLogisticsLocation } from '../../locations/LocationContext'
import { NetworkBreadCrumbItem } from '../../common/components/RouterWithBreadcrumbs'
import styles from './AuditPage.module.css'

export default function LocationAuditPage() {
  const { t } = useTranslation()
  const { logisticsLocation } = useLogisticsLocation()
  useWideContainer()

  return (
    <>
      <BreadcrumbsItem to="/">{t('common.logisticsLocations')}</BreadcrumbsItem>
      <NetworkBreadCrumbItem />
      <BreadcrumbsItem to={`/location/${logisticsLocation.id}`}>
        {logisticsLocation.name}
      </BreadcrumbsItem>
      <BreadcrumbsItem to="#">History</BreadcrumbsItem>
      <div className="row">
        <div className="col-xs-12">
          <div>
            <h4>Change history for {logisticsLocation.name}</h4>
          </div>
        </div>
      </div>
      <div
        className={`row ${styles.marginTop} ${styles.advancedAuditTable} ${styles.marginBottom}`}
      >
        <div className="col-xs-12">
          <div className="card card-minimal">
            <AdvancedAudit />
          </div>
        </div>
      </div>
    </>
  )
}
