import * as React from 'react'
import * as models from '../common/models'
import * as FulfillmentLocationPicker from './FulfillmentLocationPicker'

interface Props {
  name: string
  required: boolean
  availableFLs: models.FulfillmentLocation[] | undefined
  usedFLs: models.FulfillmentLocation[]
  selectReference: React.RefObject<any>
  disabled?: boolean
  loadingText: string
  onChange(fulfillmentLocation?: models.FulfillmentLocation): void
}

export default function FulfillmentLocationPickerContainer(props: Props) {
  if (props.availableFLs) {
    return (
      <FulfillmentLocationPicker.Loaded
        name={props.name}
        availableFLs={props.availableFLs}
        usedFLs={props.usedFLs}
        onChange={props.onChange}
        required={props.required}
        selectReference={props.selectReference}
        disabled={props.disabled}
      />
    )
  }

  return <FulfillmentLocationPicker.Loading value={props.loadingText} />
}
