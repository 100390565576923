import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'

const carrierLogos: Array<{ regexp: RegExp; logo: string }> = [
  { regexp: /^colissimo/, logo: 'colissimo.png' },
  { regexp: /^dpd/, logo: 'dpd.png' },
  { regexp: /^gls-/, logo: 'gls.png' },
  { regexp: /^postnl/, logo: 'postnl.png' },
  { regexp: /^royal-mail/, logo: 'royal-mail.png' },
  { regexp: /^ups-/, logo: 'ups.png' },
  { regexp: /^usps/, logo: 'usps.png' },
  { regexp: /^brt/, logo: 'brt.png' },
  { regexp: /^dhl/, logo: 'dhl.png' },
]

export function guessLogoFromCarrierAccount(
  carrierAccount: carrierAccounts.models.CreateCarrierAccount
): string {
  return guessLogo(carrierAccount.carrierKey)
}

export function guessLogoFromCarrierServiceKey(recskey: string): string {
  return guessLogo(recskey.replace(/^recs:/, ''))
}

export function guessLogo(carrierKey: string): string {
  for (const { regexp, logo } of carrierLogos) {
    if (carrierKey.match(regexp)) {
      return logo
    }
  }

  return 'unknown.png'
}
