import { Button } from '@cimpress/react-components'
import * as React from 'react'
import { Context } from './ContextMenu'

interface Props {
  label: React.ReactNode
  onClick: () => void
}

export default function ContextMenuItem(props: Props) {
  const { clicked } = React.useContext(Context)

  const onClick = () => {
    clicked()
    props.onClick()
  }

  return (
    <Button onClick={onClick} blockLevel={true} type="link">
      {props.label}
    </Button>
  )
}
