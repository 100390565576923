import { Select, SelectProps } from '@cimpress/react-components'
import * as React from 'react'
import Highlight from './Highlight'

interface Props extends SelectProps {
  selectReference?: React.RefObject<any>
}
export default function HighlightedSelect(props: Props) {
  return (
    <Select
      {...props}
      innerRef={props.selectReference}
      formatOptionLabel={formatOptionLabel}
    />
  )
}

function formatOptionLabel(option: any, labelMeta: any) {
  const inputValue = labelMeta.inputValue

  return inputValue && inputValue.length ? (
    <Highlight text={option.label} highlight={inputValue} />
  ) : (
    option.label
  )
}
