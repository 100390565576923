import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch, useParams } from 'react-router-dom'
import IconCalendar from '@cimpress-technology/react-streamline-icons/lib/IconCalendar'
import IconMultipleUsers from '@cimpress-technology/react-streamline-icons/lib/IconMultipleUsers'
import IconTruckConfigure from '@cimpress-technology/react-streamline-icons/lib/IconTruckConfigure'
import IconSearch from '@cimpress-technology/react-streamline-icons/lib/IconSearch'
import IconShipmentBox from '@cimpress-technology/react-streamline-icons/lib/IconShipmentBox'
import OpenInNewTab from '../common/components/OpenInNewTab'
import NavigationLink from '../locations/components/view-location-page/NavigationLink'
import LinkIcon from '../locations/components/view-location-page/LinkIcon'
import styles from './ViewNetworkPage.module.css'
import { useNetwork } from './NetworkContext'

export default function NetworkTools() {
  const match = useRouteMatch()
  const params = useParams<{ networkId: string }>()
  const { t } = useTranslation()

  return (
    <div className={`card card-minimal ${styles.marginBottomFull}`}>
      <div className="card-header">
        <h5 className="card-title">{t('networks.links.title')}</h5>
      </div>
      <div className={`${styles.configuration} card-block`}>
        <ul className="list-group">
          <NavigationLink
            url={`${match.url}/calendars/transit`}
            text={t('common.calendars.calendars')}
            icon={IconCalendar}
          />
          <NavigationLink
            url={`${match.url}/user-management`}
            text={t('userManagement.userManagement')}
            icon={IconMultipleUsers}
          />
          <QcmLink />
          <NavigationLink
            url={`${match.url}/packing`}
            text={t('packing.packing')}
            icon={IconShipmentBox}
          />
          <NavigationLink
            url={`/audits/logistics-network/${params.networkId}`}
            text={t('common.history')}
            icon={IconSearch}
          />
        </ul>
      </div>
    </div>
  )
}

function QcmLink() {
  const { network } = useNetwork()
  const { t } = useTranslation()

  if (!network.apiNetwork.quoterConfiguration) {
    return null
  }

  const href = `https://qcm.qp.cimpress.io/ui/fulfillmentLocations/${network.apiNetwork.quoterConfiguration.baseId}`

  return (
    <li className="list-group-item">
      <OpenInNewTab href={href}>
        <LinkIcon icon={IconTruckConfigure} />
        {t('networks.links.carrierContractsManagement')}
      </OpenInNewTab>
    </li>
  )
}
