import { bearerToken } from '../auth'
import { generateLink } from './cimba-proxy'

export async function generateEmbedUrl(
  configurationId: string,
  locationId: string
) {
  const result = await generateLink(bearerToken(), configurationId, [
    { filter: 'Logistic Location ID', filterValue: locationId },
  ])

  return result.signedEmbedUrl
}
