import * as React from 'react'
import { Helmet } from 'react-helmet'
import { RouteComponentProps } from 'react-router'
import _ from 'underscore'
import { WorldWide } from '../common/components/CountryPicker'
import Preloader from '../common/components/Preloader'
import FourOhFourPage from '../common/FourOhFourPage'
import { Option } from '../common/models'
import {
  CarrierService,
  CarrierServiceCapability,
  CarrierServiceShippingRegion,
  EnhancedCaasProfile,
} from '../common/models-carriers'
import {
  filterAvailableCaasProfiles,
  getCaasProfiles,
} from '../common/proxy/carrier-services-store'
import CarrierExplorerPage, { OnChangeData } from './CarrierExplorerPage'
import './CarrierExplorerPageContainer.css'

export type Modes = 'withinCountry' | 'international'
interface State {
  selectedMode: Modes
  countryCode: string

  fromCountryCode: string
  toCountryCode: string
  allCarrierServices: CarrierService[]
  filteredCarrierServices: CarrierService[]
  loading: boolean
  allCapabilities: CarrierServiceCapability[]
  selectedCapabilities: string[]
}

export default class CarrierServicePageContainer extends React.Component<
  RouteComponentProps<{ carrierServiceKey?: string }>,
  State
> {
  public readonly state: State = {
    loading: false,
    allCarrierServices: [],
    filteredCarrierServices: [],
    countryCode: WorldWide,
    toCountryCode: WorldWide,
    fromCountryCode: WorldWide,
    selectedMode: 'withinCountry',
    allCapabilities: [],
    selectedCapabilities: [],
  }

  public async componentDidMount() {
    this.setState({ loading: true })

    const profiles: EnhancedCaasProfile[] = filterAvailableCaasProfiles(
      await getCaasProfiles()
    )

    const services: CarrierService[] = _.uniq(
      _.flatten(_.pluck(profiles, 'carrierServices')),
      false,
      'key'
    ).map(extendShippingDestinations)

    const capabilities: CarrierServiceCapability[] = _.uniq(
      _.flatten(_.pluck(services, 'capabilities').filter(notUndefined)),
      false,
      'key'
    )

    this.setState({
      loading: false,
      allCarrierServices: services,
      filteredCarrierServices: services,
      allCapabilities: capabilities,
      selectedCapabilities: [],
    })
  }

  public render() {
    if (this.state.loading) {
      return <Preloader />
    }

    const carrierServiceKey = this.props.match.params.carrierServiceKey

    const selectedCarrierService = this.state.allCarrierServices.find(
      cs => cs.key === carrierServiceKey
    )

    if (carrierServiceKey && !selectedCarrierService) {
      return <FourOhFourPage />
    }

    const allCapabilitiesOptions = this.state.allCapabilities.map(c => ({
      value: c.key,
      label: c.name,
    }))

    return (
      <main className="App-content container-fluid carrier-explorer-app">
        <Helmet>
          <title>Carrier Explorer</title>
        </Helmet>
        <CarrierExplorerPage
          countryCode={this.state.countryCode}
          toCountryCode={this.state.toCountryCode}
          fromCountryCode={this.state.fromCountryCode}
          selectedMode={this.state.selectedMode}
          onChangeMode={this.onChangeMode}
          onChange={this.onChange}
          carrierServices={this.state.filteredCarrierServices}
          selectedCarrierService={selectedCarrierService}
          allCapabilities={allCapabilitiesOptions}
          selectedCapabilities={this.state.selectedCapabilities}
          onAddCapability={this.onAddCapability}
          onRemoveCapability={this.onRemoveCapability}
        />
      </main>
    )
  }

  public onChange = (e: OnChangeData): void => {
    e.value = e.value === '' ? WorldWide : e.value
    const stateCloned = JSON.parse(JSON.stringify(this.state))
    stateCloned[e.label] = e.value
    if (e.label === 'countryCode') {
      stateCloned.toCountryCode = e.value
      stateCloned.fromCountryCode = e.value
    }
    this.setState(stateCloned)
    this.filterCarrierServices()
  }

  private filterCarrierServices = () => {
    let filteredServices: CarrierService[]

    if (this.state.selectedMode === 'international') {
      filteredServices = this.state.allCarrierServices.filter(cs =>
        isShipping(
          this.state.toCountryCode,
          this.state.fromCountryCode,
          cs.shippingRegion
        )
      )
    } else {
      filteredServices = this.state.allCarrierServices.filter(cs =>
        isShipping(
          this.state.countryCode,
          this.state.countryCode,
          cs.shippingRegion
        )
      )
    }

    if (this.state.selectedCapabilities.length > 0) {
      filteredServices = filteredServices.filter(cs => {
        return this.containsAllSelectedCapabilities(
          this.state.selectedCapabilities,
          cs.capabilities
        )
      })
    }

    this.setState({
      filteredCarrierServices: filteredServices,
    })
  }

  private onChangeMode = (
    e: React.ChangeEvent & { target: { id: Modes } }
  ): void => {
    this.setState({ selectedMode: e.target.id })
    this.filterCarrierServices()
  }

  private onAddCapability = (e: Option): void => {
    if (this.state.selectedCapabilities.indexOf(e.value as string) === -1) {
      this.state.selectedCapabilities.push(e.value as string)
      this.setState({ selectedCapabilities: this.state.selectedCapabilities })
      this.filterCarrierServices()
    }
  }

  private onRemoveCapability = (e: string): void => {
    const capability:
      | CarrierServiceCapability
      | undefined = this.state.allCapabilities.find(c => c.name === e)

    if (!capability) {
      return
    }

    const index = this.state.selectedCapabilities.indexOf(capability.key)
    if (index > -1) {
      this.state.selectedCapabilities.splice(index, 1)
      this.setState({ selectedCapabilities: this.state.selectedCapabilities })
      this.filterCarrierServices()
    }
  }

  private containsAllSelectedCapabilities(
    selectedCapabilityKeys: string[],
    capabilities?: CarrierServiceCapability[]
  ): boolean {
    if (!capabilities || capabilities.length === 0) {
      return false
    }
    const capabilityKeys = capabilities.map(c => c.key)

    return selectedCapabilityKeys.every(key => capabilityKeys.indexOf(key) > -1)
  }
}

function isShipping(
  from: string,
  to: string,
  shippingMap?: CarrierServiceShippingRegion[]
): boolean {
  if (!shippingMap) {
    return from === WorldWide && to === WorldWide
  }

  return shippingMap.some(sr => {
    const fromCondition =
      from === WorldWide ||
      (sr.shipFrom !== undefined &&
        (sr.shipFrom.indexOf(from) > -1 || sr.shipFrom.indexOf(WorldWide) > -1))

    const toCondition =
      to === WorldWide ||
      (sr.shipTo !== undefined &&
        (sr.shipTo.indexOf(to) > -1 || sr.shipTo.indexOf(WorldWide) > -1))

    return fromCondition && toCondition
  })
}

function extendShippingDestinations(service: CarrierService): CarrierService {
  const worldWideShippingServices = [
    'recs:ups-standard',
    'recs:ups-expedited',
    'recs:ups-second-day',
    'recs:ups-next-day-air',
    'recs:ups-next-day-air-saver',
    'recs:fedex-international-economy',
    'recs:fedex-international-priority',
    'recs:fedex-2-3-day-n',
    'recs:usps-first-class-regular-parcel',
    'recs:usps-first-class-priority-parcel',
    'recs:usps-priority-mail-express',
    'recs:usps-first-class-envelope',
  ]

  if (worldWideShippingServices.indexOf(service.key) > -1) {
    return {
      ...service,
      shippingRegion: [
        {
          shipFrom: [WorldWide],
          shipTo: [WorldWide],
        },
      ],
    }
  }

  return service
}

function notUndefined<T>(value: T | undefined): value is T {
  return value !== undefined
}
