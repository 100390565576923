import { SelectWrapper } from '@cimpress/react-components'
import * as countries from 'i18n-iso-countries'
import * as React from 'react'
import Select from 'react-select'
import { ValidationStatus } from '../../common/helpers/validation'
import { getCountryName, getCurrentLang } from '../../i18n'
import { Option } from '../models'
import { CountryFlag } from './CountryFlag'
import { ValidatedSelect } from './ValidatedSelect'

export const countryOptions = Object.keys(
  countries.getNames(getCurrentLang())
).map(countryCode => ({
  label: (
    <>
      <CountryFlag countryCode={countryCode} />
      {getCountryName(countryCode)}
    </>
  ),
  value: countryCode,
  countryName: getCountryName(countryCode).toLowerCase(),
}))

export const filterCountryOption = (
  option: { data: OptionWithCountryName },
  search: string
) => {
  return (
    option.data.countryName.includes(search) ||
    option.data.countryName.split(' ').some(x => x.startsWith(search))
  )
}

interface Props {
  label?: string
  includeAll?: boolean
  name: string
  value: string
  required: boolean
  validationStatus: ValidationStatus
  selectReference?: React.RefObject<any>
  bsStyle?: '' | 'success' | 'warning' | 'error'
  onBlur?: () => void
  onChange(change: Option): void
}

interface OptionWithCountryName {
  label: any
  value: string
  countryName: string
}

export const WorldWide = 'WorldWide'

const allCountries = {
  label: <>All Countries</>,
  value: WorldWide,
  countryName: 'all countries',
}

const countryOptionsWithAll = [allCountries, ...countryOptions]

export default function CountryPicker(props: Props) {
  const options = props.includeAll ? countryOptionsWithAll : countryOptions

  const onChange = (data: Option) => {
    props.onChange({
      label: props.name,
      value: data ? data.value : '',
    })
  }

  const label = props.label ? props.label : 'Country'

  return (
    <ValidatedSelect validationStatus={props.validationStatus}>
      <SelectWrapper
        selectedSelect={Select}
        label={label}
        name={props.name}
        value={props.value}
        options={options}
        onChange={onChange}
        filterOption={filterCountryOption}
        required={props.required}
        onBlur={props.onBlur}
        ref={props.selectReference}
        resetValue={props.includeAll ? allCountries : undefined}
        clearable={props.includeAll && allCountries.value !== props.value}
        bsStyle={props.bsStyle || undefined}
      />
    </ValidatedSelect>
  )
}
