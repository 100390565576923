import { colors } from '@cimpress/react-components'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { guessLogo } from '../../../common/helpers/carrier-logos'

interface Props extends WithTranslation {
  carrierAccountKey: string
  carrierAccountName: string
  carrierServices: string[]

  onClickEdit: () => void
}

function CarrierAccountCard(props: Props) {
  const logoSrc = guessLogo(props.carrierAccountKey)

  const logo = (
    <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/carrier-logos/${logoSrc})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '40px',
        backgroundColor: colors.white,
        height: '42px',
        width: '42px',
        borderRadius: '50%',
        border: '1px solid var(--alloy)',
      }}
    />
  )
  if (!props.carrierAccountKey) {
    return (
      <Wrapper>
        <div className="col-sm-3">{logo}</div>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <div className="col-sm-3">{logo}</div>
      <div className="col-sm-9">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={{ marginTop: '8px' }}>{props.carrierAccountName}</h3>
          <i
            className="fa fa-pencil"
            style={{ color: 'var(--ocean-base)', cursor: 'pointer' }}
            onClick={props.onClickEdit}
          />
        </div>
        <div className="h7">{props.t('common.carrierServices')}</div>
        <small>{props.carrierServices.join(', ')}</small>
      </div>
    </Wrapper>
  )
}

function Wrapper(props: { children?: React.ReactNode }) {
  return (
    <div className="card" style={{ marginBottom: '32px' }}>
      <div className="card-block">
        <div className="row">{props.children}</div>
      </div>
    </div>
  )
}

export default withTranslation()(CarrierAccountCard)
