import { Tooltip } from '@cimpress/react-components'
import moment from 'moment-timezone'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import AnchorButton from '../../common/components/AnchorButton'
import * as models from '../../common/models'
import styles from './RegionUnits.module.css'
export default function RegionUnits(props: {
  localeSettings: models.LocaleSettings
}) {
  const { t } = useTranslation()

  return (
    <>
      <h5>
        {t('locations.region.regionAndUnits')}
        <Tooltip direction="right" contents={t('locations.region.tooltip')}>
          <AnchorButton style={{ padding: '0px 5px 0px 5px' }}>
            <i className="fa fa-fw fa-info-circle" />
          </AnchorButton>
        </Tooltip>
      </h5>
      <table className={`table ${styles['region-table']} table-striped`}>
        <tbody>
          <tr>
            <th>{t('locations.region.showTimeInTimezone')}</th>
            <td className="text-right">
              {`(GMT${moment
                .tz(props.localeSettings.timezone)
                .format('Z')}) ${props.localeSettings.timezone.replace(
                new RegExp('_', 'g'),
                ' '
              )} `}
            </td>
          </tr>
          <tr>
            <th>{t('locations.region.showDateAs')}</th>
            <td className="text-right">{props.localeSettings.dateFormat}</td>
          </tr>
          <tr>
            <th>{t('locations.currency')}</th>
            <td className="text-right">{props.localeSettings.currencyCode}</td>
          </tr>
          <tr>
            <th>{t('locations.region.weightUnits')}</th>
            <td className="text-right">{props.localeSettings.weightUnits}</td>
          </tr>
          <tr>
            <th>{t('locations.region.lengthUnits')}</th>
            <td className="text-right">{props.localeSettings.lengthUnits}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
