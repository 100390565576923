import * as React from 'react'
import { getEditable } from '../proxy/fulfillment-locations-store'
import { getAllLocations } from '../proxy/locations-store'
import * as models from '../../common/models'

export default function useFulfillmentLocations() {
  const [availableFLs, setAvailableFLs] = React.useState<
    models.FulfillmentLocation[]
  >()
  const [usedFLs, setUsedFLs] = React.useState<models.FulfillmentLocation[]>([])

  React.useEffect(() => {
    let isMounted = true
    const fetch = async () => {
      const [editableFLs, allLocations] = await Promise.all([
        getEditable(true),
        getAllLocations(),
      ])
      if (isMounted) {
        const allUsedFLs: models.FulfillmentLocation[] = []
        Array.from(allLocations.values()).forEach(location =>
          allUsedFLs.push(...location.fulfillmentLocations)
        )
        const usedFLIds = allUsedFLs.map(fl => fl.id)
        const allAvailableFLs = editableFLs.filter(
          fl => usedFLIds.indexOf(fl.id) === -1
        )
        setAvailableFLs(allAvailableFLs)
        setUsedFLs(allUsedFLs)
      }
    }

    fetch()

    return () => {
      isMounted = false
    }
  }, [])

  return { availableFLs, usedFLs }
}
