import * as queryString from 'querystring'
import { TextField } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { AdvancedUserContainer } from '../../../common/components/AdvancedUserContainer'
import '../../../common/components/App.css'
import GroupByDropdown from '../../../common/GroupByDropdown'
import { anyWholeWordStartsWithIgnoringCase } from '../../../common/helpers/filters'
import * as models from '../../../common/models'
import useLocationsContext from './LocationsContext'
import LocationsListGroupByCountries from './LocationsListGroupByCountries'
import LocationsListGroupByFulfiller from './LocationsListGroupByFulfiller'
import LocationsListGroupByNetworks from './LocationsListGroupByNetworks'
import './SelectLocationPage.css'

export default function SelectLocationPage() {
  const { t } = useTranslation()
  const context = useLocationsContext()
  const location = useLocation()
  const history = useHistory()

  const groupByOptions = [
    {
      label: t('locations.selectLocationPage.countries'),
      value: models.GroupBy.Countries,
    },
    {
      label: t('common.fulfillers.fulfiller', { count: 2 }),
      value: models.GroupBy.Fulfillers,
    },
  ]

  if (context.networks.length > 0) {
    groupByOptions.push({
      label: t('locations.selectLocationPage.networks'),
      value: models.GroupBy.Networks,
    })
  }

  const queryParameters = queryString.parse(location.search.substr(1))

  const searchQuery: string = (queryParameters.search as string) || ''
  const groupBy = parseGroupBy(queryParameters.groupBy)

  const searchChange = (event: React.FormEvent<HTMLInputElement>) => {
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({
        search: event.currentTarget.value,
        groupBy,
      }),
    })
  }

  const onChange = (change: models.Option) => {
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({
        search: searchQuery,
        groupBy: change.value as string,
      }),
    })
  }

  const filteredLocations: models.Location[] = context.locations.filter(
    advancedSearch(searchQuery)
  )

  const locationHeader = t('locations.location', {
    count: context.locations.length,
  })

  const header =
    filteredLocations.length === context.locations.length
      ? `${context.locations.length} ${locationHeader}`
      : t('locations.selectLocationPage.filteredLocationsOf', {
          filtered: filteredLocations.length,
          total: context.locations.length,
        })

  const searchBox = (
    <AdvancedUserContainer
      fallback={
        <TextField
          name="search"
          label={t('locations.selectLocationPage.advancedSearchLocationFor')}
          onChange={searchChange}
          value={searchQuery}
          style={{ width: '100%' }}
        />
      }
    >
      <div className="row vertical-align">
        <div className="col-sm-8">
          <TextField
            name="search"
            label={t('locations.selectLocationPage.advancedSearchLocationFor')}
            onChange={searchChange}
            value={searchQuery}
          />
        </div>
        <div className="col-sm-2">
          <AdvancedUserContainer enableTooltip={true}>
            <Link
              to="/migration-status"
              style={{ marginBottom: '15px', display: 'inline-block' }}
            >
              {t('locations.selectLocationPage.migrationStatus')}
            </Link>
          </AdvancedUserContainer>
        </div>
      </div>
    </AdvancedUserContainer>
  )

  return (
    <>
      <div className="row">
        <div className="col-sm-9 col-lg-10">{searchBox}</div>
        <div className="col-sm-3 col-lg-2 text-right">
          <Link to={'location/add'}>
            <button className="btn btn-primary btn-lg btn-input">
              {t('locations.addLocation')}
            </button>
          </Link>
        </div>
      </div>
      <div className="select-location-row">
        <span style={{ flexGrow: 7, marginRight: '20px' }}>
          <h4>{header}</h4>
        </span>
        <span style={{ flexGrow: 1, display: 'flex' }}>
          {groupByOptions && (
            <GroupByDropdown
              groupBy={groupBy}
              groupByOptions={groupByOptions}
              onChange={onChange}
            />
          )}
        </span>
      </div>
      <List
        groupBy={groupBy}
        locations={filteredLocations}
        searchQuery={searchQuery}
      />
    </>
  )
}

interface ListProps {
  groupBy: string
  locations: models.Location[]
  searchQuery: string
}

function List(props: ListProps) {
  switch (props.groupBy) {
    case models.GroupBy.Countries:
      return <LocationsListGroupByCountries {...props} />
    case models.GroupBy.Fulfillers:
      return <LocationsListGroupByFulfiller {...props} />
    case models.GroupBy.Networks:
      return <LocationsListGroupByNetworks {...props} />
    default:
      throw new Error(`Unexpected switch value: ${props.groupBy}`)
  }
}

function parseGroupBy(input: any): models.GroupBy {
  switch (input) {
    case models.GroupBy.Countries:
      return models.GroupBy.Countries
    case models.GroupBy.Fulfillers:
      return models.GroupBy.Fulfillers
    case models.GroupBy.Networks:
      return models.GroupBy.Networks
    default:
      return models.GroupBy.Countries
  }
}

function advancedSearch(
  searchQuery: string
): (location: models.Location) => boolean {
  return (l: models.Location) => {
    const trimmed = searchQuery.trim()

    return (
      anyWholeWordStartsWithIgnoringCase(l.name, trimmed) ||
      anyWholeWordStartsWithIgnoringCase(l.id, trimmed) ||
      l.fulfillmentLocations.some(
        fl =>
          anyWholeWordStartsWithIgnoringCase(fl.id, trimmed) ||
          anyWholeWordStartsWithIgnoringCase(fl.name, trimmed)
      )
    )
  }
}
