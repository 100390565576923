import * as React from 'react'
import { Modal, ModalProps, Button } from '@cimpress/react-components'
import isPromise from '../../common/helpers/is-promise'

interface ButtonConf {
  type?: string
  text: string
  savingText?: string
  disabled?: boolean
  className?: string
  handler: () => Promise<void> | void
}

interface Props extends ModalProps {
  footer: ButtonConf[]
  onRequestHide?: () => void
}

export default function AwaitingModal(props: Props) {
  const { footer, onRequestHide, closeOnOutsideClick, ...modalProps } = props
  const [saving, setSaving] = React.useState(false)

  const hide = () => {
    if (saving === false && onRequestHide) {
      onRequestHide()
    }
  }

  const wrap = (handler: () => Promise<void> | void) => async () => {
    const res = Promise.resolve(handler())
    if (isPromise(res)) {
      setSaving(true)
      await res
      setSaving(false)
    }
  }

  const renderedFooter = footer.map((btn, i) => {
    const type = btn.type || 'default'
    const savingText = btn.savingText || btn.text
    const disabled = btn.disabled === true ? btn.disabled : saving

    return (
      <Button
        type={type as any}
        onClick={wrap(btn.handler)}
        key={i}
        disabled={disabled}
        className={btn.className ?? ''}
      >
        {saving ? savingText : btn.text}
      </Button>
    )
  })

  return (
    <Modal
      {...modalProps}
      footer={renderedFooter}
      onRequestHide={hide}
      closeOnOutsideClick={closeOnOutsideClick ?? true}
    />
  )
}
