import { colors } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CarrierService } from '../../common/models-carriers'
import './CarrierServicesList.css'

interface Props {
  carrierServices: CarrierService[]
  selectedCarrierService?: CarrierService
}

export default function CarrierServicesList(props: Props) {
  const { t } = useTranslation()
  if (props.carrierServices.length === 0) {
    return (
      <div
        style={{
          padding: '16px 16px 8px 16px',
          backgroundColor: colors.silver,
        }}
      >
        <h5>{t('carrierExplorer.noCarrierServices')}</h5>
      </div>
    )
  }

  const isActive = (carrierService: CarrierService) => {
    return (
      props.selectedCarrierService &&
      carrierService.key === props.selectedCarrierService.key
    )
  }

  const carrierServiceRow = (carrierService: CarrierService) => {
    const link = isActive(carrierService)
      ? '/carrier-explorer'
      : `/carrier-explorer/${carrierService.key}`

    const activeClass = isActive(carrierService) ? 'active' : ''

    return (
      <Link
        to={link}
        className={`list-group-item btn btn-default carrier-service-row ${activeClass}
        )}`}
        style={{
          borderLeftStyle: 'none',
          borderRightStyle: 'none',
          borderColor: colors.platinum,
          textAlign: 'left',
        }}
        key={carrierService.key}
      >
        {carrierService.name}
      </Link>
    )
  }
  const carrierServiceRows = props.carrierServices.map(carrierServiceRow)

  const title = t('carrierExplorer.serviceAvailable', {
    count: props.carrierServices.length,
  })

  return (
    <>
      <div
        style={{
          padding: '16px 16px 8px 16px',
          backgroundColor: colors.silver,
        }}
      >
        <h5>{title}</h5>
      </div>
      <div className="list-group">{carrierServiceRows}</div>
    </>
  )
}
