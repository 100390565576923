import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import Preloader from '../../common/components/Preloader'
import Fragment from '../../common/components/Fragment'
import { config } from '../../common/config/config'
import DevelopmentFragment from '../../common/components/DevelopmentFragment'
import { useNetwork } from '../NetworkContext'

export default function NetworkPackingFragment() {
  const { t } = useTranslation()
  const { network } = useNetwork()

  const FragmentView =
    config.logisticsEnvironment === 'fragments' ? (
      <DevelopmentFragment />
    ) : (
      <Fragment
        src="https://ui.packer-config.logistics.cimpress.io/Fragment.js"
        fallback={<Preloader />}
      />
    )

  return (
    <>
      <BreadcrumbsItem to="/">{t('common.logisticsLocations')}</BreadcrumbsItem>
      <BreadcrumbsItem to={`/network/${network.apiNetwork.id}`}>
        {network.apiNetwork.name}
      </BreadcrumbsItem>
      <BreadcrumbsItem to="#">{t('packing.packing')}</BreadcrumbsItem>
      {FragmentView}
    </>
  )
}
