import { Button, Modal, TextField } from '@cimpress/react-components'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { ValidationContainer } from '../../../common/components/ValidationContainer'
import {
  ValidationResult,
  ValidationStatus,
} from '../../../common/helpers/validation'
import { validateCarrierAccountName } from '../../carrierAccountValidation'

export type SetNameHandler = (name: string) => Promise<void>

interface Props extends WithTranslation {
  initialName: string

  saveAccount: SetNameHandler
  onClose: () => void
  show: boolean
  existingCarrierAccountNames: string[]
}

interface State {
  loading: boolean
  name: string
}

class GetCarrierNameModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      name: props.initialName,
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.initialName !== this.props.initialName) {
      this.setState({ name: this.props.initialName })
    }
  }

  public render() {
    const onEdit = async () => {
      this.setLoading(true)
      await this.props.saveAccount(this.state.name)
      this.setLoading(false)
      this.props.onClose()
    }
    const disableButtons = this.state.loading || !this.isValid()

    const footer = (
      <>
        <Button
          type="default"
          onClick={this.props.onClose}
          key="cancel-btn"
          disabled={disableButtons}
        >
          {this.props.t('common.cancel')}
        </Button>
        <Button
          type="primary"
          onClick={onEdit}
          key="save-btn"
          disabled={disableButtons}
        >
          {this.state.loading
            ? this.props.t('common.saving')
            : this.props.t('common.save')}
        </Button>
      </>
    )

    const allowClosingModal = !this.state.loading

    return (
      <Modal
        show={this.props.show}
        onRequestHide={this.props.onClose}
        closeButton={allowClosingModal}
        closeOnOutsideClick={allowClosingModal}
        title={this.props.t(
          'carrierAccounts.addCarrierFlow.saveCarrierAccount'
        )}
        footer={footer}
        style={{ width: '400px' }}
      >
        <div style={{ marginBottom: '12px' }}>
          <div>
            <ValidationContainer validationResult={this.validateName()}>
              <TextField
                required={true}
                value={this.state.name}
                onChange={this.onChange}
                label={this.props.t(
                  'carrierAccounts.addCarrierFlow.carrierAccountNameLabel'
                )}
                bsStyle={
                  this.validateName()!.status === ValidationStatus.Invalid
                    ? 'error'
                    : undefined
                }
              />
            </ValidationContainer>
          </div>
        </div>
      </Modal>
    )
  }

  private validateName = (): ValidationResult => {
    return validateCarrierAccountName(
      this.state.name,
      this.props.existingCarrierAccountNames
    )
  }

  private onChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ name: e.currentTarget.value })
  }

  private setLoading = (loading: boolean) => {
    this.setState({ loading })
  }

  private isValid = () => {
    return (
      this.state.name && this.validateName().status === ValidationStatus.Valid
    )
  }
}

export default withTranslation()(GetCarrierNameModal)
