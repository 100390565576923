import * as coam from '@cimpress-technology/coam-sapidus'
import IconMessagesBubbleQuestion from '@cimpress-technology/react-streamline-icons/lib/IconMessagesBubbleQuestion'
import { Button, colors, Modal } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import OpenInNewTab from '../../common/components/OpenInNewTab'
import { SnackbarController } from '../../common/components/SnackbarController'
import FourOhFourPage from '../../common/FourOhFourPage'
import { CanonicalCoamGroupMember, CanonicalCoamUser, getName } from '../models'
import UserEditorDrawer from './UserEditorDrawer'
import UserList from './UserList'

interface Props {
  editable: boolean
  roles: string[]
  saveUser: (
    user: CanonicalCoamUser,
    role: string,
    action: 'add' | 'edit'
  ) => Promise<void>
  removeUser: (email: string) => Promise<void>
  coamGroup?: coam.models.DetailedCoamGroup
}

export default function PermissionsEditor(props: Props) {
  const { t } = useTranslation()

  const [addUser, setAddUser] = React.useState(false)
  const [userToDelete, setUserToDelete] = React.useState<CanonicalCoamUser>()
  const [userToEdit, setUserToEdit] = React.useState<CanonicalCoamGroupMember>()
  const [disable, setDisable] = React.useState(false)

  if (!props.coamGroup) {
    SnackbarController.show(t('userManagement.messages.noCoamGroup'), 'danger')

    return <FourOhFourPage />
  }

  const startAddingUser = () => {
    setAddUser(true)
  }

  const userEditorOnClose = () => {
    setAddUser(false)
    setUserToEdit(undefined)
  }

  const unsetUserToDelete = () => setUserToDelete(undefined)
  const deleteUser = async (user: CanonicalCoamUser) => {
    try {
      setDisable(true)
      await props.removeUser(user.principal)

      SnackbarController.show(
        t('userManagement.messages.userHasBeenRemoved', {
          username: getName(user),
        })!,
        'success'
      )
      setUserToDelete(undefined)
    } catch (e) {
      SnackbarController.show(
        t('userManagement.messages.failedToRemoveUser', {
          username: getName(user),
        })!,
        'danger'
      )
    }
    setDisable(false)
  }

  const saveUser = async (user: CanonicalCoamUser, role: string) => {
    setDisable(true)
    await props.saveUser(user, role, addUser ? 'add' : 'edit')
    setDisable(false)
  }

  return (
    <>
      <div className="row">
        <div className="col-xs-4 col-lg-6">
          <h4 style={{ display: 'inline-block', marginRight: '16px' }}>
            {t('userManagement.userManagement')}
          </h4>
        </div>
        <div className="col-xs-8 col-lg-6 text-right">
          <OpenInNewTab href="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/329254595/Managing+Logistics+Users">
            <IconMessagesBubbleQuestion
              color={colors.ocean.base}
              style={{ top: '.125em' }}
            />{' '}
            <span>{t('userManagement.learnMore')}</span>
          </OpenInNewTab>
          {props.editable ? (
            <Button
              type="primary"
              onClick={startAddingUser}
              style={{ marginLeft: '24px' }}
            >
              {t('userManagement.addUser')}
            </Button>
          ) : null}
        </div>
      </div>
      <div className="card" style={{ marginTop: '20px' }}>
        <UserList
          users={props.coamGroup.members as CanonicalCoamGroupMember[]}
          editable={props.editable}
          onDelete={setUserToDelete}
          onEdit={setUserToEdit}
        />
        <UserEditorDrawer
          show={addUser}
          existingMembers={
            props.coamGroup.members as CanonicalCoamGroupMember[]
          }
          onClose={userEditorOnClose}
          onSave={saveUser}
          blockUI={disable}
          roles={props.roles}
        />
        <UserEditorDrawer
          show={!!userToEdit}
          existingMembers={
            props.coamGroup.members as CanonicalCoamGroupMember[]
          }
          userToEdit={userToEdit}
          onClose={userEditorOnClose}
          onSave={saveUser}
          blockUI={disable}
          roles={props.roles}
        />

        {userToDelete ? (
          <RemoveUserConfirmationModal
            user={userToDelete}
            stopDeletingUser={unsetUserToDelete}
            deleteUser={deleteUser}
            blockUI={disable}
          />
        ) : null}
      </div>
    </>
  )
}

interface RemoveUserConfirmationModalProps {
  user: CanonicalCoamUser
  blockUI: boolean

  stopDeletingUser(): void

  deleteUser(user: CanonicalCoamUser): void
}

function RemoveUserConfirmationModal(props: RemoveUserConfirmationModalProps) {
  const { t } = useTranslation()
  const username = getName(props.user)
  const label = props.user.is_client
    ? t('userManagement.removeConfirmationClient', { username })
    : t('userManagement.removeConfirmationUser', { username })

  const onClick = () => {
    props.deleteUser(props.user)
  }

  return (
    <Modal
      show={true}
      onRequestHide={props.stopDeletingUser}
      closeButton={true}
      closeOnOutsideClick={false}
      title={t('userManagement.removeUser')}
      footer={
        <>
          {!props.blockUI ? (
            <Button type="default" onClick={props.stopDeletingUser}>
              Cancel
            </Button>
          ) : null}
          <Button type="primary" disabled={props.blockUI} onClick={onClick}>
            {!props.blockUI
              ? t('userManagement.removeUser')
              : t('userManagement.removingUser')}
          </Button>
        </>
      }
    >
      {label}
    </Modal>
  )
}
