import * as _ from 'underscore'
import auth from './auth'
import * as primitiveLogger from './primitiveLogger'

const onerror = window.onerror
// necessary for apply with arguments
// tslint:disable-next-line:only-arrow-functions
window.onerror = function (message, url, line, column) {
  primitiveLogger.log({
    user: _.pick(auth.getProfile(), 'email', 'sub'),
    level: 50,
    file: url,
    message: message as any,
    line,
    column,
  })

  if (onerror && typeof onerror === 'function') {
    // eslint-disable-next-line prefer-rest-params
    onerror.apply(window, arguments)
  }
}

export function logError(message: string, err?: any) {
  primitiveLogger.log({
    user: _.pick(auth.getProfile(), 'email', 'sub'),
    level: 50,
    error: err,
    message,
  })
}
