import { Button, Drawer, Radio, RadioGroup } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CanonicalCoamGroupMember, CanonicalCoamUser } from '../models'
import styles from './permissions.module.css'
import { RoleDescription } from './RoleDescription'
import { UserProfile } from './UserList'
import UserLookup from './UserLookup'

interface Props {
  roles: string[]
  show: boolean
  userToEdit?: CanonicalCoamGroupMember
  existingMembers: CanonicalCoamGroupMember[]
  blockUI: boolean
  onClose(): void
  onSave(user: CanonicalCoamUser, role: string): void
}

export default function UserEditorDrawer(props: Props) {
  const { t } = useTranslation()

  const [selectedUser, setSelectedUser] = React.useState<
    CanonicalCoamUser | undefined
  >(props.userToEdit)
  const [selectedRole, setSelectedRole] = React.useState('')

  React.useEffect(() => {
    setSelectedUser(props.userToEdit)
  }, [props.userToEdit])

  const onUserSelect = (user?: CanonicalCoamUser) => {
    setSelectedUser(user)
    setSelectedRole('')
  }

  const drawerContents = (
    <>
      <div style={{ marginBottom: '32px' }}>
        <UserSelector
          selectedUser={selectedUser}
          deselectable={!props.userToEdit}
          existingMembers={props.existingMembers}
          onSelect={onUserSelect}
        />
      </div>
      {selectedUser ? (
        <RoleSelector
          key={'role_selector_' + selectedUser.principal}
          defaultValue={selectedRole}
          onChange={setSelectedRole}
          roles={props.roles}
        />
      ) : null}
    </>
  )

  const getClientLabel = () => {
    return props.userToEdit
      ? props.blockUI
        ? t('userManagement.editingClient')
        : t('userManagement.editClient')
      : props.blockUI
      ? t('userManagement.addingClient')
      : t('userManagement.addClient')
  }

  const getUserLabel = () => {
    return props.userToEdit
      ? props.blockUI
        ? t('userManagement.editingUser')
        : t('userManagement.editUser')
      : props.blockUI
      ? t('userManagement.addingUser')
      : t('userManagement.addUser')
  }

  const getSaveButton = () => {
    const canAdd = selectedUser && selectedRole
    const onSave = () => props.onSave(selectedUser!, selectedRole)
    const clientSelected = selectedUser && selectedUser.is_client
    const saveButtonLabel = clientSelected ? getClientLabel() : getUserLabel()

    return (
      <Button
        type="primary"
        disabled={!canAdd || props.blockUI}
        onClick={onSave}
      >
        {saveButtonLabel}
      </Button>
    )
  }

  const getFooter = () => {
    return (
      <>
        {!props.blockUI ? (
          <Button type="default" onClick={props.onClose}>
            Cancel
          </Button>
        ) : null}
        {getSaveButton()}
      </>
    )
  }

  return (
    <Drawer
      show={props.show}
      header={
        props.userToEdit
          ? t('userManagement.editUser')
          : t('userManagement.addUser')
      }
      footer={getFooter()}
      onRequestHide={props.onClose}
      className={styles['user-editor-drawer']}
    >
      {props.show && drawerContents}
    </Drawer>
  )
}

function UserSelector(props: {
  selectedUser?: CanonicalCoamUser
  deselectable: boolean
  existingMembers: CanonicalCoamGroupMember[]
  onSelect: (user?: CanonicalCoamUser) => void
}) {
  if (props.selectedUser) {
    const clearSelection = () => {
      props.onSelect()
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <UserProfile user={props.selectedUser} />
        <span style={{ marginLeft: '16px' }}>
          {props.deselectable ? (
            <Button type="link" onClick={clearSelection}>
              Change
            </Button>
          ) : null}
        </span>
      </div>
    )
  } else {
    return (
      <UserLookup
        onSelect={props.onSelect}
        existingMembers={props.existingMembers}
      />
    )
  }
}

function RoleSelector(props: {
  defaultValue: string
  roles: string[]
  onChange(value: string): void
}) {
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => props.onChange(value)

  return (
    <>
      <h5>Select role</h5>
      <RadioGroup
        name="roles"
        defaultSelected={props.defaultValue}
        onChange={onChange}
      >
        {props.roles.map(role => (
          <Radio
            key={role}
            label={
              <>
                <strong>{role}</strong>
                <div>
                  <RoleDescription role={role} />
                </div>
              </>
            }
            className={styles.roleCheckbox}
            value={role}
          />
        ))}
      </RadioGroup>
    </>
  )
}
