import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import { colors, Select, shapes, TextField } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ValidationContainer } from '../../../common/components/ValidationContainer'
import { get, set } from '../../../common/helpers/deepBracketNotation'
import {
  toBSStyle,
  validateField,
  ValidationResult,
  ValidationStatus,
} from '../../../common/helpers/validation'
import useCallbackOnValueChange from '../../../common/hooks/useCallbackOnValueChange'
import { Option } from '../../../common/models'
import { CommonProps, getUploadLabel } from './uploadEditorFactory'
import {
  emailUploadValidatedFields,
  validateAllFields,
} from './uploadEditorValidation'

interface Props extends CommonProps {
  upload: carrierAccounts.models.EmailUpload
}

export default function EmailUploadEditor(props: Props) {
  const [upload, setUpload] = React.useState(props.upload)
  const { t } = useTranslation()
  const [receivers, setReceivers] = React.useState({
    to: props.upload.receivers.to.map(email => email.address).join(', '),
    cc: props.upload.receivers.cc
      ? props.upload.receivers.cc.map(email => email.address).join(', ')
      : '',
    bcc: props.upload.receivers.bcc
      ? props.upload.receivers.bcc.map(email => email.address).join(', ')
      : '',
  })

  const [validatedFields, setValidatedFields] = React.useState(
    new Set<string>()
  )

  useCallbackOnValueChange(() => {
    setValidatedFields(
      new Set([...emailUploadValidatedFields.map(field => field.name), 'to'])
    )
    props.onChange(upload, isUploadValid())
  }, props.validateAll)

  const doValidateField = (fieldName: string): ValidationResult => {
    if (!validatedFields.has(fieldName)) {
      return {
        fieldName,
        status: ValidationStatus.NotValidated,
        message: '',
      }
    }
    const validationField = emailUploadValidatedFields.find(
      field => field.name === fieldName
    )

    return validateField(validationField!, get(upload, fieldName))
  }

  const name = getUploadLabel(props.upload.uploadType)
  const isUploadValid = (): boolean => {
    const validationResults = validateAllFields(
      emailUploadValidatedFields,
      upload
    )

    const documentTypesValid = upload.documentTypes.length > 0

    const receiversAreValid =
      upload.receivers.to.map(receiver => receiver.address).filter(Boolean)
        .length > 0

    return (
      documentTypesValid &&
      receiversAreValid &&
      validationResults.filter(vr => vr.status === ValidationStatus.Invalid)
        .length === 0
    )
  }

  const propertyChange = (path: string) => (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    set(upload, path, event.currentTarget.value)
    setUpload({ ...upload })
    setValidatedFields(new Set([...Array.from(validatedFields), path]))
    props.onChange(upload, isUploadValid())
  }

  const onDocumentTypeChange = (changeData: Option[]) => {
    set(
      upload,
      'documentTypes',
      changeData.map(cd => cd.value)
    )
    setUpload({ ...upload })
    props.onChange(upload, isUploadValid())
  }

  const receiverChange = (path: string) => (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const emails = event.currentTarget.value.split(',')
    set(
      upload.receivers,
      path,
      emails
        .filter(email => email.trim() !== '')
        .map(email => ({ name: '', address: email.trim() }))
    )
    set(receivers, path, event.currentTarget.value)
    setReceivers({ ...receivers })
    setUpload({ ...upload })
    setValidatedFields(new Set([...Array.from(validatedFields), path]))
    props.onChange(upload, isUploadValid())
  }

  const validateReceivers = (): ValidationResult => {
    const result = {
      fieldName: 'to',
      status: ValidationStatus.NotValidated,
      message: '',
    }

    if (!validatedFields.has('to')) {
      return result
    }
    const receiversAreValid =
      upload.receivers.to.map(receiver => receiver.address).filter(Boolean)
        .length > 0

    return receiversAreValid
      ? { ...result, status: ValidationStatus.Valid }
      : {
          ...result,
          status: ValidationStatus.Invalid,
          message: 'At least one recipient is required',
        }
  }

  return (
    <>
      <h5>
        {t('carrierAccounts.uploads.uploadTitle', { name })}{' '}
        {props.onRemove && (
          <button
            onClick={props.onRemove}
            className="btn"
            name="remove-upload"
            style={{ padding: 0 }}
          >
            <shapes.hamburger.Close
              width="18px"
              color={colors.ocean.base}
              cropped={true}
            />
          </button>
        )}
      </h5>
      <Select
        label={t('carrierAccounts.uploads.selectDocumentTypes')}
        value={props.documentTypeList.filter(o =>
          upload.documentTypes.includes(o.value)
        )}
        options={props.documentTypeList}
        required={true}
        onChange={onDocumentTypeChange}
        isMulti={true}
      />
      <ValidationContainer validationResult={doValidateField('subject')}>
        <TextField
          value={upload.subject}
          label={t('carrierAccounts.uploads.emailSubject')}
          onChange={propertyChange('subject')}
          required={true}
          bsStyle={toBSStyle(doValidateField('subject'))}
        />
      </ValidationContainer>

      <ValidationContainer validationResult={doValidateField('template')}>
        <TextField
          value={upload.template}
          label={t('carrierAccounts.uploads.emailTemplate')}
          onChange={propertyChange('template')}
          required={true}
          bsStyle={toBSStyle(doValidateField('template'))}
        />
      </ValidationContainer>

      <ValidationContainer validationResult={validateReceivers()}>
        <TextField
          value={receivers.to}
          label={t('carrierAccounts.uploads.emailRecipientTo')}
          onChange={receiverChange('to')}
          required={true}
          type="email"
          bsStyle={toBSStyle(validateReceivers())}
        />
      </ValidationContainer>

      <TextField
        value={receivers.cc}
        label={t('carrierAccounts.uploads.emailRecipientCc')}
        onChange={receiverChange('cc')}
        required={false}
        type="email"
      />
      <TextField
        value={receivers.bcc}
        label={t('carrierAccounts.uploads.emailRecipientBcc')}
        onChange={receiverChange('bcc')}
        required={false}
        type="email"
      />
    </>
  )
}
