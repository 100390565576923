import * as React from 'react'

export interface Context {
  isAdvancedUser: boolean
}

export const Context = React.createContext<Context>({} as Context)

export const Provider = Context.Provider
export const Consumer = Context.Consumer

export function useUserContext() {
  return React.useContext(Context)
}
