import { Checkbox } from '@cimpress/react-components'
import * as React from 'react'
import { ConfigSpec, SpecEvent } from '../../../common/models-carriers'

interface Props {
  spec: ConfigSpec

  value: boolean
  onChange: (specEvent: SpecEvent) => void
}
export default function BooleanSpecComponent(props: Props) {
  const handleChange = () => {
    props.onChange({ path: props.spec.key, value: !props.value })
  }

  return (
    <>
      <Checkbox
        checked={props.value}
        label={props.spec.name}
        onChange={handleChange}
      />
      <small className="help-block">{props.spec.description}</small>
    </>
  )
}
