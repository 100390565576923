import * as React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { isWindows } from '../helpers/os-detection'

interface Props {
  countryCode: string
}

export function CountryFlag(props: Props) {
  let code = props.countryCode.toLowerCase()
  if (code === 'uk') {
    code = 'gb'
  }

  return <ReactCountryFlag countryCode={code} svg={isWindows()} />
}
