import React from 'react'
import { useTranslation } from 'react-i18next'
import { SequenceRange } from '../../../common/models'
import { SequenceDefinitionPair } from '../../../common/models-carriers'
import styles from './SequenceView.module.css'

interface Props {
  sequenceDefinitionPair: SequenceDefinitionPair
}

export function SequenceView(props: Props) {
  const { t } = useTranslation()

  const sequenceDefinition = props.sequenceDefinitionPair.sequenceDefinition

  const name =
    props.sequenceDefinitionPair.sequence.name ||
    props.sequenceDefinitionPair.sequence.description ||
    props.sequenceDefinitionPair.sequence.key

  const prefixSuffix =
    sequenceDefinition.activeRange.prefix ||
    sequenceDefinition.activeRange.suffix ? (
      <div className={styles.sequence}>
        <div className={`col-md-6 ${styles.sequenceView}`}>
          <div>{t('carrierAccounts.sequences.prefix')}</div>{' '}
          <div>{sequenceDefinition.activeRange.prefix}</div>
        </div>
        <div className={`col-md-6 ${styles.sequenceView}`}>
          <div>{t('carrierAccounts.sequences.suffix')}</div>{' '}
          <div>{sequenceDefinition.activeRange.suffix}</div>
        </div>
      </div>
    ) : undefined

  const replacementRanges =
    sequenceDefinition.replacementRanges &&
    sequenceDefinition.replacementRanges.length > 0 ? (
      <>
        <div className="row">
          <div className={`col-md-12 ${styles.subHeader}`}>
            <strong>{t('carrierAccounts.sequences.replacementRanges')}</strong>
          </div>
        </div>
        {sequenceDefinition.replacementRanges.map(range => (
          <RangeView key={`${range.start}-${range.end}`} range={range} />
        ))}
      </>
    ) : undefined

  return (
    <div>
      <h6>{name}</h6>
      <div className={styles.sequence}>
        <div className={`col-md-4 ${styles.sequenceView}`}>
          <div>{t('carrierAccounts.sequences.start')}</div>
          <div>{sequenceDefinition.activeRange.start}</div>
        </div>
        <div className={`col-md-4 ${styles.sequenceView}`}>
          <div>{t('carrierAccounts.sequences.current')}</div>
          <div>{sequenceDefinition.current}</div>
        </div>
        <div className={`col-md-4 ${styles.sequenceView}`}>
          <div>{t('carrierAccounts.sequences.end')}</div>{' '}
          <div>{sequenceDefinition.activeRange.end}</div>
        </div>
      </div>
      {prefixSuffix}
      <div className={styles.sequence}>
        <div className={`col-md-12 ${styles.sequenceView}`}>
          <div>{t('carrierAccounts.sequences.strategy')}</div>
          <div>
            {
              [
                {
                  value: 'retireSequence',
                  label: t('carrierAccounts.sequences.retire'),
                },
                {
                  value: 'reuseSequence',
                  label: t('carrierAccounts.sequences.reuse'),
                },
              ].find(s => s.value === sequenceDefinition.replacementStrategy)!
                .label
            }
          </div>
        </div>
      </div>
      {replacementRanges}
    </div>
  )
}

interface RangeProps {
  range: SequenceRange
}

function RangeView(props: RangeProps) {
  const { t } = useTranslation()

  const range = props.range

  return (
    <div className={styles.sequence}>
      <div className={`col-md-3 ${styles.sequenceView}`}>
        <div>{t('carrierAccounts.sequences.start')}</div>{' '}
        <div>{range.start}</div>
      </div>
      <div className={`col-md-3 ${styles.sequenceView}`}>
        <div>{t('carrierAccounts.sequences.end')}</div> <div>{range.end}</div>
      </div>
      <div className={`col-md-3 ${styles.sequenceView}`}>
        <div>{t('carrierAccounts.sequences.prefix')}</div>{' '}
        <div>{range.prefix}</div>
      </div>
      <div className={`col-md-3 ${styles.sequenceView}`}>
        <div>{t('carrierAccounts.sequences.suffix')}</div>{' '}
        <div>{range.suffix}</div>
      </div>
    </div>
  )
}
