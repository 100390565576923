import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import { Button, Select } from '@cimpress/react-components'
import { TFunction } from 'i18next'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Option, Uploads } from '../../../common/models'
import { getUploadEditor, getUploadsSelectOptions } from './uploadEditorFactory'
import { getUploadView } from './uploadViewFactory'

interface Props {
  caasProfileKey: string
  edit: boolean
  uploads: Uploads[]
  onUploadChange?: (value: Uploads, index: number, isValid: boolean) => void
  onRemoveUpload?: (index: number) => void
  documentTypeKeys: Option[]

  selectedUploadOption?: carrierAccounts.models.UploadType

  changeSelectedUpload?: (option: Option) => void
  addUpload?: () => void
  validateAll?: boolean
}
export default function DocumentUpload(props: Props) {
  const { t } = useTranslation()

  return <>{props.edit ? getEdit(props, t) : getView(props, t)}</>
}

function getEdit(props: Props, t: TFunction) {
  const options = getUploadsSelectOptions(props.caasProfileKey)
  const addUpload = (
    <div className="row">
      <div className="col-md-10">
        <Select
          label={t('carrierAccounts.uploads.selectUploadType')}
          value={options.filter(o => o.value === props.selectedUploadOption)}
          options={options}
          onChange={props.changeSelectedUpload}
          isClearable={false}
        />
      </div>
      <div className="col-md-2">
        <Button type="primary" size="lg" onClick={props.addUpload}>
          {t('carrierAccounts.uploads.add')}
        </Button>
      </div>
    </div>
  )

  const components = props.uploads
    .filter(upload => !upload.isHidden)
    .map((upload, index) => {
      const onRemove = props.onRemoveUpload

      return getUploadEditor(
        upload,
        props.documentTypeKeys!,
        (u: Uploads, isValid: boolean) =>
          props.onUploadChange!(u, index, isValid),
        onRemove ? () => onRemove(index) : undefined,
        props.validateAll
      )
    })

  return (
    <>
      <h4>{t('carrierAccounts.uploads.add')}</h4>
      {props.addUpload ? addUpload : undefined}
      {components}
    </>
  )
}

function getView(props: Props, t: TFunction) {
  if (props.uploads.length === 0) {
    return <>{t('carrierAccounts.uploads.noDocumentUploads')}</>
  }

  if (props.uploads.every(upload => upload.isHidden === true)) {
    const methods = props.uploads
      .map(upload => upload.uploadType.toUpperCase())
      .join(', ')

    return <>{t('carrierAccounts.uploads.allUploadsConfigured', { methods })}</>
  }

  const components = props.uploads
    .filter(upload => !upload.isHidden)
    .map(u => getUploadView(u, props.documentTypeKeys))

  return <>{components}</>
}
