import * as React from 'react'
import { useTranslation } from 'react-i18next'

export function RoleDescription(props: { role: string }) {
  const { t } = useTranslation()
  switch (props.role) {
    case 'Logistics Manager':
      return (
        <>
          <p>
            {t(
              'userManagement.roleDescription.logisticsManager.additionalDescription'
            )}
          </p>
          <p>
            {t(
              'userManagement.roleDescription.logisticsManager.inheritedDescription'
            )}
          </p>
        </>
      )
    case 'Logistics Supervisor':
      return (
        <>
          <p>
            {t(
              'userManagement.roleDescription.logisticsSupervisor.additionalDescription'
            )}
          </p>
          <p>
            {t(
              'userManagement.roleDescription.logisticsSupervisor.inheritedDescription'
            )}
          </p>
        </>
      )
    case 'Logistics Operator':
      return (
        <p>
          {t('userManagement.roleDescription.logisticsOperator.description')}
        </p>
      )
    case 'Logistics Network Admin':
      return (
        <>
          <p>
            {t(
              'userManagement.roleDescription.logisticsNetworkAdmin.description'
            )}
          </p>
        </>
      )
    case 'Logistics Network Reader':
      return (
        <>
          <p>
            {t(
              'userManagement.roleDescription.logisticsNetworkReader.description'
            )}
          </p>
        </>
      )
    case 'Logistics Network Operator':
      return (
        <>
          <p>
            {t(
              'userManagement.roleDescription.logisticsNetworkOperator.description'
            )}
          </p>
        </>
      )
  }

  return <></>
}
