import React from 'react'

interface Props {
  header: string
  children?: React.ReactNode
  footer: React.ReactNode
}
export default function StepContainer(props: Props) {
  return (
    <div className="card" style={{ marginBottom: '32px' }}>
      <div className="card-block">
        <h3 style={{ marginBottom: '32px' }}>{props.header}</h3>
        {props.children}
        <div className="padding-top-32">{props.footer}</div>
      </div>
    </div>
  )
}
