import React from 'react'
import { generateEmbedUrl } from '../proxy/cimba-store'
import { useIsMounted } from '../hooks/useIsMounted'
import { useLogisticsLocation } from '../../locations/LocationContext'
import Preloader from './Preloader'

interface Props {
  configurationId: string
}

export default function EmbeddedLooker(props: Props) {
  const { logisticsLocation } = useLogisticsLocation()
  const isMounted = useIsMounted()
  const [loading, setLoading] = React.useState(false)
  const [url, setUrl] = React.useState<string>()

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const u = await generateEmbedUrl(
        props.configurationId,
        logisticsLocation.id
      )
      if (isMounted.current) {
        setUrl(u)
        setLoading(false)
      }
    }

    fetchData()
  }, [isMounted, logisticsLocation.id, props.configurationId])

  if (loading) {
    return <Preloader />
  }

  if (!url) {
    return <div />
  }

  return (
    <iframe
      id="embedded-looker"
      title="embedded-looker"
      src={url}
      style={{
        width: '1px',
        minWidth: '100%',
        minHeight: '1050px',
        height: '100vh',
      }}
      frameBorder="0"
    />
  )
}
