import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin'
import IconMessagesBubbleInformation from '@cimpress-technology/react-streamline-icons/lib/IconMessagesBubbleInformation'
import { colors } from '@cimpress/react-components'
import * as React from 'react'
import AnchorButton from '../../common/components/AnchorButton'
import { ClickableTableRow } from '../../common/components/ClickableTableRow'
import { IncompleteCarrierAccount } from '../../common/proxy/backend-proxy'
import { useLogisticsLocation } from '../../locations/LocationContext'
import style from './CarrierAccountsList.module.css'

interface Props {
  incompleteCarrierAccount: IncompleteCarrierAccount
  carrierLogo?: string
  onDelete(incompleteCarrierAccount: IncompleteCarrierAccount): void
}

export function IncompleteCarrierAccountRow(props: Props): JSX.Element {
  const { logisticsLocation } = useLogisticsLocation()

  const logo = props.carrierLogo ? (
    <div
      style={{
        background: `url(${process.env.PUBLIC_URL}/images/carrier-logos/${props.carrierLogo}) center center no-repeat`,
        backgroundSize: '56px',
        backgroundColor: colors.white,
      }}
    />
  ) : (
    <div />
  )

  const onDelete = (e: any) => {
    props.onDelete(props.incompleteCarrierAccount)
    e.preventDefault()
    e.stopPropagation()
  }

  const href = `/location/${logisticsLocation.id}/incomplete-carrier-accounts/${props.incompleteCarrierAccount.id}`

  return (
    <ClickableTableRow href={href}>
      <td className={style.carrierLogo}>{logo}</td>
      <td className={style.carrierName}>
        <a href={href}>
          {props.incompleteCarrierAccount.createCarrierAccount.name}
        </a>
      </td>
      <td className={style.carrierServiceCount}>
        <div style={{ display: 'inline-block' }}>
          <IconMessagesBubbleInformation
            weight="fill"
            size="lg"
            color={colors.info.base}
            style={{ marginBottom: '-1px' }}
          />
        </div>
        <span className={style.incompleteCarrierWarning}>
          {' '}
          Configuration for this carrier is not complete.
        </span>
      </td>
      <td className={style.deleteButtonContainer}>
        {logisticsLocation.editable ? (
          <AnchorButton onClick={onDelete} className={style.deleteButton}>
            <IconBin size="2x" />
          </AnchorButton>
        ) : null}
      </td>
    </ClickableTableRow>
  )
}
