import { get } from '../common/helpers/deepBracketNotation'
import {
  FieldType,
  ValidatedField,
  validateField,
  ValidationResult,
} from '../common/helpers/validation'
import { CreateReturnAddress } from '../common/proxy/networks-store'

export const returnAddressModalValidatedFields: ValidatedField[] = [
  {
    i18nKey: 'validation.name',
    displayName: 'Name',
    name: 'address.name',
    required: true,
    type: FieldType.Text,
  },
  {
    i18nKey: 'validation.street',
    displayName: 'Street 1',
    name: 'address.street',
    required: true,
    type: FieldType.Text,
  },
  {
    i18nKey: 'validation.city',
    displayName: 'City',
    name: 'address.city',
    required: true,
    type: FieldType.Text,
  },
  {
    i18nKey: 'validation.postalCode',
    displayName: 'Postal code',
    name: 'address.postalCode',
    required: true,
    type: FieldType.Text,
  },
  {
    i18nKey: 'validation.country',
    displayName: 'Country',
    name: 'address.country',
    required: true,
    type: FieldType.Text,
  },
]

export function validateAllFields(
  fields: ValidatedField[],
  returnAddress: CreateReturnAddress
): ValidationResult[] {
  return fields.map(field =>
    validateField(field, get(returnAddress, field.name))
  )
}
