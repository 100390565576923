import { Step, StepGroup, Stepper } from '@cimpress/react-components'
import * as React from 'react'
import { getStepMap, StepDefinition, Steps } from './steps'

export interface Props {
  stepChanged: (step: Steps) => void
  current: Steps
  maxStepReached: number
  setMaxStepReached: (step: number) => void
  steps: StepDefinition[]
}

function getStep(
  step: StepDefinition,
  onClickStep: (stepName: Steps) => (() => void) | undefined,
  completed: (step: Steps) => boolean
): JSX.Element {
  if (step.type === 'group') {
    const firstStep = step.steps[0]

    return (
      <StepGroup
        key={step.key}
        contents={step.contents}
        onClick={onClickStep(firstStep.key)}
        completed={completed(firstStep.key)}
      >
        {step.steps.map(substep => getStep(substep, onClickStep, completed))}
      </StepGroup>
    )
  }

  return (
    <Step
      key={step.key}
      onClick={onClickStep(step.key)}
      completed={completed(step.key)}
    >
      {step.contents}
    </Step>
  )
}

export default function CarrierAccountStepper(props: Props) {
  const stepMap = getStepMap(props.steps)
  const currentStep = stepMap.get(props.current)!
  const currentIndex = currentStep.currentIndex
  const activeStep = currentStep.stepperIndex

  const { setMaxStepReached, maxStepReached } = props
  React.useEffect(() => {
    setMaxStepReached(Math.max(maxStepReached, currentIndex))
  }, [activeStep, currentIndex, maxStepReached, setMaxStepReached])

  const doOnClickStep = (stepKey: Steps, index: number) => () => {
    props.setMaxStepReached(Math.max(props.maxStepReached, index))
    props.stepChanged(stepKey)
  }

  const onClickStep = (stepKey: Steps) => {
    const clickedStep = stepMap.get(stepKey)!

    if (props.maxStepReached >= clickedStep.currentIndex) {
      return doOnClickStep(stepKey, clickedStep.currentIndex)
    }

    return undefined
  }

  const completed = (stepKey: Steps) => {
    const step = stepMap.get(stepKey)!.currentIndex

    return props.maxStepReached >= step && currentIndex !== step
  }

  return (
    <Stepper activeStep={activeStep} vertical={true}>
      {props.steps.map(step => getStep(step, onClickStep, completed))}
    </Stepper>
  )
}
