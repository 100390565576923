import { calendars } from '@cimpress-technology/logistics-configuration-client'
import { ButtonGroup } from '@cimpress/react-components'
import * as React from 'react'
import { EditorPickupSchedule } from '../../../../models'
import { mapScheduleToEditor, weekdays } from './schedule-helpers'

interface Props {
  schedules?: calendars.models.PickupWeeklySchedule[]
}

export function PickupScheduleView(props: Props) {
  if (!props.schedules || props.schedules.length === 0) {
    return <></>
  }
  const lastSchedule = mapScheduleToEditor(
    props.schedules[props.schedules.length - 1]
  )

  return (
    <div className="pickup-schedule-editor">
      {lastSchedule.map(s => (
        <Row key={s.time} schedule={s} />
      ))}
    </div>
  )
}

function Row(props: { schedule: EditorPickupSchedule }) {
  return (
    <div className="pickup-editor-row">
      <span style={{ marginTop: '11px', marginRight: '8px' }}>
        {props.schedule.time}
      </span>
      <ButtonGroup type="justified">
        {weekdays.map(weekday => {
          return (
            <PickupScheduleDayButton
              key={weekday}
              weekday={weekday}
              schedule={props.schedule.weeklySchedule}
            />
          )
        })}
      </ButtonGroup>
    </div>
  )
}

interface PickupScheduleDayButtonProps {
  weekday: string
  schedule: calendars.models.WeeklySchedule<boolean>
}

function PickupScheduleDayButton(props: PickupScheduleDayButtonProps) {
  return (
    <button
      name={props.weekday}
      className={
        'btn day-button ' +
        (props.schedule.schedule[props.weekday] ? 'enabled' : '')
      }
      style={{ cursor: 'auto', width: '35px', padding: '0', height: '40px' }}
    >
      {props.weekday.charAt(0).toUpperCase() + props.weekday.charAt(1)}
    </button>
  )
}
