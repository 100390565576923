import IconNavigationMenuVertical from '@cimpress-technology/react-streamline-icons/lib/IconNavigationMenuVertical'
import { colors, Tooltip } from '@cimpress/react-components'
import * as React from 'react'

interface Props {
  className?: string
  children: React.ReactNode
}

interface Context {
  clicked: () => void
}

export const Context = React.createContext<Context>({} as Context)

export default function ContextMenu(props: Props) {
  const [showMenu, setShowMenu] = React.useState(false)
  const onClickEllipsis = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    setShowMenu(!showMenu)
  }
  const onCloseMenu = () => {
    setShowMenu(false)
  }

  return (
    <Tooltip
      direction="bottom"
      variety="popover"
      contents={
        <Context.Provider value={{ clicked: () => setShowMenu(false) }}>
          {props.children}
        </Context.Provider>
      }
      show={showMenu}
      className="pickup-calendar-legend-popover"
      onClickOutside={onCloseMenu}
      tooltipStyle={{ minWidth: '100px', padding: '0' }}
    >
      <div style={{ cursor: 'pointer' }} className={props.className}>
        <IconNavigationMenuVertical
          color={colors.ocean.base}
          onClick={onClickEllipsis}
        />
      </div>
    </Tooltip>
  )
}
