import {
  Button,
  Checkbox,
  Modal,
  Select,
  TextField,
} from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import CountryPicker from '../common/components/CountryPicker'
import { ValidationContainer } from '../common/components/ValidationContainer'
import { clone } from '../common/helpers/clone'
import { get, set } from '../common/helpers/deepBracketNotation'
import {
  toBSStyle,
  validateField,
  ValidationResult,
  ValidationStatus,
} from '../common/helpers/validation'
import useToggle from '../common/hooks/useToggle'
import { Option } from '../common/models'
import {
  CreateReturnAddress,
  ReturnAddress,
} from '../common/proxy/networks-store'
import { returnAddressModalValidatedFields } from './returnAddressModalValidation'

const allRequiredFields = new Set(
  returnAddressModalValidatedFields.map(field => field.name)
)

export type Modals = 'add' | 'edit' | 'delete' | undefined

interface Props {
  returnAddress?: ReturnAddress
  modal: Modals
  carriers?: Option[]
  onClickClose: () => void
  onDeleteAddress: () => void
  onSaveAddress: (returnAddress: CreateReturnAddress) => void
}

function createEmptyReturnAddress(): CreateReturnAddress {
  return {
    address: {
      name: '',
      street: '',
      street2: '',
      city: '',
      postalCode: '',
      country: '',
    },
  }
}

export default function ReturnAddressModal(props: Props) {
  const { t } = useTranslation()

  const [returnAddress, setReturnAddress] = React.useState<CreateReturnAddress>(
    props.returnAddress
      ? clone(props.returnAddress)
      : createEmptyReturnAddress()
  )
  const [applyToWebsite, toggleApplyToWebsite] = useToggle(
    !!returnAddress.appliesToWebsite
  )

  const [validatedFields, setValidatedFields] = React.useState(
    new Set<string>()
  )

  const doValidateField = (fieldName: string): ValidationResult => {
    if (!validatedFields.has(fieldName)) {
      return {
        fieldName,
        status: ValidationStatus.NotValidated,
        message: '',
      }
    }
    const validationField = returnAddressModalValidatedFields.find(
      field => field.name === fieldName
    )

    return validateField(validationField!, get(returnAddress, fieldName))
  }

  const isReturnAddressValid = (fields: Set<string>): boolean => {
    const validationResults = returnAddressModalValidatedFields
      .filter(field => fields.has(field.name))
      .map(field => validateField(field, get(returnAddress, field.name)))

    return (
      validationResults.filter(vr => vr.status === ValidationStatus.Invalid)
        .length === 0
    )
  }

  const saveReturnAddress = () => {
    if (!isReturnAddressValid(allRequiredFields)) {
      setValidatedFields(allRequiredFields)

      return
    }

    if (!applyToWebsite) {
      returnAddress.appliesToWebsite = undefined
    }
    props.onSaveAddress(returnAddress)
  }

  const propertyChange = (path: string) => (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value
    set(returnAddress, path, value)
    setReturnAddress({ ...returnAddress })
    setValidatedFields(new Set(Array.from(validatedFields).concat(path)))
  }

  const onSelectChange = (path: string) => (event: Option) => {
    set(returnAddress, path, event.value)
    setReturnAddress({ ...returnAddress })
    setValidatedFields(new Set(Array.from(validatedFields).concat(path)))
  }

  const footer = (
    <>
      <Button onClick={props.onClickClose}>{t('common.cancel')}</Button>
      <Button
        type="primary"
        disabled={!isReturnAddressValid(validatedFields)}
        onClick={saveReturnAddress}
      >
        {props.modal === 'add'
          ? t('networks.returnAddress.addModal.confirmButton')
          : t('networks.returnAddress.editModal.confirmButton')}
      </Button>
    </>
  )

  return (
    <Modal
      show={true}
      title={
        props.modal === 'add'
          ? t('networks.returnAddress.addModal.title')
          : t('networks.returnAddress.editModal.title')
      }
      footer={footer}
      onRequestHide={props.onClickClose}
      closeButton={true}
    >
      <ValidationContainer validationResult={doValidateField('address.name')}>
        <TextField
          name="name"
          label={t('networks.returnAddress.commonModal.name')}
          value={returnAddress.address.name}
          required={true}
          onChange={propertyChange('address.name')}
          bsStyle={toBSStyle(doValidateField('address.name'))}
        />
      </ValidationContainer>

      <ValidationContainer validationResult={doValidateField('address.street')}>
        <TextField
          name="street"
          label={t('networks.returnAddress.commonModal.street')}
          value={returnAddress.address.street}
          required={true}
          onChange={propertyChange('address.street')}
          bsStyle={toBSStyle(doValidateField('address.street'))}
        />
      </ValidationContainer>
      <TextField
        name="street2"
        label={t('networks.returnAddress.commonModal.street2')}
        value={returnAddress.address.street2}
        onChange={propertyChange('address.street2')}
      />
      <ValidationContainer validationResult={doValidateField('address.city')}>
        <TextField
          name="city"
          label={t('networks.returnAddress.commonModal.city')}
          value={returnAddress.address.city}
          required={true}
          onChange={propertyChange('address.city')}
          bsStyle={toBSStyle(doValidateField('address.city'))}
        />
      </ValidationContainer>
      <ValidationContainer
        validationResult={doValidateField('address.postalCode')}
      >
        <TextField
          name="postalcode"
          label={t('networks.returnAddress.commonModal.postalCode')}
          value={returnAddress.address.postalCode}
          required={true}
          onChange={propertyChange('address.postalCode')}
          bsStyle={toBSStyle(doValidateField('address.postalCode'))}
        />
      </ValidationContainer>
      <ValidationContainer
        validationResult={doValidateField('address.country')}
      >
        <CountryPicker
          name="country"
          label={t('networks.returnAddress.commonModal.country')}
          value={returnAddress.address.country}
          required={true}
          validationStatus={ValidationStatus.NotValidated}
          onChange={onSelectChange('address.country')}
          bsStyle={toBSStyle(doValidateField('address.country'))}
        />
      </ValidationContainer>
      <h5>{t('networks.returnAddress.commonModal.applyOnlyToShipments')}</h5>
      <Select
        label={t('networks.returnAddress.commonModal.shippedByCarrier')}
        value={(props.carriers || []).filter(
          o => o.value === returnAddress.appliesToCarrier
        )}
        options={props.carriers}
        onChange={onSelectChange('appliesToCarrier')}
      />
      <Checkbox
        label={t('networks.returnAddress.commonModal.specificWebsiteCheckbox')}
        checked={applyToWebsite}
        onChange={toggleApplyToWebsite}
      />
      {applyToWebsite && (
        <TextField
          name="appliesToWebsite"
          label={t('networks.returnAddress.commonModal.website')}
          value={returnAddress.appliesToWebsite}
          onChange={propertyChange('appliesToWebsite')}
        />
      )}
    </Modal>
  )
}
