import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import { colors, Select, shapes, TextField } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ValidationContainer } from '../../../common/components/ValidationContainer'
import { get, set } from '../../../common/helpers/deepBracketNotation'
import {
  toBSStyle,
  validateField,
  ValidationResult,
  ValidationStatus,
} from '../../../common/helpers/validation'
import useCallbackOnValueChange from '../../../common/hooks/useCallbackOnValueChange'
import { Option } from '../../../common/models'
import EncryptedStringField from './EncryptedStringField'
import { CommonProps, getUploadLabel } from './uploadEditorFactory'
import {
  pldElmsUploadValidatedFields,
  validateAllFields,
} from './uploadEditorValidation'

interface Props extends CommonProps {
  upload: carrierAccounts.models.PldUpload
}
export default function PldElmsUploadEditor(props: Props) {
  const [upload, setUpload] = React.useState(props.upload)
  const { t } = useTranslation()

  const propertyChange = (path: string) => (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    set(upload, path, event.currentTarget.value)
    setUpload({ ...upload })
    props.onChange(upload, isUploadValid())
  }

  const onDocumentTypeChange = (changeData: Option[]) => {
    set(
      upload,
      'documentTypes',
      changeData.map(cd => cd.value)
    )
    setUpload({ ...upload })
    props.onChange(upload, isUploadValid())
  }
  const name = getUploadLabel(props.upload.uploadType)

  const isUploadValid = (): boolean => {
    const validationResults = validateAllFields(
      pldElmsUploadValidatedFields,
      upload
    )

    const documentTypesValid = upload.documentTypes.length > 0

    return (
      documentTypesValid &&
      validationResults.filter(vr => vr.status === ValidationStatus.Invalid)
        .length === 0
    )
  }

  const [validatedFields, setValidatedFields] = React.useState(
    new Set<string>()
  )

  useCallbackOnValueChange(() => {
    setValidatedFields(
      new Set([...pldElmsUploadValidatedFields.map(field => field.name), 'to'])
    )
    props.onChange(upload, isUploadValid())
  }, props.validateAll)

  const doValidateField = (fieldName: string): ValidationResult => {
    if (!validatedFields.has(fieldName)) {
      return {
        fieldName,
        status: ValidationStatus.NotValidated,
        message: '',
      }
    }
    const validationField = pldElmsUploadValidatedFields.find(
      field => field.name === fieldName
    )

    return validateField(validationField!, get(upload, fieldName))
  }

  return (
    <>
      <h5>
        {t('carrierAccounts.uploads.uploadTitle', { name })}{' '}
        {props.onRemove && (
          <button
            onClick={props.onRemove}
            className="btn"
            name="remove-upload"
            style={{ padding: 0 }}
          >
            <shapes.hamburger.Close
              width="18px"
              color={colors.ocean.base}
              cropped={true}
            />
          </button>
        )}
      </h5>
      <Select
        label={t('carrierAccounts.uploads.selectDocumentTypes')}
        value={props.documentTypeList.filter(o =>
          upload.documentTypes.includes(o.value)
        )}
        options={props.documentTypeList}
        required={true}
        onChange={onDocumentTypeChange}
        isMulti={true}
      />
      <ValidationContainer validationResult={doValidateField('url')}>
        <TextField
          value={upload.url}
          label={t('carrierAccounts.uploads.url')}
          onChange={propertyChange('url')}
          required={true}
          bsStyle={toBSStyle(doValidateField('url'))}
        />
      </ValidationContainer>

      <ValidationContainer validationResult={doValidateField('url')}>
        <TextField
          value={upload.username}
          label={t('carrierAccounts.uploads.username')}
          onChange={propertyChange('username')}
          required={true}
          bsStyle={toBSStyle(doValidateField('username'))}
        />
      </ValidationContainer>

      <ValidationContainer validationResult={doValidateField('url')}>
        <EncryptedStringField
          label={t('carrierAccounts.uploads.password')}
          password={upload.password}
          onChange={propertyChange('password')}
          required={true}
          bsStyle={toBSStyle(doValidateField('password'))}
        />
      </ValidationContainer>
    </>
  )
}
