import * as coam from '@cimpress-technology/coam-sapidus'
import { locations } from '@cimpress-technology/logistics-configuration-client'
import * as uuid from 'uuid'
import auth, { bearerToken } from '../auth'
import { logError } from '../logger'
import { SequenceDefinition } from '../models'
import * as proxy from './sequences-proxy'

export async function addSequenceToLocationCoamGroup(
  sequenceId: string,
  locationId: string
) {
  await locations.linkResourceToLocationGroup(
    bearerToken(),
    uuid.v4(),
    locationId,
    sequenceId,
    coam.models.ResourceTypes.LogisticsSequence
  )
}

export async function createSequenceForLocation(
  sequenceDefinition: SequenceDefinition,
  locationId: string
): Promise<string> {
  const { _metadata, ...apiSequenceDefinition } = sequenceDefinition

  const sequenceId = await proxy.createSequence(
    apiSequenceDefinition,
    auth.getAccessToken()
  )

  try {
    await addSequenceToLocationCoamGroup(sequenceId, locationId)
  } catch (err) {
    try {
      await proxy.deleteSequence(sequenceId, auth.getAccessToken())
    } catch (err2) {
      logError(
        `Failed to delete sequence id=${sequenceId} after linking to Location failed`,
        err2
      )
    }

    throw err
  }

  return sequenceId
}

export async function removeSequenceFromLocationCoamGroup(
  sequenceId: string,
  locationId: string
) {
  await locations.unlinkResourceFromLocationGroup(
    bearerToken(),
    uuid.v4(),
    locationId,
    sequenceId,
    coam.models.ResourceTypes.LogisticsSequence
  )
}

export async function deleteSequenceFromLocation(
  sequenceId: string,
  locationId: string
): Promise<void> {
  await proxy.deleteSequence(sequenceId, auth.getAccessToken())
  await removeSequenceFromLocationCoamGroup(sequenceId, locationId)
}

export async function getSequenceDirect(url: string) {
  return proxy.getSequenceDirect(url, auth.getAccessToken())
}

export async function patchSequence(sequenceId: string, diff: any) {
  return proxy.patchSequence(sequenceId, diff, auth.getAccessToken())
}
