import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import DocumentTypeView from './DocumentTypeView'
import { getUploadLabel } from './uploadEditorFactory'
import styles from './UploadView.module.css'
import { CommonProps } from './uploadViewFactory'

interface Props extends CommonProps {
  upload: carrierAccounts.models.PldUpload
}
export default function PldElmsUploadView(props: Props) {
  const { t } = useTranslation()
  const name = getUploadLabel(props.upload.uploadType)

  return (
    <>
      <h6 className={styles.uploadViewHeader}>
        {t('carrierAccounts.uploads.uploadTitle', { name })}
      </h6>
      <DocumentTypeView
        documentTypeList={props.documentTypeList}
        documentTypes={props.upload.documentTypes}
      />
      <div className={styles.uploadView}>
        <div>{t('carrierAccounts.uploads.url')}</div>
        <div>{props.upload.url}</div>
      </div>
      <div className={styles.uploadView}>
        <div>{props.upload.username}</div>
        <div>{t('carrierAccounts.uploads.username')}</div>
      </div>{' '}
      <div className={styles.uploadView}>
        <div>{t('carrierAccounts.uploads.password')}</div>
        <div>***</div>
      </div>
    </>
  )
}
