import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { Option, Uploads } from '../../../common/models'
import EmailUploadView from './EmailUploadView'
import FtpUploadView from './FtpUploadView'
import PldElmsUploadView from './PldElmsUploadView'

export interface CommonProps {
  documentTypeList: Option[]
}

export function getUploadView(upload: Uploads, documentTypeList: Option[]) {
  const key = `${upload.uploadType}-${upload.documentTypes.join('-')}`
  if (
    upload.uploadType === 'ftp' ||
    upload.uploadType === 'sftp' ||
    upload.uploadType === 'ftps'
  ) {
    return (
      <FtpUploadView
        key={key}
        upload={upload as carrierAccounts.models.FtpUpload}
        documentTypeList={documentTypeList}
      />
    )
  } else if (upload.uploadType === 'email') {
    return (
      <EmailUploadView
        key={key}
        upload={upload as carrierAccounts.models.EmailUpload}
        documentTypeList={documentTypeList}
      />
    )
  } else if (upload.uploadType === 'pld' || upload.uploadType === 'elms') {
    return (
      <PldElmsUploadView
        key={key}
        upload={upload as carrierAccounts.models.PldUpload}
        documentTypeList={documentTypeList}
      />
    )
  }

  throw new Error('Unknown upload type')
}
