import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { getCountryName } from '../../i18n'
import * as models from '../models'
import { CountryFlag } from './CountryFlag'

export interface Props {
  address: models.LocationAddress
}

export default function Address(props: Props) {
  const { t } = useTranslation()

  return (
    <>
      <h5>{t('locations.address')}</h5>
      <address>
        {props.address.street1}
        <br />
        {props.address.street2 && (
          <>
            {props.address.street2}
            <br />
          </>
        )}
        {props.address.locality} {props.address.postalCode}{' '}
        {props.address.region}
        <br />
        <CountryFlag countryCode={props.address.countryCode} />
        {getCountryName(props.address.countryCode)}
      </address>
    </>
  )
}
