import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import Preloader from '../../common/components/Preloader'
import FourOhFourPage from '../../common/FourOhFourPage'
import { clone } from '../../common/helpers/clone'
import { SequenceDefinition } from '../../common/models'
import {
  getCarrierAccount,
  updateCarrierAccount,
} from '../../common/proxy/carrier-accounts-store'
import { getCaasProfile } from '../../common/proxy/carrier-services-store'
import { getSequenceDirect } from '../../common/proxy/sequences-store'
import { useLogisticsLocation } from '../../locations/LocationContext'
import ViewCarrierAccount, { PreloadedData } from './ViewCarrierAccount'

export interface Props
  extends RouteComponentProps<{
    carrierAccountId: string
  }> {}

export default function ViewCarrierAccountPageContainer(props: Props) {
  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState<PreloadedData>()
  const { logisticsLocation } = useLogisticsLocation()

  React.useEffect(() => {
    const fetch = async () => {
      setLoading(true)

      const carrierAccountId = props.match.params.carrierAccountId
      const [carrierAccount] = await Promise.all([
        getCarrierAccount(carrierAccountId),
      ])

      if (carrierAccount === undefined) {
        return
      }

      const sequenceDefinitionPromises = Promise.all(
        Object.keys(carrierAccount.sequences!).map(async key => ({
          key,
          sequenceDefinition: await getSequenceDirect(
            carrierAccount.sequences![key]._links!.self.href
          ),
        }))
      )

      const [profile, sequenceDefinitions] = await Promise.all([
        getCaasProfile(carrierAccount.carrierKey),
        sequenceDefinitionPromises,
      ])

      const sequenceDefinitionPairs = sequenceDefinitions.map(sequenceData => ({
        sequence: profile.sequences!.find(s => s.key === sequenceData.key)!,
        sequenceDefinition: {
          ...sequenceData.sequenceDefinition,
          _metadata: {
            sequenceKey: sequenceData.key,
            isShared:
              (profile.sharedSequences || {})[sequenceData.key] !== undefined,
          },
        } as SequenceDefinition,
      }))

      setData({
        carrierAccount,
        profile,
        sequenceDefinitionPairs,
      })
      setLoading(false)
    }
    fetch()
  }, [logisticsLocation, props.match.params.carrierAccountId])

  if (loading) {
    return <Preloader />
  }

  if (!data) {
    return <FourOhFourPage />
  }

  const updateCarrierAccountName = async (
    updatedCarrierAccountName: string
  ) => {
    const newCarrierAccount = clone(data.carrierAccount)
    newCarrierAccount.name = updatedCarrierAccountName

    const updated = await updateCarrierAccount(
      data.carrierAccount,
      newCarrierAccount
    )

    newCarrierAccount.etag = updated
    setData({ ...data, carrierAccount: newCarrierAccount })
  }

  return (
    <ViewCarrierAccount
      {...props}
      {...data}
      updateCarrierAccountName={updateCarrierAccountName}
    />
  )
}
