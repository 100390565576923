import * as React from 'react'
import { useTranslation } from 'react-i18next'

export default function AuthorizationPage() {
  const { t } = useTranslation()

  return (
    <main className="App-content container">
      <div className="row" style={{ textAlign: 'center' }}>
        <div className="card">
          <div className="card-block">
            <h1>{t('loginRequired')}</h1>
            <p style={{ padding: '10px' }}>{t('youWillBeRedirected')}</p>
          </div>
        </div>
      </div>
    </main>
  )
}
