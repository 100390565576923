import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
export default function NoLocationsPage() {
  const { t } = useTranslation()

  return (
    <div style={{ textAlign: 'center' }}>
      <h5>
        <Trans i18nKey="locations.selectLocationPage.noLocationsForUser">
          No locations are configured for this user. <br /> Ask your logistics
          manager for access or add a new location.
        </Trans>
      </h5>
      <Link to={'location/add'}>
        <button className="btn btn-primary btn-lg">
          {t('locations.addLocation')}
        </button>
      </Link>
    </div>
  )
}
