import { Select } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import Preloader from '../../common/components/Preloader'
import { SnackbarController } from '../../common/components/SnackbarController'
import { logError } from '../../common/logger'
import * as models from '../../common/models'
import {
  addLocationToNetwork,
  createLocation,
  getAllNetworks,
} from '../../common/proxy/locations-store'
import LocationEditor from '../LocationEditor'

interface Props extends RouteComponentProps<{}> {
  children?: React.ReactNode
}

export default function AddLocationPageContainer(props: Props) {
  const { t } = useTranslation()

  const [saving, setSaving] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [networks, setNetworks] = React.useState<models.Network[]>([])
  const [selectedNetwork, setSelectedNetwork] = React.useState<string>()
  const [serverError, setServerError] = React.useState<models.ServerError>()

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const loadedNetworks = await getAllNetworks()
      setLoading(false)
      setNetworks(loadedNetworks.filter(network => network.editable))
    }

    fetchData()
  }, [])

  if (loading) {
    return <Preloader />
  }
  const onSave = async (location: models.Location) => {
    setSaving(true)
    try {
      const id = await createLocation(location)
      if (selectedNetwork) {
        await addLocationToNetwork(id, selectedNetwork)
      }
      SnackbarController.show(
        t('locations.locationEditor.logisticsLocationAdded'),
        'success'
      )
      props.history.push('/location/' + id)
    } catch (error) {
      logError('Error when saving location', error)
      setServerError(error.response.data)
      setSaving(false)
    }
  }

  const onCancel = () => {
    if (props.history.length === 1) {
      props.history.replace('')
    } else {
      props.history.goBack()
    }
  }

  const onSelectNetwork = (data: models.Option) => {
    setSelectedNetwork(data.value as string)
  }

  const networkOptions = networks
    .filter(network => network.editable)
    .map(network => ({
      label: network.apiNetwork.name,
      value: network.apiNetwork.id,
    }))

  const selectNetwork =
    networkOptions.length > 0 ? (
      <Select
        label={t(
          'locations.selectLocationPage.addLocationToNetworkModal.selectANetwork'
        )}
        isSearchable={true}
        isClearable={false}
        name={'selectNetwork'}
        value={networkOptions.filter(o => o.value === selectedNetwork)}
        options={networkOptions}
        onChange={onSelectNetwork}
        menuPortalTarget={document.getElementById(models.reactSelectPortalId)}
      />
    ) : undefined

  return (
    <main className="App-content container">
      <LocationEditor
        saving={saving}
        onSave={onSave}
        serverError={serverError}
        onCancel={onCancel}
      >
        {selectNetwork}
      </LocationEditor>
    </main>
  )
}
