import { Table, Button, Tooltip } from '@cimpress/react-components'
import moment from 'moment-timezone'
import * as React from 'react'
import ReactJsonView, { CollapsedFieldProps } from 'react-json-view'
import { Filter, Column } from 'react-table'
import { AuditRow } from '@cimpress-technology/logistics-configuration-client/js/models/audits'
import * as jsonPatch from 'fast-json-patch'
import useQuerystring from '../../common/hooks/useQuerystring'

export const LocationResourceTypeArray = [
  'Location',
  'Working days calendar',
  'Pickup calendar',
  'Country calendar',
  'Transit calendar',
  'Delivery calendar',
  'User Management',
] as const

interface Props {
  data: (AuditRow & { link: React.ReactNode; patch?: jsonPatch.Operation[] })[]
}

export default function MultiResourceAuditTable(props: Props) {
  const [querystring, setQuerystring] = useQuerystring()
  const initialFilter = getFiltersFromQuery(querystring)

  const [filtered, setFiltered] = React.useState<Filter[]>(initialFilter)

  const setFilter = (newFilters: Filter[]) => {
    setQuerystring(convertFiltersToQuery(newFilters))
    setFiltered(newFilters)
  }

  const onFilteredChange = (newFiltering: Filter[]) => {
    setFilter(newFiltering)
  }

  const setFilterToWorkingDay = (workingDayCalendarId: string) => {
    return () => setFilter([{ id: 'resourceId', value: workingDayCalendarId }])
  }

  const ResourceFilter = ({ filter, onChange }: any) => {
    const onValueChange = (e: any) => onChange(e.target.value)

    return (
      <select
        onChange={onValueChange}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}
      >
        <option value="all">Show All</option>
        {LocationResourceTypeArray.map(t => (
          <option key={t} value={t}>
            {t}
          </option>
        ))}
      </select>
    )
  }

  const resourceFilterMethod = (filter: any, row: any) => {
    if (filter.value === 'all') {
      return true
    }

    return filter.value === row.type
  }

  const columns: Column[] = [
    {
      Header: 'Resource Type',
      accessor: 'type',
      show: false,
    },
    {
      Header: 'Resource',
      id: 'resourceType',
      accessor: 'link',
      filterMethod: resourceFilterMethod,
      Filter: ResourceFilter,
      maxWidth: 190,
    },
    {
      Header: 'Resource Id',
      id: 'resourceId',
      accessor: 'resource_id',
      maxWidth: 150,
    },
    {
      Header: 'Timestamp',
      filterable: false,
      accessor: 'created_at',
      Cell: (row: any) => (
        <Tooltip
          contents={`Recorded at ${moment(row.original.created_at).format()}`}
          key={`timestamp-tooltip-${row.original.created_at}`}
        >
          {moment(row.original.created_at).fromNow()}
        </Tooltip>
      ),
    },
    {
      Header: 'Action',
      accessor: 'action',
      maxWidth: 190,
      Cell: (row: any) => {
        if (row.original.action === 'WORKING-DAYS-UPDATE') {
          return (
            <Button
              onClick={setFilterToWorkingDay(
                row.original.data.workingDaysCalendar.id
              )}
              size="sm"
            >
              Filter working days <br /> calendar
            </Button>
          )
        }

        return row.original.action
      },
    },
    { Header: 'User', accessor: 'user' },
    {
      Header: 'Applied change',
      filterable: false,
      sortable: false,
      Cell: (row: any) => {
        if (row.original.action === 'WORKING-DAYS-UPDATE') {
          return (
            <ReactJsonView
              collapsed={true}
              src={row.original.patch}
              enableClipboard={false}
              name={null}
              displayDataTypes={false}
            />
          )
        }

        if (!row.original.patch) {
          return null
        }

        const shouldCollapse = (field: CollapsedFieldProps) => {
          if (field.type === 'array') {
            return (field.src as any).length > 3
          }

          if ((field.src as any).path === '/etag') {
            return true
          }

          return false
        }

        return (
          <ReactJsonView
            shouldCollapse={shouldCollapse}
            src={row.original.patch}
            enableClipboard={false}
            name={null}
            displayDataTypes={false}
          />
        )
      },
    },
    {
      Header: 'Current Status',
      filterable: false,
      sortable: false,
      Cell: (row: any) => (
        <ReactJsonView
          collapsed={true}
          src={row.original.data}
          enableClipboard={false}
          name={null}
          displayDataTypes={false}
        />
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      data={props.data}
      sortable={true}
      resizable={false}
      showPagination={true}
      filterable={true}
      filtered={filtered}
      onFilteredChange={onFilteredChange}
      noDataText="No records found"
      defaultFilterMethod={filterCaseInsensitive}
      defaultSorted={[{ id: 'created_at', desc: true }]}
    />
  )
}

function filterCaseInsensitive(filter: Filter, row: any): boolean {
  const id = filter.pivotId || filter.id

  return row[id] !== undefined
    ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
    : true
}

function getFiltersFromQuery(querystring: any) {
  return Object.entries(querystring).map(([id, value]) => ({
    id,
    value,
  }))
}

function convertFiltersToQuery(filters: Filter[]) {
  return filters.reduce((acc, filter) => {
    acc[filter.id] = filter.value

    return acc
  }, {})
}
