export function set(
  object: object,
  path: string,
  value: any,
  separator = '.'
): void {
  const parts = path.split(separator)
  let dest = object
  parts.slice(0, -1).forEach(part => (dest = dest[part]))
  dest[parts[parts.length - 1]] = value
}

export function get(object: object, path: string, separator = '.'): any {
  const parts = path.split(separator)
  let result = object
  for (const part of parts) {
    if (result === undefined) {
      return undefined
    }
    result = result[part]
  }

  return result
}
