import * as coam from '@cimpress-technology/coam-sapidus'
import { LogisticsLocationRoles } from '@cimpress-technology/logistics-configuration-client/js/models/locations'
import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation } from 'react-i18next'
import auth from '../../common/auth'
import { SnackbarController } from '../../common/components/SnackbarController'
import * as locationsStore from '../../common/proxy/locations-store'
import { useLogisticsLocation } from '../../locations/LocationContext'
import { CanonicalCoamUser, locationRoles, getName } from '../models'
import Preloader from '../../common/components/Preloader'
import PermissionsEditor from './PermissionsEditor'

export default function LocationPermissionEditor() {
  const { t } = useTranslation()
  const { logisticsLocation } = useLogisticsLocation()
  const [loading, setLoading] = React.useState(true)
  const [coamGroup, setCoamGroup] = React.useState<
    coam.models.DetailedCoamGroup
  >()
  const fetchData = async () => {
    setLoading(true)
    const coamGroupOverview = await coam.group.findCoamGroupByResourceAndName(
      {
        id: logisticsLocation.id,
        type: coam.models.ResourceTypes.LogisticsLocation,
      },
      `Logistics location (${logisticsLocation.id})`,
      auth.getAccessToken(),
      auth.getProfile().sub
    )
    if (!coamGroupOverview) {
      setLoading(false)

      return
    }

    const coamGroupDetail = await coam.group.getCoamGroupDetails(
      coamGroupOverview.id,
      auth.getAccessToken(),
      auth.getProfile().sub,
      true
    )

    setCoamGroup(coamGroupDetail)
    setLoading(false)
  }

  React.useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logisticsLocation.id])

  if (loading) {
    return <Preloader />
  }

  const removeUser = async (email: string) => {
    await locationsStore.removeUser(logisticsLocation.id, email)
    await fetchData()
  }

  const saveUser = async (
    user: CanonicalCoamUser,
    role: LogisticsLocationRoles,
    action: 'add' | 'edit'
  ) => {
    const email = user.canonical_principal || user.principal
    await locationsStore.addUser(logisticsLocation.id, email, role)
    await fetchData()
    const message =
      action === 'add'
        ? t('userManagement.messages.userHasBeenAdded', {
            username: getName(user),
            role,
          })
        : t('userManagement.messages.userHasBeenUpdated', {
            username: getName(user),
            role,
          })

    SnackbarController.show(message, 'success')
  }

  return (
    <>
      <BreadcrumbsItem to={`/location/${logisticsLocation.id}`}>
        {logisticsLocation.name}
      </BreadcrumbsItem>
      <BreadcrumbsItem to="#">
        {t('userManagement.userManagement')}
      </BreadcrumbsItem>
      <PermissionsEditor
        editable={logisticsLocation.editable}
        roles={locationRoles}
        saveUser={saveUser}
        removeUser={removeUser}
        coamGroup={coamGroup}
      />
    </>
  )
}
