import { clone } from '../../helpers/clone'
import { ConfigSpec, EnhancedCaasProfile } from '../../models-carriers'
import { ApiCaasProfile } from '../carrier-services-proxy'

export function enrichCaasProfile(profile: ApiCaasProfile): ApiCaasProfile {
  switch (profile.key) {
    case 'ups-api': {
      const updatedProfile = clone(profile)
      getSpec('apiInfo', updatedProfile).name = ''

      return updatedProfile
    }

    case 'brt': {
      const updatedProfile = clone(profile)
      getSpec('account', updatedProfile).name = 'Dettagli account'
      getSpec('account/customerCode', updatedProfile).name = 'Codice standard'
      getSpec('seriesNumber', updatedProfile).name = 'Numero serie'
      getSpec('departureDepot', updatedProfile).name = 'Filiale di partenza'
      getSpec('departureTerminal', updatedProfile).name = 'Terminal di partenza'

      return updatedProfile
    }

    default:
      return profile
  }
}

function getSpec(path: string, profile: EnhancedCaasProfile): ConfigSpec {
  const parts = path.split('/')
  let configSpec: ConfigSpec = {
    key: 'key',
    name: '',
    value: {
      valueType: 'object',
      items: profile.accountConfigSpec,
    },
  }

  if (configSpec.value.items === undefined) {
    throw new Error(
      `Can't find spec with path: ${path} in profile ${JSON.stringify(profile)}`
    )
  }

  parts.forEach(part => {
    if (
      configSpec.value.valueType !== 'object' ||
      !Array.isArray(configSpec.value.items)
    ) {
      throw new Error(
        `Expected config spec to be object with values, found: ${configSpec}`
      )
    }
    const spec = configSpec.value.items.find(s => s.key === part)
    if (spec === undefined) {
      throw new Error(
        `Can't find spec with path: ${path} in spec ${JSON.stringify(
          configSpec
        )}`
      )
    }
    configSpec = spec
  })

  return configSpec
}
