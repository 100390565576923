import { get } from '../../../common/helpers/deepBracketNotation'
import {
  FieldType,
  ValidatedField,
  validateField,
  ValidationResult,
  ValidationStatus,
} from '../../../common/helpers/validation'
import { Uploads } from '../../../common/models'

export const emailUploadValidatedFields: ValidatedField[] = [
  {
    i18nKey: 'validation.uploads.subject',
    name: 'subject',
    required: true,
    type: FieldType.Text,
  },
  {
    i18nKey: 'validation.uploads.template',
    name: 'template',
    required: true,
    type: FieldType.Text,
  },
]

export const ftpUploadValidatedFields: ValidatedField[] = [
  {
    i18nKey: 'validation.uploads.hostName',
    name: 'host',
    required: true,
    type: FieldType.Text,
  },
  {
    i18nKey: 'validation.uploads.hostPort',
    name: 'port',
    required: true,
    type: FieldType.Text,
  },
  {
    i18nKey: 'validation.uploads.username',
    displayName: 'Username',
    name: 'username',
    required: true,
    type: FieldType.Text,
  },
  {
    i18nKey: 'validation.uploads.password',
    name: 'password',
    required: true,
    type: FieldType.Text,
  },
  {
    i18nKey: 'validation.uploads.directory',
    name: 'directory',
    required: true,
    type: FieldType.Text,
  },
]

export const pldElmsUploadValidatedFields: ValidatedField[] = [
  {
    i18nKey: 'validation.uploads.url',
    name: 'url',
    required: true,
    type: FieldType.Text,
  },
  {
    i18nKey: 'validation.uploads.username',
    name: 'username',
    required: true,
    type: FieldType.Text,
  },
  {
    i18nKey: 'validation.uploads.password',
    name: 'password',
    required: true,
    type: FieldType.Text,
  },
]

export function validateAllFields(
  fields: ValidatedField[],
  upload: Uploads
): ValidationResult[] {
  return fields.map(field => validateField(field, get(upload, field.name)))
}

const getFieldsDefinition = (upload: Uploads): ValidatedField[] => {
  switch (upload.uploadType) {
    case 'ftp':
    case 'ftps':
    case 'sftp':
      return ftpUploadValidatedFields
    case 'email':
      return emailUploadValidatedFields
    case 'pld':
    case 'elms':
      return pldElmsUploadValidatedFields
  }
}

export function isUploadValid(upload: Uploads) {
  const fields = getFieldsDefinition(upload)
  const validationResults = validateAllFields(fields, upload)

  return (
    upload.documentTypes.length > 0 &&
    validationResults.filter(vr => vr.status === ValidationStatus.Invalid)
      .length === 0
  )
}
