import * as _ from 'underscore'
import { PatchSequence, SequenceDefinition } from '../../../common/models'
import { Sequence } from '../../../common/models-carriers'

export function showAsUploadSequence(sequence: Sequence): boolean {
  return (
    !sequence._metadata.isShared &&
    sequence._metadata.isDocumentSequence === true &&
    sequence.default === undefined
  )
}

export function showAsTrackingSequence(sequence: Sequence): boolean {
  return (
    !sequence._metadata.isShared &&
    sequence._metadata.isDocumentSequence === false &&
    sequence.default === undefined
  )
}

export function createPatchSequence(
  sequence: SequenceDefinition,
  sequenceBeingEdited: SequenceDefinition
): PatchSequence {
  const patch: PatchSequence = {}

  if (sequence.description !== sequenceBeingEdited.description) {
    patch.description = sequence.description
  }

  if (sequence.current !== sequenceBeingEdited.current) {
    patch.current = sequence.current
  }

  if (sequence.active !== sequenceBeingEdited.active) {
    patch.active = sequence.active
  }

  if (!_.isEqual(sequence.activeRange, sequenceBeingEdited.activeRange)) {
    patch.activeRange = sequence.activeRange
  }

  if (
    sequence.replacementStrategy !== sequenceBeingEdited.replacementStrategy
  ) {
    patch.replacementStrategy = sequence.replacementStrategy
  }

  if (
    !_.isEqual(
      sequence.replacementRanges,
      sequenceBeingEdited.replacementRanges
    )
  ) {
    patch.replacementRanges = sequence.replacementRanges
  }

  return patch
}
