import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { Option, Uploads } from '../../../common/models'
import EmailUploadEditor from './EmailUploadEditor'
import FtpUploadEditor from './FtpUploadEditor'
import PldElmsUploadEditor from './PldElmsUploadEditor'

export interface CommonProps {
  onChange: (upload: Uploads, isValid: boolean) => void
  onRemove?: () => void

  documentTypeList: Option[]
  validateAll?: boolean
}

export function getUploadEditor(
  upload: Uploads,
  documentTypeList: Option[],
  onUploadChange: (upload: Uploads, isValid: boolean) => void,
  onRemove?: () => void,
  validateAll?: boolean
) {
  const key = `${upload.uploadType}-${upload.documentTypes.join('-')}`
  if (
    upload.uploadType === 'ftp' ||
    upload.uploadType === 'sftp' ||
    upload.uploadType === 'ftps'
  ) {
    return (
      <FtpUploadEditor
        key={key}
        upload={upload as carrierAccounts.models.FtpUpload}
        onChange={onUploadChange}
        onRemove={onRemove}
        documentTypeList={documentTypeList}
        validateAll={validateAll}
      />
    )
  } else if (upload.uploadType === 'email') {
    return (
      <EmailUploadEditor
        key={key}
        upload={upload as carrierAccounts.models.EmailUpload}
        onChange={onUploadChange}
        onRemove={onRemove}
        documentTypeList={documentTypeList}
        validateAll={validateAll}
      />
    )
  } else if (upload.uploadType === 'pld' || upload.uploadType === 'elms') {
    return (
      <PldElmsUploadEditor
        key={key}
        upload={upload as carrierAccounts.models.PldUpload}
        onChange={onUploadChange}
        onRemove={onRemove}
        documentTypeList={documentTypeList}
        validateAll={validateAll}
      />
    )
  }

  throw new Error('Unknown upload type')
}

export function getUploadLabel(uploadType: carrierAccounts.models.UploadType) {
  switch (uploadType) {
    case 'ftp':
      return 'FTP'
    case 'ftps':
      return 'FTPS'
    case 'sftp':
      return 'SFTP'
    case 'email':
      return 'Email'
    case 'pld':
      return 'PLD'
    case 'elms':
      return 'ELMS'
  }
}

export function getUploadsSelectOptions(caasProfileKey: string): Option[] {
  const uploadTypes: carrierAccounts.models.UploadType[] = [
    'ftp',
    'ftps',
    'sftp',
    'email',
  ]

  if (
    caasProfileKey === 'australia-post-eparcel' ||
    caasProfileKey === 'australia-post-air-ral'
  ) {
    uploadTypes.push('elms')
  }

  if (caasProfileKey === 'ups-host' || caasProfileKey === 'ups-surepost') {
    uploadTypes.push('pld')
  }

  return uploadTypes.map(uploadType => ({
    value: uploadType,
    label: getUploadLabel(uploadType),
  }))
}
