import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { AuditResource } from '../../common/models'
import styles from './AuditPage.module.css'
import AuditTable from './AuditTable'

export default function AuditPage() {
  const { resourceId, resourceType } = useParams<AuditResource>()

  return (
    <main className="main-container">
      <Helmet>
        <title>
          Audit {resourceType} {resourceId}
        </title>
      </Helmet>
      <div className="row">
        <div className="col-xs-12">
          <h4 className={styles.marginTop}>
            Change history for {resourceType} {resourceId}
          </h4>
          <div className="card card-minimal">
            <div className="card-block">
              <AuditTable resourceId={resourceId} resourceType={resourceType} />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
