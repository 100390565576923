import { SequenceDefinition } from '../../../common/models'
import { Sequence } from '../../../common/models-carriers'
export function defaultSequenceDefinition(
  sequence: Sequence
): SequenceDefinition {
  if (sequence.default) {
    const seq = sequence.default

    return {
      active: true,
      description: '',
      current: seq.current || 0,
      activeRange: {
        start: seq.start || 0,
        end: seq.end || 100,
        prefix: seq.prefix,
      },
      replacementRanges: [],
      replacementStrategy: seq.strategy || 'retireSequence',
      _metadata: {
        isShared: sequence._metadata.isShared,
        sequenceKey: sequence.key,
      },
    }
  }

  return {
    active: true,
    description: '',
    current: 0,
    activeRange: { start: 0, end: 100 },
    replacementRanges: [],
    replacementStrategy: 'retireSequence',
    _metadata: {
      isShared: sequence._metadata.isShared,
      sequenceKey: sequence.key,
    },
  }
}
