import { carrierAccounts as carrierAccountsClient } from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import AddCarrierAccountPageContainer, {
  Props as AddCarrierAccountPageContainerProps,
} from '../../carrier-configuration/components/add-carrier-flow/AddCarrierConfigurationContainer'
import Preloader from '../../common/components/Preloader'
import FourOhFourPage from '../../common/FourOhFourPage'
import { EnhancedCaasProfile } from '../../common/models-carriers'
import { IncompleteCarrierAccount } from '../../common/proxy/backend-proxy'
import { getIncompleteCarrierAccount } from '../../common/proxy/backend-store'
import { getCarrierAccountsForLocation } from '../../common/proxy/carrier-accounts-store'
import {
  getCaasProfiles,
  getDocumentTypeKeys,
} from '../../common/proxy/carrier-services-store'
import { useLogisticsLocation } from '../../locations/LocationContext'
import { useAppContext } from '../../common/AppContext'
import { CarrierSelection } from './add-carrier-flow/carrierAccountData'

export type Props = RouteComponentProps<
  {
    locationId: string
    incompleteCarrierAccountId: string
  },
  any,
  { carrierSelection: CarrierSelection }
>

export function IncompleteCarrierAccountPage(props: Props) {
  const { containerType } = useAppContext()

  const [isLoading, setIsLoading] = React.useState(true)
  const [
    incompleteCarrierAccount,
    setIncompleteCarrierAccount,
  ] = React.useState<IncompleteCarrierAccount>()
  const { logisticsLocation } = useLogisticsLocation()

  const [
    existingCarrierAccountNames,
    setExistingCarrierAccountNames,
  ] = React.useState<string[]>([])

  const [caasProfiles, setCaasProfiles] = React.useState<EnhancedCaasProfile[]>(
    []
  )

  const incompleteCarrierAccountId =
    props.match.params.incompleteCarrierAccountId

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const [ica, profiles] = await Promise.all([
        getIncompleteCarrierAccount(
          logisticsLocation.id,
          incompleteCarrierAccountId
        ),
        getCaasProfiles(),
      ])

      const carrierAccounts = await getCarrierAccountsForLocation(
        logisticsLocation
      )
      setExistingCarrierAccountNames(
        (carrierAccounts.filter(
          ca => ca !== undefined
        ) as carrierAccountsClient.models.CarrierAccountWithLink[])
          .map(ca => ca.name)
          .filter(name => name !== ica.createCarrierAccount.name)
      )
      setIncompleteCarrierAccount(ica)
      setCaasProfiles(profiles)
      setIsLoading(false)
    }

    fetchData()
  }, [incompleteCarrierAccountId, logisticsLocation])

  if (isLoading) {
    return <Preloader />
  }

  if (!logisticsLocation || !incompleteCarrierAccount) {
    return <FourOhFourPage />
  }

  const usedProfile = caasProfiles.find(
    x => x.key === incompleteCarrierAccount.createCarrierAccount.carrierKey
  )

  if (!usedProfile) {
    return <FourOhFourPage />
  }

  const addCarrierAccountPageContainerProps: AddCarrierAccountPageContainerProps = {
    ...props,
    logisticsLocation,
    existingCarrierAccountNames,
    caasProfiles,
    existingData: {
      incompleteCarrierAccount,
      configSpec: usedProfile.accountConfigSpec!,
      defaultDocumentUploads: usedProfile.defaultDocumentUploads,
      documentTypeKeys: getDocumentTypeKeys(
        caasProfiles.find(
          profile =>
            profile.key ===
            incompleteCarrierAccount.createCarrierAccount.carrierKey
        )!
      ),
      sequences: usedProfile.sequences,
      sequenceDefinitions: incompleteCarrierAccount.sequences,
      sharedSequences: usedProfile.sharedSequences || {},
      profile: usedProfile,
    },
  }

  return (
    <main className={`App-content ${containerType} flex-vertical`}>
      <AddCarrierAccountPageContainer
        {...addCarrierAccountPageContainerProps}
      />
    </main>
  )
}
