import { carrierAccounts as carrierAccountsClient } from '@cimpress-technology/logistics-configuration-client'
import { Button } from '@cimpress/react-components'
import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Preloader from '../../common/components/Preloader'
import { SnackbarController } from '../../common/components/SnackbarController'
import { guessLogoFromCarrierAccount } from '../../common/helpers/carrier-logos'
import { EnhancedCaasProfile } from '../../common/models-carriers'
import { IncompleteCarrierAccount } from '../../common/proxy/backend-proxy'
import {
  deleteIncompleteCarrierAccount,
  getIncompleteCarrierAccounts,
} from '../../common/proxy/backend-store'
import { getCarrierAccountsForLocation } from '../../common/proxy/carrier-accounts-store'
import { getCaasProfiles } from '../../common/proxy/carrier-services-store'
import { useLogisticsLocation } from '../../locations/LocationContext'
import { CarrierSelection } from './add-carrier-flow/carrierAccountData'
import CarrierSelectModalContainer from './add-carrier-flow/CarrierSelectModalContainer'
import style from './CarrierAccountsList.module.css'
import { CompleteCarrierAccountRow } from './CompleteCarrierAccountRow'
import { IncompleteCarrierAccountRow } from './IncompleteCarrierAccountRow'
import { NoCarrierAccounts } from './NoCarrierAccounts'
import { RemoveCarrierAccountConfirmationModal } from './RemoveCarrierAccountConfirmationModal'

interface Props {
  children?: React.ReactNode
}

export function CarrierAccountsList(props: Props): JSX.Element {
  const { t } = useTranslation()
  const { logisticsLocation } = useLogisticsLocation()

  const [deleting, setDeleting] = React.useState(false)
  const [reload, setReload] = React.useState(true)
  const [caasProfiles, setCaasProfiles] = React.useState<EnhancedCaasProfile[]>(
    []
  )
  const [carrierAccounts, setCarrierAccounts] = React.useState(
    [] as carrierAccountsClient.models.CarrierAccountWithLink[]
  )
  const [incomplete, setIncomplete] = React.useState(
    [] as IncompleteCarrierAccount[]
  )

  const [isLoading, setIsLoading] = React.useState(true)

  const [carrierAccountToDelete, setCarrierAccountToDelete] = React.useState<
    IncompleteCarrierAccount
  >()

  const stopDeletingCarrierAccount = () => {
    setCarrierAccountToDelete(undefined)
  }

  const startDeletingCarrierAccount = (
    carrierAccount: IncompleteCarrierAccount
  ) => {
    setCarrierAccountToDelete(carrierAccount)
  }

  React.useEffect(() => {
    const fetchCarrierAccounts = async () => {
      setIsLoading(true)
      const [cas, icas, cps] = await Promise.all([
        getCarrierAccountsForLocation(logisticsLocation),
        getIncompleteCarrierAccounts(logisticsLocation.id),
        getCaasProfiles(),
      ])

      setCarrierAccounts(
        cas.filter(
          ca => ca !== undefined
        ) as carrierAccountsClient.models.CarrierAccountWithLink[]
      )
      setIncomplete(icas)
      setCaasProfiles(cps)
      setIsLoading(false)
    }

    fetchCarrierAccounts()
  }, [logisticsLocation, reload])

  if (isLoading) {
    return <Preloader />
  }

  if (carrierAccounts.length === 0 && incomplete.length === 0) {
    return (
      <CarrierAccountsListParent {...props} caasProfiles={caasProfiles}>
        <NoCarrierAccounts />
      </CarrierAccountsListParent>
    )
  }

  const onDelete = async () => {
    try {
      setDeleting(true)
      await deleteIncompleteCarrierAccount(
        logisticsLocation.id,
        carrierAccountToDelete!.id!
      )
      SnackbarController.show(
        t('carrierAccounts.carrierAccountRemoved', {
          name: carrierAccountToDelete!.createCarrierAccount.name,
        }),
        'success'
      )
    } catch (e) {
      SnackbarController.show(
        t('carrierAccounts.failedToRemoveCarrierAccount'),
        'danger'
      )
    }
    setCarrierAccountToDelete(undefined)
    setIsLoading(false)
    setReload(!reload)
    setDeleting(false)
  }

  const incompleteCarrierRows = incomplete
    .sort((a, b) =>
      compareCarrierAccountsByName(
        a.createCarrierAccount,
        b.createCarrierAccount
      )
    )
    .map(ica => (
      <IncompleteCarrierAccountRow
        incompleteCarrierAccount={ica}
        carrierLogo={guessLogoFromCarrierAccount(ica.createCarrierAccount)}
        onDelete={startDeletingCarrierAccount}
        key={ica.id}
      />
    ))
  const incompleteCarriers = incompleteCarrierRows.length > 0 && (
    <div className={`${style.incompleteCarrierList} row panel`}>
      <table className="table table-hover">
        <tbody>{incompleteCarrierRows}</tbody>
      </table>
    </div>
  )
  const completeCarrierRows = carrierAccounts
    .sort(compareCarrierAccountsByName)
    .map(ca => (
      <CompleteCarrierAccountRow
        carrierAccount={ca}
        carrierLogo={guessLogoFromCarrierAccount(ca)}
        key={ca.id}
      />
    ))
  const completeCarriers = completeCarrierRows.length > 0 && (
    <div className={`${style.completeCarrierList} row panel`}>
      <table className="table table-hover">
        <tbody>{completeCarrierRows}</tbody>
      </table>
    </div>
  )

  return (
    <>
      <CarrierAccountsListParent {...props} caasProfiles={caasProfiles}>
        <div className="col-xs-12 col-md-12">
          {incompleteCarriers}
          {completeCarriers}
        </div>
      </CarrierAccountsListParent>
      {carrierAccountToDelete ? (
        <RemoveCarrierAccountConfirmationModal
          incompleteCarrierAccount={carrierAccountToDelete}
          stopDeleting={stopDeletingCarrierAccount}
          delete={onDelete}
          blockUI={deleting}
        />
      ) : null}
    </>
  )
}

function CarrierAccountsListParent(
  props: Props & {
    caasProfiles: EnhancedCaasProfile[]
  }
) {
  const { t } = useTranslation()
  const history = useHistory()
  const { logisticsLocation } = useLogisticsLocation()

  const [showCarrierSelectModal, setShowCarrierSelectModal] = React.useState(
    false
  )

  const onClickAdd = () => {
    setShowCarrierSelectModal(true)
  }

  const cancel = () => {
    setShowCarrierSelectModal(false)
  }

  const onSelect = (carrierSelection: CarrierSelection) => {
    history.push('carrier-accounts/add', { carrierSelection })
  }

  return (
    <>
      <BreadcrumbsItem to="/">{t('common.logisticsLocations')}</BreadcrumbsItem>
      <BreadcrumbsItem to={`/location/${logisticsLocation.id}`}>
        {logisticsLocation.name}
      </BreadcrumbsItem>
      <BreadcrumbsItem to="#">{t('common.carrierAccounts')}</BreadcrumbsItem>
      <CarrierSelectModalContainer
        locationId={logisticsLocation.id}
        carrierAccountData={{}}
        onClose={cancel}
        onSelect={onSelect}
        show={showCarrierSelectModal}
        caasProfiles={props.caasProfiles}
      />
      <div className="row vertical-align">
        <div className="col-xs-5 col-lg-5">
          <h4>{t('common.carrierAccounts')}</h4>
        </div>
        <div className="col-xs-7 col-lg-7">
          <div className="text-right">
            <Link to="/carrier-explorer" className="btn btn-link">
              {t('carrierAccounts.exploreCarrierServices')}
            </Link>{' '}
            <Button type="primary" onClick={onClickAdd}>
              Add <span className="hidden-xs">account</span>
            </Button>
          </div>
        </div>
      </div>
      {props.children}
    </>
  )
}

function compareCarrierAccountsByName(
  a: carrierAccountsClient.models.CreateCarrierAccount,
  b: carrierAccountsClient.models.CreateCarrierAccount
): number {
  return a.name.localeCompare(b.name)
}
