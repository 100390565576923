import * as React from 'react'
import { EnhancedCaasProfile } from '../../../common/models-carriers'
import { CarrierSelection } from './carrierAccountData'
import CarrierSelectModal from './CarrierSelectModal'

interface Props {
  locationId: string
  carrierAccountData: CarrierSelection
  show: boolean
  onClose: () => void
  onSelect: (carrierAccountData: CarrierSelection) => void
  caasProfiles: EnhancedCaasProfile[]
}

export default function CarrierSelectModalContainer(props: Props) {
  const [carrierAccountData, setCarrierAccountData] = React.useState(
    props.carrierAccountData
  )

  const onChange = (cad: CarrierSelection) => {
    setCarrierAccountData(cad)
  }
  const onSelect = () => {
    props.onSelect(carrierAccountData)
  }

  return (
    <CarrierSelectModal
      carrierAccountTypes={props.caasProfiles}
      carrierAccountData={carrierAccountData}
      onClose={props.onClose}
      onSelect={onSelect}
      show={props.show}
      onChange={onChange}
    />
  )
}
