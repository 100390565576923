import { Select } from '@cimpress/react-components'
import { currencies } from 'country-data'
import { useField } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Option } from '../common/models'

const filtered = currencies.all.filter(
  currency =>
    !(currency.name.includes('(funds code)') || currency.code.startsWith('X'))
)

interface Props {
  name: string
}

export default function CurrencyPicker(props: Props) {
  const { t } = useTranslation()
  const [field, , helpers] = useField(props.name)

  const options = filtered.map(currency => ({
    label: `${currency.name} (${currency.code})`,
    value: currency.code,
  }))

  const onChange = (data: Option) => {
    helpers.setValue(data ? (data.value as string) : '')
  }

  return (
    <Select
      label={t('locations.currency')}
      name={field.name}
      value={options.filter(o => o.value === field.value)}
      options={options}
      onChange={onChange}
    />
  )
}
