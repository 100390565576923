import { Button, Modal } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ReturnAddress } from '../common/proxy/networks-store'

interface Props {
  returnAddress: ReturnAddress
  onClose: () => void
  onDelete: (returnAddress: ReturnAddress) => void
}
export default function DeleteReturnAddressModal(props: Props) {
  const { t } = useTranslation()
  const onDelete = () => {
    props.onDelete(props.returnAddress)
    props.onClose()
  }

  const footer = (
    <>
      <Button type="default" onClick={props.onClose} key="cancel-btn">
        {t('common.cancel')}
      </Button>
      <Button type="primary" onClick={onDelete} key="save-btn">
        {t('common.delete')}
      </Button>
    </>
  )

  return (
    <Modal
      show={true}
      onRequestHide={props.onClose}
      closeButton={true}
      closeOnOutsideClick={false}
      title={t('networks.returnAddress.deleteModal.title')}
      footer={footer}
    >
      <div>
        {t('networks.returnAddress.deleteModal.confirmation', {
          returnAddressName: props.returnAddress.address.name,
        })}
      </div>
    </Modal>
  )
}
