import * as React from 'react'
import { colors } from '@cimpress/react-components'

interface Props {
  left: string[]
  right: string[]
}

export default function DiffList(props: Props) {
  const removed = new Set()
  const added = new Set(props.right)

  for (const elem of props.left) {
    if (!added.has(elem)) {
      removed.add(elem)
    } else {
      added.delete(elem)
    }
  }

  const types = {
    added: { color: colors.success.darkest, prefix: '+' },
    removed: { color: colors.danger.base, prefix: '-' },
    unchanged: { color: colors.slate, prefix: '' },
  }

  const getType = (i: string) => {
    return added.has(i)
      ? types.added
      : removed.has(i)
      ? types.removed
      : types.unchanged
  }

  const allItems = Array.from(new Set([...props.left, ...props.right])).filter(
    Boolean
  )

  return (
    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
      {allItems.map(i => {
        const type = getType(i)

        return (
          <li key={i} style={{ color: type.color }}>
            {type.prefix} {i}
          </li>
        )
      })}
    </ul>
  )
}
