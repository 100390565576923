import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { Link } from 'react-router-dom'
import ReactJsonView from 'react-json-view'
import { Table, Button } from '@cimpress/react-components'
import * as uuid from 'uuid'
import moment from 'moment-timezone'
import { useLogisticsLocation } from '../../LocationContext'
import {
  getLocationCarrierAccounts,
  getSchedule,
  getPickups,
  getShipmentSummary,
} from '../../../common/proxy/sapi-proxy'
import { bearerToken } from '../../../common/auth'
import useWideContainer from '../../../common/components/useContainer'
import DiffList from './DiffList'

export default function LocationAdvancedOverview() {
  useWideContainer()
  const { logisticsLocation } = useLogisticsLocation()
  const [data, setData] = React.useState<any[]>()
  const [collapsed, setCollapsed] = React.useState(true)

  const toggleCollapsibles = () => {
    setCollapsed(!collapsed)
  }

  React.useEffect(() => {
    const pickupCalendarsById = Object.entries(
      logisticsLocation.pickupCalendars.mapping
    ).reduce((acc, curr) => {
      const [csKey, ref] = curr
      if (!acc[ref.id]) {
        return { ...acc, [ref.id]: [csKey] }
      }

      return { ...acc, [ref.id]: acc[ref.id].concat(csKey) }
    }, {} as Record<string, string[]>)

    const fetchData = async () => {
      const response = await getSapiCalendars(logisticsLocation.id)
      const newData = Object.keys(pickupCalendarsById).map(calendarId => {
        const emptyPlaceHolder = {
          lca: undefined,
          schedule: undefined,
          carrierServices: [],
          totalCount: 0,
        }

        return {
          ...(response[calendarId] || emptyPlaceHolder),
          calendarId,
          logisticsCarrierServices: pickupCalendarsById[calendarId],
        }
      })
      setData(newData)
    }

    fetchData()
  }, [logisticsLocation.pickupCalendars.mapping, logisticsLocation.id])

  const columns = [
    {
      Header: 'Calendar',
      width: 140,
      Cell: (row: any) => {
        return (
          <Link
            to={`/location/${logisticsLocation.id}/calendars?pickupCalendar=${row.original.calendarId}`}
          >
            {row.original.calendarId}
          </Link>
        )
      },
    },
    {
      Header: 'Carrier Services Logistics <> Sapi',
      width: 240,
      Cell: (row: any) => {
        return (
          <DiffList
            left={row.original.logisticsCarrierServices}
            right={row.original.carrierServices}
          />
        )
      },
    },
    {
      Header: 'Sapi LCA',
      Cell: (row: any) => {
        if (!row.original.lca) {
          return <span>NOT FOUND</span>
        }

        return (
          <ReactJsonView
            src={row.original.lca}
            collapsed={collapsed}
            enableClipboard={false}
            name={null}
            displayDataTypes={false}
          />
        )
      },
    },
    {
      Header: 'Schedule Id',
      width: 320,
      accessor: 'schedule',
    },
    {
      Header: 'Shipments Today',
      width: 120,
      accessor: 'totalCount',
    },
  ]

  return (
    <>
      <BreadcrumbsItem to={`/location/${logisticsLocation.id}`}>
        {logisticsLocation.name}
      </BreadcrumbsItem>
      <div className="row">
        <div className="col-xs-12">
          <h4>Advanced Overview for {logisticsLocation.name}</h4>
        </div>
      </div>
      <div className="row" style={{ marginTop: '20px' }}>
        <div className="col-xs-12">
          <div className="card card-minimal">
            <Button
              style={{ margin: '12px', width: '150px' }}
              onClick={toggleCollapsibles}
            >
              {collapsed ? 'EXPAND ALL' : 'COLLAPSE ALL'}
            </Button>
            <Table
              loading={data === undefined}
              columns={columns}
              data={data}
              sortable={false}
              resizable={false}
              showPagination={false}
              pageSize={data?.length || 10}
              noDataText="No records found"
            />
          </div>
        </div>
      </div>
    </>
  )
}

async function getSapiCalendars(locationId: string) {
  const now = moment()
  const startTime = now.clone().subtract(1, 'days').startOf('day')
  const endTime = now.endOf('day')
  const accessToken = bearerToken()
  const correlationId = uuid.v4()
  const carrierAccounts = await getLocationCarrierAccounts(
    accessToken,
    correlationId,
    locationId
  )

  const splittedBySequenceId = {}
  const addToSchedule = (sId: string, csKey: string, carrierAccount: any) => {
    if (!splittedBySequenceId[sId]) {
      splittedBySequenceId[sId] = {
        lca: carrierAccount,
        schedule: sId,
        carrierServices: [],
      }
    }

    splittedBySequenceId[sId].carrierServices.push(csKey)
  }

  for (const lca of carrierAccounts) {
    const scheduleId = lca.settings.schedule?.id
    const carrierServices = lca.settings.carrierServices

    for (const [csKey, conf] of Object.entries(carrierServices)) {
      const overrideSchedule = conf?.schedule?.id
      addToSchedule(overrideSchedule || scheduleId, csKey, lca)
    }
  }

  const schedulesWithCalendarId = await Promise.all(
    Object.values(splittedBySequenceId).map(async (data: any) => {
      if (data.schedule) {
        const [schedule, pickups] = await Promise.all([
          getSchedule(accessToken, correlationId, data.schedule),
          getPickups(
            accessToken,
            correlationId,
            data.schedule,
            startTime,
            endTime
          ),
        ])

        const summaries = await Promise.all(
          pickups.map(async pickup => {
            return getShipmentSummary(
              accessToken,
              correlationId,
              pickup.id,
              locationId
            )
          })
        )

        const totalCount = summaries
          .map(s => s.totalShipmentCount)
          .reduce((sum, curr) => sum + curr, 0)

        return { ...data, calendarId: schedule?.calendarId, totalCount }
      }

      return data
    })
  )

  return schedulesWithCalendarId
    .filter(e => e.calendarId)
    .reduce((acc, curr) => {
      const calendarId = curr.calendarId!

      return { ...acc, [calendarId]: curr }
    }, {})
}
