import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useLogisticsLocation } from '../../locations/LocationContext'

export default () => {
  const { logisticsLocation } = useLogisticsLocation()

  const network = logisticsLocation._links && logisticsLocation._links.network
  if (!network) {
    return null
  }
  const { t } = useTranslation()

  return (
    <>
      <h5>{t('locations.viewLocationPage.network')}</h5>
      <Link to={`../network/${network.id}`}>{network.name}</Link>
    </>
  )
}
