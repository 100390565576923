export type Steps =
  | 'accountDetails'
  | 'documents'
  | 'trackingIds'
  | 'pickups'
  | 'summary'

export interface SingleStepDefinition {
  type: 'step'
  key: Steps
  contents: string
  show: boolean
  indices?: Array<number | string>
}

interface StepGroupDefinition {
  type: 'group'
  key: Steps
  contents: string
  steps: SingleStepDefinition[]
}

export type StepDefinition = SingleStepDefinition | StepGroupDefinition

export function skipSteps(steps: StepDefinition[]): StepDefinition[] {
  const [group, ...rest] = steps

  const showGroup = (group as StepGroupDefinition).steps.some(s => s.show)

  if (showGroup) {
    const filteredGroup = {
      ...group,
      steps: (group as StepGroupDefinition).steps.filter(s => s.show),
    }

    return [filteredGroup, ...rest]
  }

  return rest
}

export function addIndices(
  step: StepDefinition,
  index: number,
  groupIndex?: number
): StepDefinition {
  if (step.type === 'group') {
    return {
      ...step,
      steps: step.steps.map((s, i) => addIndices(s, i, index)),
    } as StepGroupDefinition
  }

  if (groupIndex !== undefined) {
    const indices = [
      index === 0 && `${groupIndex}`,
      `${groupIndex}.${index}`,
    ].filter(Boolean)

    return {
      ...step,
      indices,
    } as StepDefinition
  }

  return { ...step, indices: [index] }
}

export function getInitialStep(steps: StepDefinition[]): Steps {
  const firstStep = steps[0]

  return firstStep.type === 'group' ? firstStep.steps[0].key : firstStep.key
}

function flattenSteps(steps: StepDefinition[]): SingleStepDefinition[] {
  const [first, ...rest] = steps

  if (first.type === 'group') {
    return [...first.steps, ...rest] as SingleStepDefinition[]
  }

  return [first, ...rest] as SingleStepDefinition[]
}

export function getStepMap(steps: StepDefinition[]) {
  const stepMap = new Map<
    string | number,
    { currentIndex: number; stepperIndex: string | number }
  >()

  const flatSteps = flattenSteps(steps)

  flatSteps.forEach((step, currentIndex) => {
    const mapEntry = {
      currentIndex,
      stepperIndex: step.indices!.slice().pop()!,
    }

    stepMap.set(step.key, mapEntry)
  })

  return stepMap
}

export function getSurroundingSteps(
  steps: StepDefinition[],
  currentStep: Steps
): {
  previous: SingleStepDefinition
  next: SingleStepDefinition
} {
  const flatSteps = flattenSteps(steps)

  const currentIndex = flatSteps.findIndex(({ key }) => key === currentStep)

  return {
    previous: flatSteps[currentIndex - 1] && flatSteps[currentIndex - 1],
    next: flatSteps[currentIndex + 1] && flatSteps[currentIndex + 1],
  }
}
