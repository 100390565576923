import { Select } from '@cimpress/react-components'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Option, reactSelectPortalId } from '../../../common/models'
import { EnhancedCaasProfile } from '../../../common/models-carriers'
import { filterAvailableCaasProfiles } from '../../../common/proxy/carrier-services-store'
import { CarrierSelection } from './carrierAccountData'

interface Props extends WithTranslation {
  caasProfiles: EnhancedCaasProfile[]
  data: CarrierSelection
  onChange(data: CarrierSelection): void
}

function CarrierSelectStep(props: Props) {
  const filteredCaasProfiles = filterAvailableCaasProfiles(props.caasProfiles)

  const carrierOptions: Option[] = filteredCaasProfiles
    .map(carrier => ({
      value: carrier.key,
      label: carrier.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  function getCarrierServicesOptions(carrierKey: string): Option[] {
    const carrierData = filteredCaasProfiles.find(
      carrier => carrier.key === carrierKey
    )
    if (!carrierData) {
      return []
    }

    return carrierData.carrierServices.map(service => ({
      value: service.key,
      label: service.name,
    }))
  }

  const onCarrierChange = (changeData: Option) => {
    if (!changeData) {
      props.onChange({})

      return
    }

    const carrierServicesOptions = changeData
      ? getCarrierServicesOptions(changeData.value as string)
      : []
    const autoSelectCarrierServices =
      carrierServicesOptions.length === 1
        ? carrierServicesOptions.map(cso => cso.value as string)
        : []

    props.onChange({
      carrier: changeData ? (changeData.value as string) : undefined,
      carrierServices: autoSelectCarrierServices,
    })
  }
  const onCarrierServicesChange = (changeData: Option[]) => {
    props.onChange({
      carrier: props.data.carrier,
      carrierServices: changeData.map(cd => cd.value as string),
    })
  }

  const carrierServiceOptions = props.data.carrier
    ? getCarrierServicesOptions(props.data.carrier)
    : []

  return (
    <>
      <Select
        label={props.t('carrierAccounts.addCarrierFlow.selectCarrierLabel')}
        value={carrierOptions.filter(o => o.value === props.data.carrier)}
        options={carrierOptions}
        isClearable={true}
        required={true}
        onChange={onCarrierChange}
        menuPortalTarget={document.getElementById(reactSelectPortalId)}
      />
      <Select
        label={props.t(
          'carrierAccounts.addCarrierFlow.selectCarrierServicesLabel'
        )}
        value={carrierServiceOptions.filter(o =>
          (props.data.carrierServices || []).includes(o.value)
        )}
        options={carrierServiceOptions}
        required={true}
        isDisabled={!props.data.carrier}
        onChange={onCarrierServicesChange}
        isMulti={true}
        menuPortalTarget={document.getElementById(reactSelectPortalId)}
      />
    </>
  )
}

export default withTranslation()(CarrierSelectStep)
