import { calendars } from '@cimpress-technology/logistics-configuration-client'
import { Button } from '@cimpress/react-components'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PickupScheduleEditorContainer } from '../../../calendars/components/weekly/legend/pickup-calendar-editor/PickupScheduleEditor'
import { PickupCalendar } from '../../../calendars/models'
import StepContainer from './StepContainer'
import { StepDefinition, Steps } from './steps'

interface Props extends WithTranslation {
  pickupCalendar: PickupCalendar
  previousStep: StepDefinition
  saveIncompleteAccount: () => Promise<void>

  onStepChanged: (step: Steps) => void
  onScheduleChange(schedule: calendars.models.PickupWeeklySchedule): void
}

function PickupsStep(props: Props) {
  const back = () => {
    props.onStepChanged(props.previousStep.key)
  }

  const forward = () => {
    props.onStepChanged('summary')
  }

  const footer = (
    <>
      {props.previousStep && (
        <Button type="default" onClick={back}>
          <i className="fa fa-angle-left" /> {props.previousStep.contents}
        </Button>
      )}{' '}
      <Link to="../carrier-accounts" className="btn btn-link">
        {props.t('common.cancel')}
      </Link>
      <span style={{ float: 'right' }}>
        <Button type="link" onClick={props.saveIncompleteAccount}>
          {props.t('carrierAccounts.addCarrierFlow.saveAndFinishLater')}
        </Button>{' '}
        <Button type="primary" onClick={forward}>
          {props.t('carrierAccounts.addCarrierFlow.definePickups')}{' '}
          <i className="fa fa-angle-right icon-on-right" />
        </Button>
      </span>
    </>
  )

  return (
    <StepContainer header="Define Pickups" footer={footer}>
      <h5>{props.t('common.pickupTime')}</h5>
      <PickupScheduleEditorContainer
        onChange={props.onScheduleChange}
        initialSchedules={props.pickupCalendar.weeklySchedules}
      />
    </StepContainer>
  )
}

export default withTranslation()(PickupsStep)
