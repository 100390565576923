import * as React from 'react'
import { getCountryName } from '../../i18n'
import { CountryFlag } from './CountryFlag'

export default function CountryHeader(props: { countryCode: string }) {
  return (
    <h5>
      <span className="h4">
        <CountryFlag countryCode={props.countryCode} />
      </span>{' '}
      {getCountryName(props.countryCode)}
    </h5>
  )
}
