import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'

import { Button } from '@cimpress/react-components'

import { Link } from 'react-router-dom'
import { EnhancedCaasProfile } from '../../../common/models-carriers'
import { PickupCalendar } from '../../../calendars/models'
import { Uploads } from '../../../common/models'
import CarrierAccountSummary from './CarrierAccountSummary'
import { CarrierAccountData } from './carrierAccountData'
import { Steps } from './steps'
import StepContainer from './StepContainer'

interface Props extends WithTranslation {
  profile: EnhancedCaasProfile
  onStepChanged: (step: Steps) => void
  carrierAccountData: CarrierAccountData
  uploads: Uploads[]
  pickupCalendar: PickupCalendar
  saveIncompleteAccount: () => Promise<void>
  saveCompleteAccount: () => Promise<void>
}
function SummaryStep(props: Props) {
  const back = () => {
    props.onStepChanged('pickups')
  }

  const footer = (
    <>
      <Button type="default" onClick={back}>
        <i className="fa fa-angle-left" /> Define Pickups
      </Button>{' '}
      <Link to="../carrier-accounts" className="btn btn-link">
        {props.t('common.cancel')}
      </Link>
      <span style={{ float: 'right' }}>
        <Button type="link" onClick={props.saveIncompleteAccount}>
          {props.t('carrierAccounts.addCarrierFlow.saveAndFinishLater')}
        </Button>{' '}
        <Button type="primary" onClick={props.saveCompleteAccount}>
          {props.t('carrierAccounts.addCarrierFlow.doneAddAccount')}
        </Button>
      </span>
    </>
  )

  return (
    <StepContainer header={props.t('common.summary')} footer={footer}>
      <CarrierAccountSummary
        carrierAccount={{
          name: props.carrierAccountData.carrierAccountName,
          carrierKey: props.carrierAccountData.carrier!,
          carrierServiceKeys: props.carrierAccountData.carrierServices!,
          autoManifest: true,
          accountDetails: props.carrierAccountData.accountDetails,
          uploads: props.uploads,
        }}
        profile={props.profile}
        sequenceDefinitionPairs={(
          props.carrierAccountData.sequenceDefinitions || []
        ).map((sd, index) => ({
          sequenceDefinition: sd,
          sequence: props.carrierAccountData.sequences![index],
        }))}
        schedules={props.pickupCalendar.weeklySchedules}
      />
    </StepContainer>
  )
}

export default withTranslation()(SummaryStep)
