import { Loudspeaker } from '@cimpress-technology/react-loudspeaker'
import { Helmet } from 'react-helmet'
import Header from '@cimpress-technology/react-platform-header'
import { getSettings } from '@cimpress-technology/react-platform-settings/lib/SettingsClient'
import { CssLoader } from '@cimpress/react-components'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { hot } from 'react-hot-loader/root'
import { Trans, useTranslation } from 'react-i18next'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { ThroughProvider } from 'react-through'
import * as logger from '../logger'
import * as fulfillmentLocationsStore from '../../common/proxy/fulfillment-locations-store'
import { supportedLanguages } from '../../i18n'
import auth, {
  ensureUserLogin,
  subscribeOnAuthorizationStateChanged,
} from '../auth'
import AuthorizationPage from '../AuthorizationPage'
import * as googleAnalytics from '../helpers/googleAnalytics'
import { hotjar } from '../hotjar'
import { reactSelectPortalId } from '../models'
import { isAdvancedUser as getIsAdvancedUser } from '../proxy/user-store'
import * as UserContext from '../UserContext'
import './App.css'
import './colors.css'
import { MainRouter } from './RouterWithBreadcrumbs'
import { SnackbarController } from './SnackbarController'
import Container from './Container'
import generateApplinks from './app-links'

function App() {
  const [t, i18n] = useTranslation()

  const [isAuthorized, setIsAuthorized] = React.useState(false)
  const [isAdvancedUser, setIsAdvancedUser] = React.useState(false)
  const [
    enableAdvancedUserToggle,
    setEnableAdvancedUserToggle,
  ] = React.useState(false)

  React.useEffect(() => {
    subscribeOnAuthorizationStateChanged(setIsAuthorized)
    const initialLoad = async () => {
      await ensureUserLogin()

      loadCache().catch(e => logger.logError('Error loading cache data', e))
      const [advancedUser, settings] = await Promise.all([
        getIsAdvancedUser(),
        getSettings(auth.getAccessToken(), supportedLanguages),
      ])
      setIsAdvancedUser(advancedUser)
      setEnableAdvancedUserToggle(advancedUser)

      i18n.changeLanguage(settings.global.language)
      googleAnalytics.initialize()
      const fullstory = (window as any).FS
      if (fullstory) {
        fullstory.identify(auth.getProfile().sub, {
          displayName: auth.getProfile().name,
          email: auth.getProfile().email,
        })
      }

      const isFromCimpress = auth.getProfile().email.endsWith('cimpress.com')

      if (!isFromCimpress) {
        hotjar(1680483, 6)
      }
    }
    initialLoad()
  }, [i18n])

  const loudspeakerConf = {
    language: i18n.language,
    title: t('loudspeaker.title'),
    channelIds: ['logistics-configuration'],
  }

  const appLinks = generateApplinks(
    isAdvancedUser,
    () => setIsAdvancedUser(!isAdvancedUser),
    enableAdvancedUserToggle,
    i18n,
    t
  )

  const context = { isAdvancedUser }

  return (
    <Container>
      <UserContext.Provider value={context}>
        <ThroughProvider>
          <CssLoader>
            <Router>
              <Helmet>
                <title>Logistics Configuration</title>
              </Helmet>
              <>
                <Loudspeaker
                  accessToken={auth.getAccessToken()}
                  language={loudspeakerConf.language}
                  title={loudspeakerConf.title}
                  channelIds={loudspeakerConf.channelIds}
                />
                <SnackbarController />
                <div style={{ zIndex: 999 }}>
                  <Header
                    responsive={true}
                    appTitle={
                      <Link to="/">
                        <Trans i18nKey="title" />
                      </Link>
                    }
                    profile={auth.getProfile()}
                    isLoggedIn={auth.isLoggedIn()}
                    onLogInClicked={handleLogIn}
                    onLogOutClicked={handleLogOut}
                    appLinks={appLinks}
                  />
                </div>
                {isAuthorized ? <MainRouter /> : <AuthorizationPage />}
              </>
            </Router>
          </CssLoader>
        </ThroughProvider>
        <ReactSelectMenuPortal />
      </UserContext.Provider>
    </Container>
  )
}

function ReactSelectMenuPortal() {
  const menu = (
    <div
      id={reactSelectPortalId}
      className={reactSelectPortalId}
      style={{
        zIndex: 9999,
        position: 'absolute',
        top: '0px',
      }}
      tabIndex={-1}
    />
  )

  return ReactDOM.createPortal(menu, document.body)
}

async function loadCache() {
  await fulfillmentLocationsStore.getAll()
}

function handleLogOut() {
  auth.logout('/')
}

function handleLogIn() {
  auth.login('/')
}

export default hot(App)
