import { Button, Modal } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IncompleteCarrierAccount } from '../../common/proxy/backend-proxy'

interface Props {
  incompleteCarrierAccount: IncompleteCarrierAccount
  blockUI: boolean
  stopDeleting(): void
  delete(): void
}

export function RemoveCarrierAccountConfirmationModal(props: Props) {
  const { t } = useTranslation()

  return (
    <Modal
      show={true}
      onRequestHide={props.stopDeleting}
      closeButton={true}
      closeOnOutsideClick={false}
      title={t('carrierAccounts.removeCarrierAccount')}
      footer={
        <>
          {!props.blockUI ? (
            <Button type="default" onClick={props.stopDeleting}>
              {t('common.cancel')}
            </Button>
          ) : null}
          <Button
            type="primary"
            disabled={props.blockUI}
            onClick={props.delete}
          >
            {!props.blockUI
              ? t('carrierAccounts.removeCarrierAccount')
              : t('carrierAccounts.removingCarrierAccount')}
          </Button>
        </>
      }
    >
      {t('carrierAccounts.removeCarrierAccountConfirmation')}{' '}
      {props.incompleteCarrierAccount.createCarrierAccount.name}?
    </Modal>
  )
}
