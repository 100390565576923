import * as React from 'react'
import LinkIcon from './LinkIcon'

interface Props {
  icon: React.ComponentClass<IconProps>
  url: string
  text: string
}

export default function NavigationExternalLink(props: Props) {
  return (
    <li key={props.text} className="list-group-item">
      <a href={props.url}>
        <LinkIcon icon={props.icon} />
        {props.text}
      </a>
    </li>
  )
}
