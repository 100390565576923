import { Select } from '@cimpress/react-components'
import { useField } from 'formik'
import moment from 'moment-timezone'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Option } from '../common/models'

const allTimezones = moment.tz.names().sort(timezoneComparator)

interface Props {
  name: string
}
export default function TimezonePicker(props: Props) {
  const [field, , helpers] = useField(props.name)
  const { t } = useTranslation()

  const options = allTimezones.map(timezone => ({
    label: `(GMT${moment.tz(timezone).format('Z')}) ${timezone.replace(
      new RegExp('_', 'g'),
      ' '
    )} `,
    value: timezone,
  }))

  const onChange = (data: Option) => {
    helpers.setValue(data ? data.value : '')
  }

  return (
    <Select
      label={t('locations.timezone')}
      name={props.name}
      value={options.filter(o => o.value === field.value)}
      options={options}
      onChange={onChange}
    />
  )
}

function timezoneComparator(tz1: string, tz2: string): number {
  const offsetDiff = moment.tz(tz1).utcOffset() - moment.tz(tz2).utcOffset()
  if (offsetDiff === 0) {
    return tz1.localeCompare(tz2)
  }

  return offsetDiff
}
