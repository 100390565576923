import { Select } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Preloader from '../../common/components/Preloader'
import { SnackbarController } from '../../common/components/SnackbarController'
import * as models from '../../common/models'
import { getAllLocations } from '../../common/proxy/locations-store'
import AwaitingModal from '../../calendars/components/AwaitingModal'
import { useNetwork } from '../NetworkContext'

interface Props {
  onClose: () => void
}

export default function AddLocationToNetworkModal(props: Props) {
  const { t } = useTranslation()
  const { network, updateNetwork } = useNetwork()

  const [loading, setLoading] = React.useState(true)
  const [locations, setLocations] = React.useState<models.Location[]>([])
  const [selectedLocationId, setSelectedLocationId] = React.useState<string>()

  React.useEffect(() => {
    const fetchData = async () => {
      const allLocations = await getAllLocations()
      setLocations(
        Array.from(allLocations.values()).filter(
          location =>
            location._links && !location._links.network && location.editable
        )
      )

      setLoading(false)
    }
    fetchData()
  }, [network])

  const onSave = async () => {
    try {
      await updateNetwork(n => n.logisticsLocationIds.push(selectedLocationId!))

      setLoading(false)
      SnackbarController.show(
        t('networks.addLocationToNetworkSuccess', {
          locationName: locations.find(
            location => location.id === selectedLocationId
          )!.name,
          networkName: network.apiNetwork.name,
        }),
        'success'
      )
      props.onClose()
    } catch (error) {
      SnackbarController.show(
        t(
          'locations.selectLocationPage.addLocationToNetworkModal.errorAddingToNetwork'
        ),
        'danger'
      )
      setLoading(false)
    }
  }

  const footer = loading
    ? []
    : [
        {
          handler: props.onClose,
          text: t('common.cancel'),
        },
        {
          type: 'primary',
          handler: onSave,
          text: t('locations.addLocation'),
          savingText: t('common.saving'),
          disabled: selectedLocationId === undefined,
        },
      ]

  const options = locations
    .map(location => ({ label: location.name, value: location.id }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const onChange = (data: models.Option) => {
    setSelectedLocationId(data.value)
  }

  const content = loading ? (
    <Preloader />
  ) : (
    <Select
      label={t('networks.selectALocation')}
      isSearchable={true}
      isClearable={false}
      name="location"
      value={options.filter(o => o.value === selectedLocationId)}
      options={options}
      onChange={onChange}
      menuPortalTarget={document.getElementById(models.reactSelectPortalId)}
    />
  )

  return (
    <AwaitingModal
      show={true}
      onRequestHide={props.onClose}
      closeButton={true}
      closeOnOutsideClick={false}
      title={t('networks.addLocationToNetwork', {
        networkName: network.apiNetwork.name,
      })}
      style={{ width: '526px' }}
      footer={footer}
    >
      {content}
    </AwaitingModal>
  )
}
