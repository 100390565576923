import React from 'react'
import { clone } from '../../../common/helpers/clone'
import { get, set } from '../../../common/helpers/deepBracketNotation'
import {
  FieldType,
  validateField,
  ValidationResult,
  ValidationStatus,
} from '../../../common/helpers/validation'
import { ConfigSpec, SpecEvent } from '../../../common/models-carriers'
import { getValidationErrors } from '../../carrierAccountValidation'
import CarrierConfiguration from './CarrierConfiguration'

export interface CarrierConfigurationContainerHandles {
  jumpToFirstError: () => void
}

interface Props {
  edit: boolean
  accountDetails: any
  configSpecs: any
  highlightAllErrors?: boolean

  onCarrierAccountChange?: (
    accountDetails: any,
    validationResult: ValidationResult[]
  ) => void
}

export const CarrierConfigurationContainer = React.forwardRef(
  CarrierConfigurationContainerWithRef
)

function CarrierConfigurationContainerWithRef(props: Props, forwardRef: any) {
  const refMap = new Map<string, React.RefObject<any>>()
  const [validatedFields, setValidatedFields] = React.useState(
    new Set<string>()
  )

  // Expose jumpToFirstError
  React.useImperativeHandle(forwardRef, () => ({
    jumpToFirstError: () => jumpToFirstError(),
  }))

  const jumpToFirstError = () => {
    const validationErrors = getValidationErrors(
      props.configSpecs,
      props.accountDetails
    )
    const firstError =
      validationErrors.length > 0 && validationErrors[0].fieldName
    const ref = firstError && refMap.get(firstError)
    if (ref && ref.current) {
      ref.current.focus()
    }
  }

  const handleCarrierAccountChange = (accountDetails: any) => {
    if (props.onCarrierAccountChange) {
      props.onCarrierAccountChange(
        accountDetails,
        getValidationErrors(props.configSpecs, accountDetails)
      )
    }
  }

  const onSpecChange = (event: SpecEvent) => {
    const value = event.value === '' ? undefined : event.value
    const accountDetails = clone(props.accountDetails)
    setValidatedFields(new Set([...Array.from(validatedFields), event.path]))
    set(accountDetails, event.path, value, '/')
    handleCarrierAccountChange(accountDetails)
  }

  const onValidate = (path: string, spec: ConfigSpec): ValidationResult => {
    if (props.highlightAllErrors || validatedFields.has(path)) {
      return validateField(
        {
          displayName: spec.name,
          name: path,
          required:
            spec.value.isRequired === undefined
              ? false
              : spec.value.isRequired!,
          type: FieldType.Text,
        },
        get(props.accountDetails, path, '/')
      )
    }

    return {
      fieldName: path,
      status: ValidationStatus.NotValidated,
      message: '',
    }
  }

  const onBlur = (path: string) => {
    setValidatedFields(new Set([...Array.from(validatedFields), path]))
  }

  return (
    <CarrierConfiguration
      config={props.accountDetails}
      configSpecs={props.configSpecs}
      onChange={onSpecChange}
      edit={props.edit}
      onValidate={onValidate}
      onBlur={onBlur}
      refMap={refMap}
    />
  )
}
