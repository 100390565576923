import { Tag } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Option } from '../../common/models'

interface Props {
  selectedCapabilities: string[]
  allCapabilities: Option[]
  onAddCapability: (e: Option) => void
  onRemoveCapability: (value: string) => void
}

export default function CarrierServicesCapabilities(props: Props) {
  const { t } = useTranslation()
  const [value, setValue] = React.useState<Option>()

  const selected = props.selectedCapabilities.map(
    sc => props.allCapabilities.find(c => c.value === sc)!
  )

  const notSelected = props.allCapabilities.filter(
    c => props.selectedCapabilities.indexOf(c.value as string) === -1
  )

  let selectedCapabilities
  if (selected.length === 0) {
    selectedCapabilities = <Tag value={t('carrierExplorer.anything')} />
  } else {
    selectedCapabilities = selected.map(sc => (
      <Tag
        key={sc.value}
        value={sc.label}
        removable={true}
        onRemoveClick={props.onRemoveCapability}
      />
    ))
  }

  const onChange = (e: Option) => {
    props.onAddCapability(e)
    setValue(undefined)
  }

  return (
    <div className="capabilities">
      {t('carrierExplorer.andAllowCapabilities')}
      {selectedCapabilities}
      <div
        style={{
          display: 'inline-block',
          minWidth: '175px',
          paddingLeft: '8px',
        }}
      >
        <Select
          className="capabilities-select"
          name="capabilities-select"
          options={notSelected}
          onChange={onChange}
          value={notSelected.filter(o => o === value)}
          isClearable={false}
          placeholder={t('carrierExplorer.selectCapabilities')}
          style={{ border: 'none' }}
          isSearchable={false}
          noResultsText="All capabilities are selected"
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
    </div>
  )
}
