import React from 'react'
import { useAppContext } from '../AppContext'

export default function useWideContainer() {
  const context = useAppContext()

  React.useEffect(() => {
    context.setContainerType('main-container')

    return () => {
      context.setContainerType('container')
    }
  })
}
