import {
  calendars,
  carrierAccounts,
} from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { PickupScheduleView } from '../../../calendars/components/weekly/legend/pickup-calendar-editor/PickupScheduleView'
import {
  EnhancedCaasProfile,
  SequenceDefinitionPair,
} from '../../../common/models-carriers'
import { getDocumentTypeKeys } from '../../../common/proxy/carrier-services-store'
import {
  showAsTrackingSequence,
  showAsUploadSequence,
} from '../sequences/helpers'
import { SequenceView } from '../sequences/SequenceView'
import { CarrierConfigurationContainer } from '../spec-components/CarrierConfigurationContainer'
import DocumentUpload from '../uploads/DocumentUpload'
import style from '../ViewCarrierAccount.module.css'

interface Props extends WithTranslation {
  carrierAccount: carrierAccounts.models.CreateCarrierAccount
  profile: EnhancedCaasProfile
  sequenceDefinitionPairs: SequenceDefinitionPair[]
  schedules?: calendars.models.PickupWeeklySchedule[]
}

function CarrierAccountSummary(props: Props) {
  const trackingSequences = props.sequenceDefinitionPairs.filter(pair =>
    showAsTrackingSequence(pair.sequence)
  )
  const trackingSequencePart =
    trackingSequences.length === 0 ? undefined : (
      <div className="row" style={{ marginBottom: '32px' }}>
        <div className="col-xs-12">
          <h4>{props.t('common.trackingRanges')}</h4>
          {trackingSequences.map(pair => (
            <SequenceView
              key={pair.sequence.key}
              sequenceDefinitionPair={pair}
            />
          ))}
        </div>
      </div>
    )

  return (
    <>
      <div className="row" style={{ marginBottom: '32px' }}>
        <div className="col-xs-12">
          <h4>{props.t('common.carrierServices')}</h4>
          <table className={'table ' + style.carrierServicesTable}>
            <tbody>
              {props.carrierAccount.carrierServiceKeys.map(key => {
                const carrierService = props.profile.carrierServices.find(
                  cs => cs.key === key
                )

                return (
                  <tr key={key}>
                    <td>{carrierService ? carrierService.name : key}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row" style={{ marginBottom: '32px' }}>
        <div className="col-xs-12">
          <h4>{props.t('common.accountDetails')}</h4>
          <CarrierConfigurationContainer
            edit={false}
            accountDetails={props.carrierAccount.accountDetails}
            configSpecs={props.profile.accountConfigSpec!}
          />
        </div>
      </div>
      <div className="row" style={{ marginBottom: '32px' }}>
        <div className="col-xs-12">
          <h4>{props.t('common.documentUploads')}</h4>
          <DocumentUpload
            caasProfileKey={props.carrierAccount.carrierKey}
            edit={false}
            uploads={props.carrierAccount.uploads}
            documentTypeKeys={getDocumentTypeKeys(props.profile)}
          />
          {props.sequenceDefinitionPairs
            .filter(pair => showAsUploadSequence(pair.sequence))
            .map(pair => (
              <SequenceView
                key={pair.sequence.key}
                sequenceDefinitionPair={pair}
              />
            ))}
        </div>
      </div>
      {trackingSequencePart}
      <div className="row" style={{ marginBottom: '32px' }}>
        <div className="col-xs-12">
          <h4>{props.t('common.pickupSchedule')}</h4>
          <PickupScheduleView schedules={props.schedules} />
        </div>
      </div>
    </>
  )
}

export default withTranslation()(CarrierAccountSummary)
