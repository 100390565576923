import { TextField } from '@cimpress/react-components'
import * as React from 'react'
import { ValidationContainer } from '../../../common/components/ValidationContainer'
import { ValidationStatus } from '../../../common/helpers/validation'
import { SpecProps } from './specProps'

interface Props extends SpecProps {
  value: number
}
export default function NumberSpecComponent(props: Props) {
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    props.onChange!({
      path: props.spec.key,
      value: e.currentTarget.value === '' ? '' : Number(e.currentTarget.value),
    })
  }

  const onBlur = (_: any) => {
    props.onBlur!(props.spec.key)
  }
  const validationResult = props.onValidate!(props.spec.key, props.spec)

  return (
    <ValidationContainer validationResult={validationResult}>
      <TextField
        value={props.value}
        label={props.spec.name}
        onChange={handleChange}
        type={'number'}
        helpText={props.spec.description}
        required={props.spec.value.isRequired}
        onBlur={onBlur}
        bsStyle={
          validationResult.status === ValidationStatus.Invalid
            ? 'error'
            : undefined
        }
        ref={props.specRef}
      />
    </ValidationContainer>
  )
}
