import * as React from 'react'
import { Network, LightLocation } from '../../../common/models'

export interface Context {
  networks: Network[]

  locations: LightLocation[]

  reloadLocations: () => void
}

export const Context = React.createContext<Context>({} as Context)

export const Provider = Context.Provider
export const Consumer = Context.Consumer

export default function useLocationsContext() {
  return React.useContext(Context)
}
