import Icon from '@cimpress-technology/react-streamline-icons/lib/IconEmailSend'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export default function ContactUsPage() {
  const { t } = useTranslation()

  return (
    <main className="App-content container">
      <div className="row">
        <div className="card">
          <div className="card-block">
            <div className="col-md-6 col-md-offset-3">
              <h1 style={{ textAlign: 'center' }}>{t('contactUs')}</h1>
              <div style={{ textAlign: 'center' }}>
                <Icon style={{ marginBottom: '-1px', marginRight: '4px' }} />
                {t('emailSupport')}
                {'\n '}
                <a href="mailto:logisticssupport@cimpress.com">
                  logisticssupport@cimpress.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
