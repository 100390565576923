import * as coam from '@cimpress-technology/coam-sapidus'
import * as uuid from 'uuid'
import auth from '../auth'

const logisticsTechnologyCoamGroupId = '6525'

export async function isAdvancedUser() {
  try {
    const ltCoamGroup = await coam.group.getCoamGroupDetails(
      logisticsTechnologyCoamGroupId,
      auth.getAccessToken(),
      uuid.v4()
    )

    return ltCoamGroup.members.some(
      member => member.principal === auth.getProfile().sub
    )
  } catch (err) {
    return false
  }
}
