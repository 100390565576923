import { Button } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { SequenceDefinition } from '../../../common/models'
import { EnhancedCaasProfile, Sequence } from '../../../common/models-carriers'
import * as sequenceHelpers from '../sequences/helpers'
import MultipleSequencesEditor from '../sequences/MultipleSequencesEditor'
import { CarrierAccountData } from './carrierAccountData'
import StepContainer from './StepContainer'
import { StepDefinition, Steps } from './steps'

export function showTrackingIdsStep(sequences?: Sequence[]) {
  return (
    sequences !== undefined &&
    sequences.filter(s => s && sequenceHelpers.showAsTrackingSequence(s))
      .length > 0
  )
}

interface Props {
  profile: EnhancedCaasProfile
  carrierAccountData: CarrierAccountData
  previousStep: StepDefinition

  onSequenceDefinitionChange: (
    sequenceDefinitions: SequenceDefinition[]
  ) => void

  saveIncompleteAccount: () => Promise<void>

  onStepChanged: (step: Steps) => void
}

export default function TrackingIdsStep(props: Props) {
  const { t } = useTranslation()
  const [areValid, setAreValid] = React.useState<boolean[]>([])
  const sequences = props.carrierAccountData.sequences

  const back = () => {
    props.onStepChanged(props.previousStep.key)
  }

  const forward = () => {
    props.onStepChanged('pickups')
  }

  const onSequenceDefinitionChange = (
    sequenceDefinitions: SequenceDefinition[],
    areSequencesValid: boolean[]
  ) => {
    setAreValid(areSequencesValid)
    props.onSequenceDefinitionChange(sequenceDefinitions)
  }

  const footer = (
    <>
      {props.previousStep && (
        <Button type="default" onClick={back}>
          <i className="fa fa-angle-left" /> {props.previousStep.contents}
        </Button>
      )}{' '}
      <Link to="../carrier-accounts" className="btn btn-link">
        {t('common.cancel')}
      </Link>
      <span style={{ float: 'right' }}>
        <Button type="link" onClick={props.saveIncompleteAccount}>
          {t('carrierAccounts.addCarrierFlow.saveAndFinishLater')}
        </Button>{' '}
        <Button
          type="primary"
          onClick={forward}
          disabled={areValid.some(v => v === false)}
        >
          {t('carrierAccounts.addCarrierFlow.configureTrackingRanges')}
          <i className="fa fa-angle-right icon-on-right" />
        </Button>
      </span>
    </>
  )

  return (
    <StepContainer
      header={t('carrierAccounts.addCarrierFlow.trackingIds')}
      footer={footer}
    >
      <MultipleSequencesEditor
        sequenceTypeToShow={'tracking'}
        sequences={sequences!}
        onSequenceDefinitionChange={onSequenceDefinitionChange}
        sequenceDefinitions={props.carrierAccountData.sequenceDefinitions!}
        areValid={areValid}
      />
    </StepContainer>
  )
}
