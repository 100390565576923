import axios from 'axios'
import { Fulfiller } from '../models'

const F_URL = 'https://fulfilleridentity.trdlnk.cimpress.io/v1/fulfillers'

export const getFulfillers = async (
  authorization: string,
  disableCaching?: boolean
): Promise<Map<string, Fulfiller>> => {
  const response = (
    await axios.get(F_URL, {
      headers: {
        authorization,
        'Cache-Control': 'no-cache',
        'x-cache-id': disableCaching ? Date.now() : undefined,
      },
    })
  ).data

  return response.reduce((fMap: Map<string, Fulfiller>, f: Fulfiller) => {
    if (!fMap.has(f.fulfillerId)) {
      fMap.set(f.fulfillerId, { fulfillerId: f.fulfillerId, name: f.name })
    }

    return fMap
  }, new Map<string, Fulfiller>())
}
