import { get } from '../../../common/helpers/deepBracketNotation'
import {
  FieldType,
  ValidatedField,
  validateField,
  ValidationResult,
  ValidationStatus,
} from '../../../common/helpers/validation'
import { SequenceDefinition } from '../../../common/models'

export const sequenceValidatedFields: ValidatedField[] = [
  {
    i18nKey: 'validation.sequences.start',
    name: 'activeRange.start',
    required: true,
    type: FieldType.Text,
  },
  {
    i18nKey: 'validation.sequences.current',
    name: 'current',
    required: true,
    type: FieldType.Text,
  },
  {
    i18nKey: 'validation.sequences.end',
    name: 'activeRange.end',
    required: true,
    type: FieldType.Text,
  },
]

export function validateAllFields(
  sequenceDefinition: SequenceDefinition
): ValidationResult[] {
  return sequenceValidatedFields.map(field =>
    validateField(field, get(sequenceDefinition, field.name))
  )
}

export function isSequenceValid(sequenceDefinition: SequenceDefinition) {
  const validationResults = validateAllFields(sequenceDefinition)

  return (
    validationResults.filter(vr => vr.status === ValidationStatus.Invalid)
      .length === 0
  )
}
