import * as React from 'react'
import styles from './CarrierConfiguration.module.css'
import { SpecProps } from './specProps'

interface Props extends SpecProps {
  obj: any
  edit: boolean
  level: number
  refMap: Map<string, React.RefObject<any>>

  children?: React.ReactNode
}

export default function ObjectSpecComponent(props: Props) {
  if (props.spec.name === '') {
    return <>{props.children}</>
  }

  const title = props.spec.description
    ? `${props.spec.name} (${props.spec.description})`
    : props.spec.name

  const CustomH: any = `h${Math.min(props.level + 3, 6)}`

  return (
    <div className={styles.objectSpec}>
      <CustomH>{title}</CustomH>
      {props.children}
    </div>
  )
}
