import { shapes } from '@cimpress/react-components'
import * as React from 'react'

export default function Preloader(props: { small?: boolean }) {
  return (
    <div style={{ textAlign: 'center' }}>
      <shapes.Spinner size={props.small ? 'small' : 'large'} />
    </div>
  )
}
