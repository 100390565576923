const labels = {
  common: {
    PATCH: 'Changed',
    POST: 'Created',
    DELETE: 'Deleted',
  },
  'User Management': {
    POST: 'User added',
    'POST-VIA-NETWORK': 'User added vai network',
    DELETE: 'User removed',
  },
}
export default function getActionLabel(resourceType: string, action: string) {
  return labels[resourceType]?.[action] ?? labels.common?.[action] ?? action
}
