import * as React from 'react'
import { useTranslation } from 'react-i18next'
import CountryHeader from '../../../common/components/CountryHeader'
import * as models from '../../../common/models'
import { getCountryName, getCurrentLang } from '../../../i18n'
import AdvancedRowGroupByCountriesComponent from './AdvancedRowGroupByCountriesComponent'

interface Props {
  locations: models.Location[]
  searchQuery: string
}

export default function LocationsList(props: Props) {
  const { t } = useTranslation()

  if (props.locations.length === 0) {
    return <h5>{t('locations.selectLocationPage.noLocationFound')}</h5>
  }

  const countryCodes = groupBy(props.locations)

  return (
    <>
      {countryCodes.map(countryCode => (
        <div key={countryCode} style={{ marginBottom: '20px' }}>
          <CountryHeader countryCode={countryCode} />
          <div className="panel">
            <table className="table table-hover" style={{ marginBottom: 0 }}>
              <tbody>
                {props.locations
                  .filter(l => l.address.countryCode === countryCode)
                  .sort((l1, l2) => l1.name.localeCompare(l2.name, 'en'))
                  .map(location => (
                    <AdvancedRowGroupByCountriesComponent
                      key={location.id}
                      location={location}
                      searchQuery={props.searchQuery}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </>
  )
}

function groupBy(locations: models.Location[]): string[] {
  return Array.from(new Set(locations.map(l => l.address.countryCode))).sort(
    (a, b) => {
      return getCountryName(a).localeCompare(
        getCountryName(b),
        getCurrentLang()
      )
    }
  )
}
