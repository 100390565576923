import * as React from 'react'
import { v4 } from 'uuid'
import { useSocket, SocketIOProvider } from 'use-socketio'
import Fragment from './Fragment'
import Preloader from './Preloader'

const LOCALHOST_BASE = 'http://localhost:3001'
const FRAGMENT = 'Fragment.js'

export default function DevelopmentFragment() {
  return React.useMemo(
    () => (
      <SocketIOProvider url={LOCALHOST_BASE}>
        <ReloadingFragment />
      </SocketIOProvider>
    ),
    []
  )
}

function ReloadingFragment() {
  const [random, setRandom] = React.useState(v4())
  useSocket('end', msg => {
    setRandom(v4())
  })

  const handleError = (e: Error) => {
    // eslint-disable-next-line no-console
    console.log(e)

    return <div />
  }

  return (
    <Fragment
      src={`${LOCALHOST_BASE}/${FRAGMENT}?uuid=${random}`}
      fallback={<Preloader />}
      error={handleError}
    />
  )
}
