import * as client from '@cimpress-technology/logistics-configuration-client'
export interface Config {
  logisticsEnvironment: string
  configuration: () => string
  backend: string
  shippingCalculator: string
  qcm: string
  shippingOperations: string
  smc: string
  tracking: string
  truckBasedManifestingLocations: string[]
}

const defaultConfig: Config = {
  logisticsEnvironment: 'production',
  configuration: () => getBaseUrl(),
  backend: 'https://ui-backend.logistics.cimpress.io',
  shippingCalculator: 'https://shipping-calculator.logistics.cimpress.io',
  qcm: 'https://qcm.qp.cimpress.io',
  shippingOperations: 'https://shipping.cimpress.io',
  smc: 'https://my.shipping.cimpress.io/',
  tracking: 'https://track.cimpress.io',
  truckBasedManifestingLocations: ['venlo', 'windsor'],
}

const localServiceConfig: Config = {
  logisticsEnvironment: 'local',
  configuration: () => 'https://configuration.logistics.cimpress.io',
  backend: 'https://ui-backend.logistics.cimpress.io',
  shippingCalculator: 'https://shipping-calculator.logistics.cimpress.io',
  qcm: 'https://qcm.qp.cimpress.io',
  shippingOperations: 'https://shipping.cimpress.io',
  smc: 'https://my.shipping.cimpress.io/',
  tracking: 'https://track.cimpress.io',
  truckBasedManifestingLocations: ['venlo', 'windsor'],
}

const stagingConfig: Config = {
  logisticsEnvironment: 'staging',
  configuration: () => getBaseUrl(),
  backend: 'https://staging.ui-backend.logistics.cimpress.io',
  shippingCalculator: 'https://stg.shipping-calculator.logistics.cimpress.io',
  qcm: 'https://qcm-stg.qp.cimpress.io',
  shippingOperations: 'https://stg.shipping-operations.shipping.cimpress.io',
  smc: 'https://stg.my.shipping.cimpress.io/',
  tracking: 'https://track.cimpress.io',
  truckBasedManifestingLocations: ['venlo-demo', 'windsor-demo'],
}

const e2eConfig: Config = {
  logisticsEnvironment: 'e2e',
  configuration: () => {
    return 'http://e2e-app:5000'
  },
  backend: 'http://e2e-app:5000',
  shippingCalculator: 'http://e2e-app:5000',
  qcm: 'http://e2e-app:5000',
  shippingOperations: 'https://shipping.cimpress.io',
  smc: 'https://my.shipping.cimpress.io/',
  tracking: 'https://track.cimpress.io',
  truckBasedManifestingLocations: ['venlo', 'windsor'],
}

const fragmentsConfig: Config = {
  ...defaultConfig,
  logisticsEnvironment: 'fragments',
}

function getBaseUrl() {
  return window.location.origin
}

function initConfig(): Config {
  switch (process.env.REACT_APP_CONFIG) {
    case 'e2e':
      return e2eConfig
    case 'local':
      return localServiceConfig
    case 'staging':
      return stagingConfig
    case 'fragments':
      return fragmentsConfig
    default:
      return defaultConfig
  }
}

function setupClientForEnv(clientConfig: Config) {
  client.setBaseUrl(clientConfig.configuration())
}

const initialConfig = initConfig()
setupClientForEnv(initialConfig)

export const config = initialConfig
