import { RadioGroup } from '@cimpress/react-components'
import * as React from 'react'
import { useField } from 'formik'

interface Props {
  name: string
  children?: React.ReactNode
}

export default function FormikRadioGroup(props: Props) {
  const [field, , helpers] = useField(props.name)
  const onChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    helpers.setValue(value)
  }

  return (
    <RadioGroup
      onChange={onChange}
      defaultSelected={field.value}
      name={props.name}
    >
      {props.children}
    </RadioGroup>
  )
}
