import * as React from 'react'
import usePrevious from './usePrevious'

export default function useCallbackOnValueChange(
  callback: () => void,
  value: any
) {
  const previousValue = usePrevious(value)

  React.useEffect(() => {
    if (!previousValue && value) {
      callback()
    }
  }, [value, callback, previousValue])
}
