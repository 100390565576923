import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import { colors, Select, shapes, TextField } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ValidationContainer } from '../../../common/components/ValidationContainer'
import { get, set } from '../../../common/helpers/deepBracketNotation'
import {
  toBSStyle,
  validateField,
  ValidationResult,
  ValidationStatus,
} from '../../../common/helpers/validation'
import useCallbackOnValueChange from '../../../common/hooks/useCallbackOnValueChange'
import { Option } from '../../../common/models'
import EncryptedStringField from './EncryptedStringField'
import { CommonProps, getUploadLabel } from './uploadEditorFactory'
import {
  ftpUploadValidatedFields,
  validateAllFields,
} from './uploadEditorValidation'

interface Props extends CommonProps {
  upload: carrierAccounts.models.FtpUpload
}
export default function FtpUploadEditor(props: Props) {
  const [upload, setUpload] = React.useState(props.upload)
  const { t } = useTranslation()

  const propertyChange = (path: string, parser?: (value: string) => any) => (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const value = parser
      ? parser(event.currentTarget.value)
      : event.currentTarget.value

    set(upload, path, value)
    setUpload({ ...upload })
    setValidatedFields(new Set([...Array.from(validatedFields), path]))
    props.onChange(upload, isUploadValid())
  }

  const onDocumentTypeChange = (changeData: Option[]) => {
    set(
      upload,
      'documentTypes',
      changeData.map(cd => cd.value)
    )
    setUpload({ ...upload })
    props.onChange(upload, isUploadValid())
  }

  const onAuthenticationMethodChange = (changeData: Option) => {
    set(upload, 'authenticationMethod', changeData.value)
    setUpload({ ...upload })
    props.onChange(upload, isUploadValid())
  }

  const name = getUploadLabel(props.upload.uploadType)

  const [validatedFields, setValidatedFields] = React.useState(
    new Set<string>()
  )

  useCallbackOnValueChange(() => {
    setValidatedFields(
      new Set(ftpUploadValidatedFields.map(field => field.name))
    )
    props.onChange(upload, isUploadValid())
  }, props.validateAll)

  const doValidateField = (fieldName: string): ValidationResult => {
    if (!validatedFields.has(fieldName)) {
      return {
        fieldName,
        status: ValidationStatus.NotValidated,
        message: '',
      }
    }
    const validationField = ftpUploadValidatedFields.find(
      field => field.name === fieldName
    )

    return validateField(validationField!, get(upload, fieldName))
  }

  const isUploadValid = (): boolean => {
    const validationResults = validateAllFields(
      ftpUploadValidatedFields,
      upload
    )

    return (
      upload.documentTypes.length > 0 &&
      validationResults.filter(vr => vr.status === ValidationStatus.Invalid)
        .length === 0
    )
  }

  return (
    <>
      <h5>
        {t('carrierAccounts.uploads.uploadTitle', { name })}{' '}
        {props.onRemove && (
          <button
            onClick={props.onRemove}
            className="btn"
            name="remove-upload"
            style={{ padding: 0 }}
          >
            <shapes.hamburger.Close
              width="18px"
              color={colors.ocean.base}
              cropped={true}
            />
          </button>
        )}
      </h5>
      <Select
        label={t('carrierAccounts.uploads.selectDocumentTypes')}
        value={props.documentTypeList.filter(o =>
          upload.documentTypes.includes(o.value)
        )}
        options={props.documentTypeList}
        required={true}
        onChange={onDocumentTypeChange}
        isMulti={true}
      />
      <ValidationContainer validationResult={doValidateField('host')}>
        <TextField
          value={upload.host}
          label={t('carrierAccounts.uploads.hostName')}
          onChange={propertyChange('host')}
          required={true}
          bsStyle={toBSStyle(doValidateField('host'))}
        />
      </ValidationContainer>

      <ValidationContainer validationResult={doValidateField('port')}>
        <TextField
          value={upload.port}
          label={t('carrierAccounts.uploads.hostPort')}
          onChange={propertyChange('port', parseInt)}
          required={true}
          type="number"
          bsStyle={toBSStyle(doValidateField('port'))}
        />
      </ValidationContainer>

      <ValidationContainer validationResult={doValidateField('username')}>
        <TextField
          value={upload.username}
          label={t('carrierAccounts.uploads.username')}
          onChange={propertyChange('username')}
          required={true}
          bsStyle={toBSStyle(doValidateField('username'))}
        />
      </ValidationContainer>

      <ValidationContainer validationResult={doValidateField('password')}>
        <EncryptedStringField
          label={t('carrierAccounts.uploads.password')}
          password={upload.password}
          onChange={propertyChange('password')}
          required={true}
          bsStyle={toBSStyle(doValidateField('password'))}
        />
      </ValidationContainer>

      <ValidationContainer validationResult={doValidateField('directory')}>
        <TextField
          value={upload.directory}
          label={t('carrierAccounts.uploads.directory')}
          onChange={propertyChange('directory')}
          required={true}
          bsStyle={toBSStyle(doValidateField('directory'))}
        />
      </ValidationContainer>

      <TextField
        value={upload.afterUploadMoveToDirectory}
        label={t('carrierAccounts.uploads.afterUploadMoveToDirectory')}
        onChange={propertyChange('afterUploadMoveToDirectory')}
      />
      {upload.uploadType === 'sftp' && (
        <Select
          label={t('carrierAccounts.uploads.selectAuthenticationMethod')}
          value={authenticationMethods.filter(
            o => o.value === upload.authenticationMethod
          )}
          options={authenticationMethods}
          onChange={onAuthenticationMethodChange}
        />
      )}
    </>
  )
}

const authenticationMethods = [
  { value: 'password', label: 'Password' },
  { value: 'key', label: 'Key' },
]
