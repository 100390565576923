export const SHIPMENTS_PER_CARRIER_SERVICE =
  'f6f9134b-f887-4473-a08b-ee3c44d6879c'
export const SHIPMENTS_PER_FULFILLMENT_LOCATION =
  '54237852-0990-4270-8e12-85095592deb8'
export const OTC_PER_LOCATION = '1661384c-983a-4bee-8c8c-769d265eb171'
export const OTC_PER_NETWORK = 'd1c33db2-a7dd-4b90-99e7-e2f1001ad2cc'
export const OTS_PER_LOCATION = '3ac5b1c5-b29b-4f72-8532-a5a499a93190'
export const OTS_PER_NETWORK = '577985a7-7765-4ec1-9b03-273353bdeb75'

export const OTC_PER_CARRIER_SERVICE_NETWORK =
  '072b5e3c-081a-412f-9a14-fbecd9014112'

export const DASHBOARD_SHIPPING_OPERATIONS =
  'db4d32da-154f-4523-99b4-1696166b544b'
export const DASHBOARD_CARRIER_PERFORMANCE =
  '77c9dd50-dabd-4740-99f0-e093ce3290c3'

export type Reports =
  | typeof SHIPMENTS_PER_CARRIER_SERVICE
  | typeof SHIPMENTS_PER_FULFILLMENT_LOCATION
  | typeof OTC_PER_LOCATION
  | typeof OTC_PER_NETWORK
  | typeof OTS_PER_LOCATION
  | typeof OTS_PER_NETWORK
  | typeof OTC_PER_CARRIER_SERVICE_NETWORK

export interface ShipmentsQuery {
  limit?: number
  networkId?: string
}

export interface ShipmentsPerCarrierServiceResponse {
  'logistics_network.networkid': string
  carrier_service_name: string
  'logistics_recs.carrier_service_key': string
  shipments: number
}

export interface ShipmentsPerCarrierService {
  carrierServiceName: string
  carrierServiceKey: string
  shipments: number
}
export interface CarrierServiceOtc {
  otc: string
  carrierKey: string
}

export interface CarrierServiceOtcResponse extends NetworkOtcResponse {
  'tis_otc.carriername': string
  'tis_otc.carrierservicekey': string
}

export interface LocationOtcResponse extends NetworkOtcResponse {
  'tis_otc.fulfillmentlocationid': string
  'tis_otc.logisticslocationid': string
}

export interface NetworkOtcResponse {
  'tis_otc.networkid': string
  'tis_otc.OTC_ACHIEVED_1': number
  'tis_otc.OTC_ACHIEVED_2': number
  otc: number
}

export interface NetworkOtsResponse {
  'ots.networkid': string
  ots: number
}

export interface LocationOtsResponse extends NetworkOtsResponse {
  'ots.logistics_location_id': string
}

export interface ShipmentsPerFulfillmentLocationResponse {
  'logistics_network_tier1.networkid': string | null
  'logistics_network_tier1.logisticslocationid': string | null
  'mcp_logistics_sapi_shipments.fulfillmentLocationId': string
  shipments: number
}
