import axios from 'axios'
import { logError } from '../logger'
import { ApiSequenceDefinition } from '../models'

const SEQUENCES_URL = 'https://sequences.logistics.cimpress.io/api/v3'

export const createSequence = async (
  sequence: ApiSequenceDefinition,
  accessToken: string
): Promise<string> => {
  try {
    const response = (
      await axios.post(SEQUENCES_URL + '/sequences', sequence, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).data

    if (!response.sequenceId) {
      throw new Error('Response does not contain sequenceId')
    }

    return response.sequenceId
  } catch (e) {
    logError(`Error when creating sequence ${JSON.stringify(sequence)}`, e)
    throw e
  }
}

export const deleteSequence = async (
  sequenceId: string,
  accessToken: string
): Promise<void> => {
  try {
    await axios.delete(SEQUENCES_URL + `/sequences/${sequenceId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  } catch (e) {
    logError(`Error when deleting sequence ${sequenceId}`, e)
    throw e
  }
}

export const getSequenceDirect = async (
  url: string,
  accessToken: string
): Promise<ApiSequenceDefinition> => {
  try {
    return (
      await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).data
  } catch (e) {
    logError('Error when getting location', e)
    throw e
  }
}

export const patchSequence = async (
  sequenceId: string,
  body: any,
  accessToken: string
): Promise<void> => {
  try {
    await axios.patch(SEQUENCES_URL + `/sequences/${sequenceId}`, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return
  } catch (e) {
    logError(`Error when creating sequence ${JSON.stringify(body)}`, e)
    throw e
  }
}
