import * as React from 'react'
import * as ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router'
import auth from '../auth'

const cimpressGlobalTracker = 'CimpressGlobal'

export function initialize() {
  ReactGA.initialize([
    {
      trackingId: 'UA-123333109-1',
      gaOptions: {
        userId: auth.getProfile().sub,
      },
    },
    {
      trackingId: 'UA-140259858-1',
      gaOptions: {
        name: cimpressGlobalTracker,
        userId: auth.getProfile().sub,
      },
    },
  ])
}

export function pageView(page: string) {
  ReactGA.pageview(page, [cimpressGlobalTracker])
}

export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  options: ReactGA.FieldsObject = {}
) => {
  const trackPage = (page: string) => {
    ReactGA.set({ page, ...options })
    ReactGA.pageview(page)
  }

  return (props: P) => {
    React.useEffect(() => {
      trackPage(props.location.pathname)
    }, [props.location.pathname])

    return <WrappedComponent {...props} />
  }
}
