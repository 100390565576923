import React from 'react'
import { ContainerType, Provider } from '../AppContext'

export default function Container(props: { children: any }) {
  const [containerType, setContainerType] = React.useState<ContainerType>(
    'container'
  )

  const appContext = { containerType, setContainerType }

  return <Provider value={appContext}>{props.children}</Provider>
}
