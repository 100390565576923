import { Button } from '@cimpress/react-components'
import { CSSProperties, default as React, ReactNode } from 'react'

interface Props {
  style?: CSSProperties
  onClick?: (e: any) => void
  children?: ReactNode
  className?: string
}

export default function AnchorButton(props: Props) {
  const disabled = props.onClick ? false : true

  return (
    <Button
      type={'link'}
      className={`${props.className ?? ''}`}
      style={{
        border: 'none',
        outline: 'none',
        padding: 0,
        font: 'inherit',
        backgroundColor: 'Transparent',
        ...props.style,
      }}
      onClick={props.onClick}
      disabled={disabled}
    >
      {props.children}
    </Button>
  )
}
