import { calendars } from '@cimpress-technology/logistics-configuration-client'
import * as jsonPatch from 'fast-json-patch'
import * as uuid from 'uuid'
import auth from '../auth'

export function createPickupCalendar(
  calendar: calendars.models.CreatePickupCalendar,
  correlationId: string
): Promise<string> {
  return calendars.createPickupCalendar(
    auth.getAccessToken(),
    correlationId,
    calendar
  )
}

export function deletePickupCalendar(
  calendarId: string,
  correlationId: string
): Promise<void> {
  return calendars.deletePickupCalendar(
    auth.getAccessToken(),
    correlationId,
    calendarId
  )
}

export function updatePickupCalendar(
  id: string,
  etag: string,
  patch: jsonPatch.Operation[],
  correlationId?: string
): Promise<string> {
  return calendars.updatePickupCalendar(
    auth.getAccessToken(),
    correlationId || uuid.v4(),
    id,
    etag,
    patch
  )
}
