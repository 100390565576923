import * as React from 'react'

const useToggle = (
  initialState = false
): [
  boolean,
  (event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | undefined) => void
] => {
  const [isOn, set] = React.useState(initialState)

  return [
    isOn,
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | undefined) => {
      if (event) {
        event.preventDefault()
      }
      set(!isOn)
    },
  ]
}

export default useToggle
