import * as React from 'react'

export type ContainerType = 'container' | 'main-container'

export interface Context {
  containerType: ContainerType
  setContainerType: (containerType: ContainerType) => void
}

export const Context = React.createContext<Context>({} as Context)

export const Provider = Context.Provider
export const Consumer = Context.Consumer

export function useAppContext() {
  return React.useContext(Context)
}
