import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import { TextField } from '@cimpress/react-components'
import * as React from 'react'

interface Props {
  label: string
  required: boolean
  password: string | carrierAccounts.models.EncryptedString
  bsStyle?: '' | 'success' | 'warning' | 'error' | undefined
  onChange(event: React.FormEvent<HTMLInputElement>): void
}
export default function EncryptedStringField(props: Props) {
  const [isFocused, setFocused] = React.useState(false)

  const onFocus = () => {
    setFocused(true)
  }
  const onBlur = () => {
    setFocused(false)
  }

  return (
    <TextField
      label={props.label}
      type="password"
      required={props.required}
      value={
        typeof props.password === 'string'
          ? props.password
          : isFocused
          ? ''
          : 'password'
      }
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={props.onChange}
      bsStyle={props.bsStyle}
    />
  )
}
