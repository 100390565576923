import { colors } from '@cimpress/react-components'
import * as React from 'react'
import ContextMenu from '../common/components/ContextMenu'
import ContextMenuItem from '../common/components/ContextMenuItem'
import { guessLogo } from '../common/helpers/carrier-logos'
import { ReturnAddress } from '../common/proxy/networks-store'
import { getCountryName } from '../i18n'

interface Props {
  returnAddress: ReturnAddress
  onEdit: (returnAddress: ReturnAddress) => void
  onDelete: (returnAddress: ReturnAddress) => void
}

export default function ReturnAddressCard(props: Props) {
  const logoSrc = guessLogo(props.returnAddress.appliesToCarrier || '')

  const logo = (
    <div
      className="pull-right"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/carrier-logos/${logoSrc})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '56px',
        backgroundColor: colors.white,
        height: '56px',
        width: '56px',
        borderRadius: '50%',
        border: '1px solid var(--alloy)',
      }}
    />
  )
  const onEdit = () => {
    props.onEdit(props.returnAddress)
  }

  const onDelete = () => {
    props.onDelete(props.returnAddress)
  }

  return (
    <div className="card card-minimal" style={{ marginBottom: '32px' }}>
      <div className="card-header">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <div>{props.returnAddress.address.name}</div>
            <div>{props.returnAddress.address.street}</div>
            <div>
              {props.returnAddress.address.postalCode}{' '}
              {props.returnAddress.address.city}
            </div>
            <div>{getCountryName(props.returnAddress.address.country)}</div>
          </div>
          <div>
            <ContextMenu>
              <ContextMenuItem onClick={onEdit} label="Edit" />
              <ContextMenuItem onClick={onDelete} label="Delete" />
            </ContextMenu>
          </div>
        </div>
      </div>
      <div className="card-block">
        <div className="row">
          <div className="col-xs-8">
            <small>Applies only to shipments</small>
            <table>
              <tbody>
                <tr>
                  <td style={{ paddingRight: '8px' }}>
                    <small className="h7">From</small>
                  </td>
                  <td>
                    <small>
                      {props.returnAddress.appliesToWebsite || 'all websites'}
                    </small>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingRight: '8px' }}>
                    <small className="h7">Shipped by</small>
                  </td>
                  <td>
                    <small>{props.returnAddress.appliesToCarrier}</small>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-4">{logo}</div>
        </div>
      </div>
    </div>
  )
}
