import {
  calendars,
  carrierAccounts,
} from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { PickupScheduleView } from '../../calendars/components/weekly/legend/pickup-calendar-editor/PickupScheduleView'
import {
  EnhancedCaasProfile,
  SequenceDefinitionPair,
} from '../../common/models-carriers'
import { getDocumentTypeKeys } from '../../common/proxy/carrier-services-store'
import { showAsUploadSequence } from './sequences/helpers'
import { SequenceView } from './sequences/SequenceView'
import { CarrierConfigurationContainer } from './spec-components/CarrierConfigurationContainer'
import DocumentUpload from './uploads/DocumentUpload'
import style from './ViewCarrierAccount.module.css'

interface Props {
  editAccountLink?: React.ReactNode
  pickupCalendarLink?: React.ReactNode
  editUploadsLink?: React.ReactNode
  editTrackingRangesLink?: React.ReactNode
  carrierAccount: carrierAccounts.models.CreateCarrierAccount
  profile: EnhancedCaasProfile
  sequenceDefinitionPairs: SequenceDefinitionPair[]
  schedules?: calendars.models.PickupWeeklySchedule[]
}

export default function CarrierAccountView(props: Props) {
  const { t } = useTranslation()

  const scheduleCardBlock = props.schedules ? (
    <div className="card" style={{ marginTop: '16px' }}>
      <div className="card-block">
        <h5>{t('common.pickupSchedule')}</h5>
        <PickupScheduleView schedules={props.schedules} />
      </div>
    </div>
  ) : undefined

  return (
    <div className="row" style={{ marginBottom: '32px' }}>
      <div className="col-xs-12 col-sm-7 col-lg-7">
        <div className="card">
          <div className="card-block">
            <div className={style.withRightContent}>
              <h5>{t('common.accountDetails')}</h5>
              {props.editAccountLink}
            </div>
            <CarrierConfigurationContainer
              edit={false}
              accountDetails={props.carrierAccount.accountDetails}
              configSpecs={props.profile.accountConfigSpec!}
            />
            {props.pickupCalendarLink}
          </div>
        </div>
        <div className="card" style={{ marginTop: '16px' }}>
          <div className="card-block">
            <div className={style.withRightContent}>
              <h5>{t('common.documentUploads')}</h5>
              {props.editUploadsLink}
            </div>
            <DocumentUpload
              caasProfileKey={props.carrierAccount.carrierKey}
              edit={false}
              uploads={props.carrierAccount.uploads}
              documentTypeKeys={getDocumentTypeKeys(props.profile)}
            />
            {props.sequenceDefinitionPairs
              .filter(pair => showAsUploadSequence(pair.sequence))
              .map(pair => (
                <SequenceView
                  key={pair.sequence.key}
                  sequenceDefinitionPair={pair}
                />
              ))}
          </div>
        </div>
        <div className="card" style={{ marginTop: '16px' }}>
          <div className="card-block">
            <div className={style.withRightContent}>
              <h5>{t('common.trackingRanges')}</h5>
              {props.editTrackingRangesLink}
            </div>
            {props.sequenceDefinitionPairs
              .filter(
                pair =>
                  !pair.sequence._metadata.isShared &&
                  !pair.sequence._metadata.isDocumentSequence
              )
              .map(pair => (
                <SequenceView
                  key={pair.sequence.key}
                  sequenceDefinitionPair={pair}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-5 col-lg-5">
        <div className="card">
          <div className="card-block">
            <h5>{t('common.carrierServices')}</h5>
            <table className={'table ' + style.carrierServicesTable}>
              <tbody>
                {props.carrierAccount.carrierServiceKeys.map(key => {
                  const carrierService = props.profile.carrierServices.find(
                    cs => cs.key === key
                  )

                  return (
                    <tr key={key}>
                      <td>{carrierService ? carrierService.name : key}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        {scheduleCardBlock}
      </div>
    </div>
  )
}
