import { get } from '../../../common/helpers/deepBracketNotation'
import {
  FieldType,
  ValidatedField,
  validateField,
  ValidationResult,
} from '../../../common/helpers/validation'
import { SequenceRange } from '../../../common/models'

export const rangeValidatedFields: ValidatedField[] = [
  {
    i18nKey: 'validation.sequences.start',
    displayName: 'Start',
    name: 'start',
    required: true,
    type: FieldType.Text,
  },
  {
    i18nKey: 'validation.sequences.end',
    displayName: 'End',
    name: 'end',
    required: true,
    type: FieldType.Text,
  },
]

export function validateAllRangeFields(
  range: SequenceRange
): ValidationResult[] {
  return rangeValidatedFields.map(field =>
    validateField(field, get(range, field.name))
  )
}
