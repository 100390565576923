import * as React from 'react'
import { ReturnAddress } from '../common/proxy/networks-store'
import NoReturnAddresses from './NoReturnAddresses'
import ReturnAddressCard from './ReturnAddressCard'

interface Props {
  returnAddresses: ReturnAddress[]

  onEdit: (returnAddress: ReturnAddress) => void
  onDelete: (returnAddress: ReturnAddress) => void
}

export default function ReturnAddressesList(props: Props) {
  if (props.returnAddresses.length === 0) {
    return <NoReturnAddresses />
  }

  return (
    <div className="row">
      {props.returnAddresses.map(returnAddress => (
        <div
          key={returnAddress.address.name}
          className="col-xs-12 col-sm-6 col-md-4"
        >
          <ReturnAddressCard
            returnAddress={returnAddress}
            onEdit={props.onEdit}
            onDelete={props.onDelete}
          />
        </div>
      ))}
    </div>
  )
}
