import * as queryString from 'query-string'
import * as React from 'react'
import { useHistory, useLocation } from 'react-router'

interface Querystring {
  [key: string]: any
}

export default function useQuerystring(): [
  Querystring,
  (value: Querystring) => void
] {
  const location = useLocation()
  const history = useHistory()
  const valueFromUrl = queryString.parse(location.search) as Querystring

  const [currentValue, setCurrentValue] = React.useState(valueFromUrl)

  const changeValue = React.useCallback(
    (newValue: Querystring) => {
      setCurrentValue(newValue)
      history.replace({
        pathname: location.pathname,
        search: queryString.stringify(newValue || {}),
      })
    },
    [history, location.pathname]
  )

  return [currentValue, changeValue]
}
