import * as React from 'react'
import FourOhFourPage from '../FourOhFourPage'
import Preloader from './Preloader'

export interface Props {
  children?: React.ReactNode
}

interface State<DATA> {
  loading: boolean

  data?: DATA
}

export default <PROPS extends {}, DATA extends {}>(
  TargetComponent: React.ComponentType<PROPS & DATA>,
  dataFunction: (props: PROPS) => Promise<DATA | undefined>,
  NotFoundComponent?: React.ComponentType<{}>
): React.ComponentType<PROPS> =>
  class LoaderContainer extends React.Component<PROPS, State<DATA>> {
    public readonly state: State<DATA> = { loading: false }
    private mounted = false

    public componentDidMount() {
      this.mounted = true
      this.setState({ loading: true })
      dataFunction(this.props).then(data => {
        if (this.mounted) {
          this.setState({
            loading: false,
            data,
          })
        }
      })
    }

    public componentWillUnmount() {
      this.mounted = false
    }

    public render() {
      const NotFound = NotFoundComponent || FourOhFourPage

      if (this.state.loading) {
        return <Preloader />
      }
      if (!this.state.data) {
        return <NotFound />
      }

      const { data } = this.state

      return <TargetComponent {...this.props} {...data} />
    }
  }
