import { Select } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { GroupBy, Option } from './models'

interface Props {
  groupBy: GroupBy
  groupByOptions: Option[]
  onChange?: (change: Option) => void
}

export default function GroupByDropdown(props: Props) {
  const { t } = useTranslation()

  return (
    <div className="location-groupby-dropdown--" style={{ flexGrow: 1 }}>
      <Select
        label={t('locations.selectLocationPage.groupBy')}
        value={props.groupByOptions.filter(o => o.value === props.groupBy)}
        options={props.groupByOptions}
        isClearable={false}
        isSearchable={false}
        required={false}
        onChange={props.onChange}
      />
    </div>
  )
}
