import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import { Toggle } from '@cimpress/react-components'
import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation } from 'react-i18next'
import { Link, useRouteMatch } from 'react-router-dom'
import {
  EnhancedCaasProfile,
  SequenceDefinitionPair,
} from '../../common/models-carriers'

import { useLogisticsLocation } from '../../locations/LocationContext'
import CarrierAccountView from './CarrierAccountView'
import style from './ViewCarrierAccount.module.css'

export interface PreloadedData {
  carrierAccount: carrierAccounts.models.CarrierAccount
  profile: EnhancedCaasProfile
  sequenceDefinitionPairs: SequenceDefinitionPair[]
}

interface Props extends PreloadedData {
  children?: React.ReactNode
  updateCarrierAccountName?: (updatedValue: string) => void
}

export default function ViewCarrierAccount(props: Props) {
  const { t } = useTranslation()
  const { logisticsLocation } = useLogisticsLocation()
  const match = useRouteMatch()

  const editAccountLink = (
    <Link to={`${match.url}/edit-account`} className={style.rightContent}>
      {t('common.change')}
    </Link>
  )
  const editUploadsLink = (
    <Link to={`${match.url}/edit-uploads`} className={style.rightContent}>
      {t('common.change')}
    </Link>
  )
  const editTrackingRangesLink = (
    <Link to={`${match.url}/edit-sequences`} className={style.rightContent}>
      {t('common.change')}
    </Link>
  )

  return (
    <main className="App-content container flex-vertical">
      <BreadcrumbsItem to="/">{t('common.logisticsLocations')}</BreadcrumbsItem>
      <BreadcrumbsItem to={`/location/${logisticsLocation.id}`}>
        {logisticsLocation.name}
      </BreadcrumbsItem>
      <BreadcrumbsItem
        to={`/location/${logisticsLocation.id}/carrier-accounts`}
      >
        {t('common.carrierAccounts')}
      </BreadcrumbsItem>
      <BreadcrumbsItem to="#">{props.carrierAccount.name}</BreadcrumbsItem>
      <div className="row vertical-align" style={{ marginBottom: '8px' }}>
        <div className="col-xs-9 col-lg-9">
          <div>
            <h3>{props.carrierAccount.name}</h3>
          </div>
        </div>
        <div className="col-xs-3 col-lg-3">
          <div className="text-right">
            <span style={{ marginRight: '16px' }}>
              {t('carrierAccounts.usedForShipping')}
            </span>
            <Toggle on={true} />
          </div>
        </div>
      </div>
      <CarrierAccountView
        carrierAccount={props.carrierAccount}
        sequenceDefinitionPairs={props.sequenceDefinitionPairs}
        profile={props.profile}
        editAccountLink={editAccountLink}
        editUploadsLink={editUploadsLink}
        editTrackingRangesLink={editTrackingRangesLink}
      />
    </main>
  )
}
