import * as React from 'react'

interface Props {
  href: string | undefined
  children: React.ReactNode
  className?: string
}

export default function OpenInNewTab(props: Props) {
  const { children, ...rest } = props

  return (
    <a {...rest} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}
