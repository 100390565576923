import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Option } from '../../../common/models'
import styles from './UploadView.module.css'

interface Props {
  documentTypes: string[]
  documentTypeList: Option[]
}
export default function DocumentTypeView(props: Props) {
  const { t } = useTranslation()
  const tags = props.documentTypes
    .map(documentType => {
      const o = props.documentTypeList.find(
        option => option.value === documentType
      )

      return o || { label: documentType, value: documentType }
    })
    .map(documentType => (
      <span key={documentType.value} className="tag">
        {documentType.label}
      </span>
    ))

  return (
    <div className={styles.uploadView}>
      <div>{t('carrierAccounts.uploads.documentTypes')}</div>
      <div>{tags}</div>
    </div>
  )
}
