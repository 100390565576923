import { calendars } from '@cimpress-technology/logistics-configuration-client'
import { ButtonGroup } from '@cimpress/react-components'
import * as React from 'react'
import styles from './DayOfTheWeekSelector.module.css'
import { weekdays } from './weekly/legend/pickup-calendar-editor/schedule-helpers'

interface Props {
  weeklySchedule: calendars.models.WeekdayData<boolean>
  onChangeSchedule(schedule: calendars.models.WeekdayData<boolean>): void
}

export default function DayOfTheWeekSelector(props: Props) {
  const onDayClick = (day: string) => {
    const newSchedule = { ...props.weeklySchedule }
    newSchedule[day] = !props.weeklySchedule[day]
    props.onChangeSchedule(newSchedule)
  }

  return (
    <div className={styles.dowSelector}>
      <ButtonGroup type="justified">
        {weekdays.map(weekday => {
          const onClick = () => onDayClick(weekday)

          return (
            <DayButton
              key={weekday}
              weekday={weekday}
              enabled={props.weeklySchedule[weekday]}
              onClick={onClick}
            />
          )
        })}
      </ButtonGroup>
    </div>
  )
}

interface DayButtonProps {
  weekday: string
  enabled: boolean
  onClick: () => void
}

function DayButton(props: DayButtonProps) {
  const enabled = props.enabled ? 'enabled' : ''

  return (
    <button
      name={props.weekday}
      className={`btn ${styles.dayButton} ${enabled}`}
      onClick={props.onClick}
    >
      {props.weekday.charAt(0).toUpperCase() + props.weekday.charAt(1)}
    </button>
  )
}
