import { get } from '../common/helpers/deepBracketNotation'
import {
  FieldType,
  ValidatedField,
  validateField,
  ValidationResult,
  ValidationStatus,
} from '../common/helpers/validation'
import { ConfigSpec } from '../common/models-carriers'
import { SpecVisitor, traverse } from './specVisitor'

const ValidationSpecVisitor = (
  validatedFields: ValidatedField[],
  rootPath: string
): SpecVisitor => {
  const visit = (spec: ConfigSpec) => {
    validatedFields.push({
      displayName: spec.name,
      name: rootPath !== '' ? `${rootPath}/${spec.key}` : spec.key,
      required:
        spec.value.isRequired === undefined ? false : spec.value.isRequired!,
      type: FieldType.Text,
    })
  }

  return {
    visitObject: (spec: ConfigSpec) => {
      const path = rootPath !== '' ? `${rootPath}/${spec.key}` : spec.key
      if (spec.value.items) {
        spec.value.items!.forEach(item =>
          traverse(item, ValidationSpecVisitor(validatedFields, path))
        )
      }
    },
    visitBoolean: visit,
    visitString: visit,
    visitNumber: visit,
  }
}

export function getValidationErrors(
  configSpecs: ConfigSpec[],
  accountDetails: any
) {
  return getValidatedFields(configSpecs)
    .map(field => validateField(field, get(accountDetails, field.name, '/')))
    .filter(result => result.status === ValidationStatus.Invalid)
}

export function getValidatedFields(specs: ConfigSpec[]) {
  const main: ConfigSpec = {
    key: '',
    name: '',
    value: {
      valueType: 'object',
      items: specs,
    },
  }

  const validatedFields: ValidatedField[] = []

  const validationSpecVisitor = ValidationSpecVisitor(validatedFields, '')

  traverse(main, validationSpecVisitor)

  return validatedFields
}

export function validateCarrierAccountName(
  name: string,
  existingCarrierAccountNames: string[]
): ValidationResult {
  if (
    existingCarrierAccountNames.find(
      caName =>
        caName.localeCompare(name, undefined, { sensitivity: 'base' }) === 0
    )
  ) {
    return {
      fieldName: 'name',
      status: ValidationStatus.Invalid,
      message: `Carrier account name '${name}' is already being used.`,
    }
  }

  return validateField(
    {
      displayName: 'Carrier account name',
      name: 'name',
      required: true,
      type: FieldType.Text,
    },
    name
  )
}
