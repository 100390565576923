import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import axios from 'axios'
import {
  BaseCaasProfile,
  CarrierIdentity,
  EmailAddress,
} from '../models-carriers'

const CARRIERS_URL = 'https://carriers.logistics.cimpress.io/api/v1/carriers'

export async function getCaasProfiles(
  accessToken: string
): Promise<ApiCaasProfile[]> {
  const caasLinks: string[] = (
    await axios.get<{ carriers: CarrierIdentity[] }>(CARRIERS_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  ).data.carriers.map(x => x.key)

  const results = (
    await Promise.all(
      caasLinks.map(async key => {
        try {
          return await getCaasProfile(key, accessToken)
        } catch (e) {
          return undefined
        }
      })
    )
  ).filter(x => x !== undefined)

  return results.map(toApiCaasProfile)
}

export async function getCaasProfile(
  key: string,
  accessToken: string
): Promise<ApiCaasProfile> {
  const profile = (
    await axios.get(`${CARRIERS_URL}/${key}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  ).data

  return toApiCaasProfile(profile)
}

export interface ApiCaasProfile extends BaseCaasProfile {
  documentUploadMethods?: Array<{
    documentTypeKeys: string[]
    uploadMethods: DefaultUploadMethods
  }>
}

function toApiCaasProfile(profile: any): ApiCaasProfile {
  return {
    ...profile,
    sequences: (profile.sequences || []).map((seq: any) => ({
      ...seq,
      _metadata: {
        isDocumentSequence: false,
        isHidden: false,
      },
    })),
  }
}

export interface DefaultUploadMethods {
  ftp?: DefaultFtpConfig[]
  email?: DefaultEmailConfig[]
  elms?: DefaultElmsPldConfig[]
  pld?: DefaultElmsPldConfig[]
}

interface DefaultUploadConfig {
  isHidden?: boolean
}

export interface DefaultFtpConfig extends DefaultUploadConfig {
  secure?: boolean
  ftpType?: string
  host?: string
  directory?: string
  moveToDirectory?: string
  username?: string
  password?: carrierAccounts.models.EncryptedString
  port?: number
  runInParallel?: boolean
  authenticationMethod?: 'password' | 'key'
}

export interface DefaultElmsPldConfig extends DefaultUploadConfig {
  url?: string
  username?: string
  password?: carrierAccounts.models.EncryptedString
  runInParallel?: boolean
}

export interface DefaultEmailConfig extends DefaultUploadConfig {
  receivers?: {
    to?: EmailAddress[]
    cc?: EmailAddress[]
    bcc?: EmailAddress[]
  }
  subject?: string
  content?: string[]
  template?: string
  runInParallel?: boolean
}
