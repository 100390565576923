import React from 'react'
import { SequenceDefinition } from '../../../common/models'
import { Sequence } from '../../../common/models-carriers'
import { showAsTrackingSequence, showAsUploadSequence } from './helpers'
import { SequenceEditor } from './SequenceEditor'

interface Props {
  sequences: Sequence[]
  sequenceTypeToShow: 'upload' | 'tracking'

  sequenceDefinitions: SequenceDefinition[]
  areValid: boolean[]

  onSequenceDefinitionChange: (
    sequenceDefinitions: SequenceDefinition[],
    areValid: boolean[]
  ) => void
}

export default function MultipleSequencesEditor(props: Props) {
  const onChange = (
    sequenceDefinition: SequenceDefinition,
    index: number,
    isValid?: boolean
  ) => {
    const updatedSequenceDefinitions = props.sequenceDefinitions.slice()
    updatedSequenceDefinitions[index] = sequenceDefinition
    const updatedValid = props.areValid.slice()
    updatedValid[index] = isValid!

    props.onSequenceDefinitionChange(updatedSequenceDefinitions, updatedValid)
  }

  const sequencesRendered = props.sequences.map((sequence, index) => {
    if (sequence._metadata.isShared) {
      return undefined
    }

    const showSequence =
      props.sequenceTypeToShow === 'upload'
        ? showAsUploadSequence(sequence)
        : showAsTrackingSequence(sequence)

    if (!showSequence) {
      return undefined
    }

    return (
      <SequenceEditor
        key={sequence.key}
        sequence={sequence}
        initialState={props.sequenceDefinitions[index]}
        onChange={onChange}
        index={index}
      />
    )
  })

  if (sequencesRendered.every(s => s === undefined)) {
    return null
  }

  return <>{sequencesRendered}</>
}
