import { isValidNumber } from 'libphonenumber-js'

export enum ValidationStatus {
  NotValidated = '',
  Valid = 'success',
  Invalid = 'error',
}

export interface ValidationResult {
  fieldName: string
  message?: string
  status: ValidationStatus
  translationKey?: string
}

export enum FieldType {
  Email,
  Text,
  Phone,
}

export interface ValidatedField {
  i18nKey?: string
  displayName?: string
  name: string
  required: boolean
  type: FieldType
}

const emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function validateList(validatedField: ValidatedField, values?: any[]) {
  if (values === null || values === undefined || values.length === 0) {
    return {
      fieldName: validatedField.name,
      message: `${validatedField.displayName} is required`,
      status: ValidationStatus.Invalid,
      translationKey:
        validatedField.i18nKey && `${validatedField.i18nKey}.required`,
    }
  }

  return {
    fieldName: validatedField.name,
    status: ValidationStatus.Valid,
  }
}

export function validateField(
  validatedField: ValidatedField,
  value: any
): ValidationResult {
  value = typeof value === 'string' ? value.trim() : value

  if (
    validatedField.required &&
    (value === null || value === undefined || value === '')
  ) {
    // TODO: deal with `is required` translation
    return {
      fieldName: validatedField.name,
      message: `${validatedField.displayName} is required`,
      status: ValidationStatus.Invalid,
      translationKey:
        validatedField.i18nKey && `${validatedField.i18nKey}.required`,
    }
  }
  if (value === '') {
    return {
      fieldName: validatedField.name,
      status: ValidationStatus.NotValidated,
    }
  }
  switch (validatedField.type) {
    case FieldType.Text:
      return {
        fieldName: validatedField.name,
        status: ValidationStatus.Valid,
      }
    case FieldType.Email:
      if (value.match(emailRegex)) {
        return {
          fieldName: validatedField.name,
          status: ValidationStatus.Valid,
        }
      } else {
        return {
          fieldName: validatedField.name,
          message: `Please provide a valid email address as name@domain.com`,
          status: ValidationStatus.Invalid,
          translationKey: 'validation.email.notValid',
        }
      }
    case FieldType.Phone:
      if (isValidNumber(value)) {
        return {
          fieldName: validatedField.name,
          status: ValidationStatus.Valid,
        }
      } else {
        return {
          fieldName: validatedField.name,
          message: `Please provide a valid phone number`,
          status: ValidationStatus.Invalid,
          translationKey: 'validation.phone.notValid',
        }
      }
  }
}

export function toBSStyle(
  validationResult: ValidationResult
): 'success' | 'error' | undefined {
  switch (validationResult.status) {
    case ValidationStatus.Valid:
      return 'success'
    case ValidationStatus.Invalid:
      return 'error'
    case ValidationStatus.NotValidated:
      return undefined
  }
}
