import * as countries from 'i18n-iso-countries'
import deLocale from 'i18n-iso-countries/langs/de.json'
import enLocale from 'i18n-iso-countries/langs/en.json'
import frLocale from 'i18n-iso-countries/langs/fr.json'
import itLocale from 'i18n-iso-countries/langs/it.json'
import nlLocale from 'i18n-iso-countries/langs/nl.json'
import i18n from 'i18next'
import moment from 'moment-timezone'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/tr'
import { getI18n, initReactI18next } from 'react-i18next'
import de from './common/languages/de.json'
import en from './common/languages/en.json'
import fr from './common/languages/fr.json'
import it from './common/languages/it.json'
import nl from './common/languages/nl.json'

const locales = {
  en: enLocale,
  fr: frLocale,
  it: itLocale,
  de: deLocale,
  nl: nlLocale,
}

const resources = {
  en: { translation: en },
  fr: { translation: fr },
  it: { translation: it },
  de: { translation: de },
  nl: { translation: nl },
}
export const supportedLanguages = ['en', 'fr', 'it', 'de', 'nl']

moment.locale('en')

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng) => {
        if (moment.isMoment(value)) {
          return moment(value)
            .locale(lng || 'en')
            .format(format)
        }

        if (value instanceof Date) {
          return moment(value)
            .locale(lng || 'en')
            .format(format)
        }

        return value
      },
    },
  })

Object.values(locales).forEach(locale => countries.registerLocale(locale))

export const getCurrentLang = (): string => getI18n().languages[0]
export const getCountryName = (countryCode: string): string =>
  countries.getName(countryCode, getCurrentLang())

export default i18n
