import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import { Uploads } from '../../../common/models'

export function getDefaultUploads(
  uploadType: carrierAccounts.models.UploadType
): Uploads {
  switch (uploadType) {
    case 'ftp':
    case 'ftps':
    case 'sftp':
      return {
        uploadType,
        runInParallel: true,
        documentTypes: [],
        host: '',
        username: '',
        password: '',
        directory: '',
        afterUploadMoveToDirectory: '',
        port: uploadType === 'sftp' ? 22 : 21,
        authenticationMethod: 'password',
      }
    case 'email':
      return {
        uploadType,
        runInParallel: true,
        documentTypes: [],
        subject: '',
        template: '',
        receivers: { to: [], cc: [], bcc: [] },
      }
    case 'elms':
      return {
        uploadType,
        runInParallel: true,
        documentTypes: [],
        url: '',
        username: '',
        password: '',
      }
    case 'pld':
      return {
        uploadType,
        runInParallel: true,
        documentTypes: [],
        url: '',
        username: '',
        password: '',
      }
  }

  throw new Error('Unknown upload type')
}
