import * as React from 'react'
import { useTranslation } from 'react-i18next'
import AnchorButton from '../../../common/components/AnchorButton'
import useToggle from '../../../common/hooks/useToggle'
import EditLocationButton from '../../../locations/components/view-location-page/EditLocationButton'
import PrimaryContact from '../../../locations/components/view-location-page/PrimaryContact'
import { useLogisticsLocation } from '../../LocationContext'
import RegionUnits from '../RegionUnits'
import Address from '../../../common/components/Address'
import Network from './../../../common/components/Network'
import styles from './FulfillerOverview.module.css'

export default function OverviewLocationDetail() {
  const [isExpandedView, toggleItems] = useToggle()

  const { t } = useTranslation()
  const { logisticsLocation } = useLogisticsLocation()

  const fulfillmentLocations = logisticsLocation.fulfillmentLocations
    .map(loc => ({
      fulfillmentLocationId: loc.id,
      fulfillmentLocationName: loc.name,
      fulfillerId: loc.fulfiller.fulfillerId,
      fulfillerName:
        loc.fulfiller.name ||
        `${t('common.fulfillers.fulfiller')} ${loc.fulfiller.fulfillerId}`,
      isArchived: loc.isArchived,
    }))
    .sort((a, b) => {
      if (a.isArchived) {
        return 1
      }
      if (b.isArchived) {
        return -1
      }

      return `${a.fulfillerName} ${a.fulfillmentLocationName}`.localeCompare(
        `${b.fulfillerName} ${b.fulfillmentLocationName}`
      )
    })

  const view = isExpandedView ? (
    <>
      <div className="card-item">
        <Network />
      </div>
      <div className="card-item">
        <Address address={logisticsLocation.address} />
      </div>
      <div className="card-item">
        <h5>
          {t('common.fulfillers.fulfillmentLocation', {
            count: logisticsLocation.fulfillmentLocations.length,
          })}
        </h5>
        <ul>
          {fulfillmentLocations.map(fl => {
            const flName = `${fl.fulfillerName} (${fl.fulfillerId}) • ${fl.fulfillmentLocationName} (${fl.fulfillmentLocationId})`
            const formatted = fl.isArchived ? <i>{flName}</i> : flName

            return <li key={fl.fulfillmentLocationId}>{formatted}</li>
          })}
        </ul>
      </div>
      <div className="card-item">
        <RegionUnits localeSettings={logisticsLocation.localeSettings} />
      </div>
      <AnchorButton onClick={toggleItems}>
        {t('fulfillerOverview.showLess')} <i className="fa fa-lg fa-angle-up" />
      </AnchorButton>
    </>
  ) : (
    <AnchorButton onClick={toggleItems}>
      {t('fulfillerOverview.showMore')} <i className="fa fa-lg fa-angle-down" />
    </AnchorButton>
  )

  return (
    <div className={`${styles.card} card card-minimal`}>
      <div className="card-header">
        <h5
          className="card-title pull-left"
          style={{ display: 'inline-block' }}
        >
          {t('fulfillerOverview.aboutLocation')}
        </h5>
        <div className="text-right pull-right">
          {logisticsLocation.editable ? (
            <EditLocationButton
              path={`/location/${logisticsLocation.id}/edit`}
            />
          ) : null}
        </div>
      </div>
      <div className={'card-block'}>
        <div className="card-item">
          <PrimaryContact contact={logisticsLocation.contact} />
        </div>
        {view}
      </div>
    </div>
  )
}
