export function anyWholeWordStartsWithIgnoringCase(
  subject: string,
  search: string
): boolean {
  const regexp = getRegExp(search)

  return regexp.test(subject)
}

export function anyWholeWordStartsWithIgnoringCaseIndex(
  subject: string,
  search: string
): RegExpExecArray | null {
  const regexp = getRegExp(search)

  return regexp.exec(subject)
}

function getRegExp(search: string) {
  const sanitized = search.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&')

  return new RegExp('(^|\\s)' + sanitized, 'i')
}
