import { Select } from '@cimpress/react-components'
import { useField } from 'formik'
import * as countries from 'i18n-iso-countries'
import * as React from 'react'
import { getCountryName, getCurrentLang } from '../../i18n'
import { CountryFlag } from './CountryFlag'
import ValidationErrorMessage from './ValidationErrorMessage'

export const countryOptions = Object.keys(
  countries.getNames(getCurrentLang())
).map(countryCode => ({
  label: getCountryName(countryCode),
  value: countryCode,
  countryName: getCountryName(countryCode).toLowerCase(),
}))

const filterCountryOption = (option: { data: Option }, search: string) => {
  return (
    option.data.countryName.includes(search) ||
    option.data.countryName.split(' ').some(x => x.startsWith(search))
  )
}

interface Props {
  name: string
  required: boolean
  label?: string
  includeAll?: boolean
  bsStyle?: '' | 'success' | 'warning' | 'error'
  selectReference?: React.RefObject<any>
}

interface Option {
  label: any
  value: string
  countryName: string
}

export const WorldWide = 'WorldWide'

const allCountries = {
  label: 'All Countries',
  value: WorldWide,
  countryName: 'all countries',
}

const countryOptionsWithAll = [allCountries, ...countryOptions]

export default function CountryPicker(props: Props) {
  const [field, meta, helpers] = useField(props.name)
  const options = props.includeAll ? countryOptionsWithAll : countryOptions

  const onChange = (data: Option) => {
    helpers.setValue(data ? data.value : '')
  }

  const onBlur = () => {
    helpers.setTouched(true)
  }

  const label = props.label ? props.label : 'Country'

  return (
    <div className="form-group">
      <Select
        label={label}
        value={options.filter(o => o.value === field.value)}
        formatOptionLabel={formatOptionLabel}
        options={options}
        onChange={onChange}
        filterOption={filterCountryOption}
        required={props.required}
        onBlur={onBlur}
        ref={props.selectReference}
        resetValue={props.includeAll ? allCountries : undefined}
        isClearable={props.includeAll && allCountries.value !== field.value}
      />
      <ValidationErrorMessage message={meta.error} />
    </div>
  )
}

function formatOptionLabel(option: Option, labelMeta: any) {
  if (option.value === WorldWide) {
    return option.label
  }

  return (
    <>
      <CountryFlag countryCode={option.value} />
      {option.label}
    </>
  )
}
