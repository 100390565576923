import { TFunction } from 'i18next'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ClickableTableRow } from '../../../common/components/ClickableTableRow'
import { CountryFlag } from '../../../common/components/CountryFlag'
import Highlight from '../../../common/components/Highlight'
import * as models from '../../../common/models'
import { getCountryName } from '../../../i18n'

interface Props {
  locations: models.Location[]
  searchQuery: string
}

interface LocationWithFulfiller {
  fulfiller: models.Fulfiller
  location: models.Location
}

export default function LocationsListGroupByFulfiller(props: Props) {
  const { t } = useTranslation()
  if (props.locations.length === 0) {
    return <h5>{t('locations.selectLocationPage.noLocationFound')}</h5>
  }

  const fulfillers = groupBy(props.locations, t)

  return (
    <>
      {fulfillers.map(fulfiller => (
        <div
          key={'Fulfiller ' + fulfiller[0].fulfiller.fulfillerId}
          style={{ marginBottom: '20px' }}
        >
          <h5>{fulfiller[0].fulfiller.name}</h5>
          <div className="panel">
            <table className="table table-hover" style={{ marginBottom: 0 }}>
              <tbody>
                {fulfiller
                  .sort((l1, l2) =>
                    l1.location.name.localeCompare(l2.location.name, 'en')
                  )
                  .map(locationWithFulfiller => (
                    <Row
                      key={locationWithFulfiller.location.id}
                      locationWithFulfiller={locationWithFulfiller}
                      searchQuery={props.searchQuery}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </>
  )
}

function groupBy(
  locations: models.Location[],
  t: TFunction
): LocationWithFulfiller[][] {
  const withoutFls: LocationWithFulfiller[] = []
  const fulfillersMap = locations.reduce((acc, location) => {
    if (location.fulfillmentLocations.length === 0) {
      withoutFls.push({
        fulfiller: {
          fulfillerId: 'without-fulfiller',
          name: t('locations.selectLocationPage.withoutFulfiller'),
        },
        location,
      })

      return acc
    }
    location.fulfillmentLocations.forEach(fl => {
      if (!acc.has(fl.fulfiller.fulfillerId)) {
        acc.set(fl.fulfiller.fulfillerId, [])
      }
      const groupedLocations = acc.get(fl.fulfiller.fulfillerId)!
      if (
        groupedLocations.findIndex(l => l.location.id === location.id) === -1
      ) {
        groupedLocations.push({ fulfiller: fl.fulfiller, location })
      }
    })

    return acc
  }, new Map<string, LocationWithFulfiller[]>())

  const fulfillers = Array.from(
    fulfillersMap,
    ([_, value]) => value
  ).sort((a, b) => a[0].fulfiller.name!.localeCompare(b[0].fulfiller.name!))
  if (withoutFls.length !== 0) {
    fulfillers.push(withoutFls)
  }

  return fulfillers
}

interface RowProps {
  locationWithFulfiller: LocationWithFulfiller
  searchQuery: string
}

function Row(props: RowProps): JSX.Element {
  const href = '/location/' + props.locationWithFulfiller.location.id

  return (
    <ClickableTableRow href={href}>
      <td style={{ width: '30%' }}>
        <a href={href}>
          <Highlight
            text={props.locationWithFulfiller.location.name}
            highlight={props.searchQuery}
          />
        </a>
      </td>
      <td>
        <span className="h4">
          <CountryFlag
            countryCode={
              props.locationWithFulfiller.location.address.countryCode
            }
          />
        </span>
        {getCountryName(
          props.locationWithFulfiller.location.address.countryCode
        )}
      </td>
    </ClickableTableRow>
  )
}
