import * as React from 'react'
import IconIdCard from '@cimpress-technology/react-streamline-icons/lib/IconIdCard'
export default function NoReturnAddresses(): JSX.Element {
  return (
    <div className="row">
      <div style={{ textAlign: 'center' }}>
        <div>
          <IconIdCard style={{ height: '125px', fill: 'var(--alloy)' }} />
        </div>
        <span style={{ fontSize: '16px', color: 'var(--shale)' }}>
          You haven't added any return addresses for this network.
        </span>
      </div>
    </div>
  )
}
