import * as React from 'react'
import { useTranslation } from 'react-i18next'
import IconDeliveryTruckClock from '@cimpress-technology/react-streamline-icons/lib/IconDeliveryTruckClock'
import IconCommonFileStack from '@cimpress-technology/react-streamline-icons/lib/IconCommonFileStack'
import { useLogisticsLocation } from '../../LocationContext'
import { config } from '../../../common/config/config'
import styles from './ConfigureCard.module.css'
import NavigationExternalLink from './NavigationExternalLink'

export default function OperationsCard() {
  const { t } = useTranslation()
  const { logisticsLocation } = useLogisticsLocation()
  const manifestingUrl = config.truckBasedManifestingLocations.includes(
    logisticsLocation.id
  )
    ? `${config.shippingOperations}/manifests`
    : config.smc

  return (
    <div className="card card-minimal">
      <div className="card-header">
        <h5 className="card-title">
          {t('locations.viewLocationPage.operations')}
        </h5>
      </div>
      <div className={`${styles.configuration} card-block`}>
        <ul className="list-group">
          <NavigationExternalLink
            url={config.tracking}
            text={t('locations.viewLocationPage.shipmentTracking')}
            icon={IconDeliveryTruckClock}
          />
          <NavigationExternalLink
            url={manifestingUrl}
            text={t('fulfillerOverview.viewCreateManifests')}
            icon={IconCommonFileStack}
          />
        </ul>
      </div>
    </div>
  )
}
