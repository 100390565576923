import { locations } from '@cimpress-technology/logistics-configuration-client'
import Icon from '@cimpress-technology/react-streamline-icons/lib/IconEmailActionUnread'
import { Table } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Preloader from '../../common/components/Preloader'
import styles from './LocationsTable.module.css'

interface Row {
  contact: locations.models.Contact
  id: string
  name: string
}

interface Props {
  locations?: locations.models.LocationWithLinks[]
}

export default function LocationsTable(props: Props) {
  const { t } = useTranslation()

  if (!props.locations) {
    return <Preloader />
  }

  const columns = [
    {
      Header: (
        <span className={styles.withSortable}>{t('locations.location')}</span>
      ),
      id: 'location',
      accessor: (row: Row) => row,
      Cell: (cellProps: { value: Row }) => (
        <Link to={`/location/${cellProps.value.id}`}>
          {cellProps.value.name}
        </Link>
      ),
      sortMethod: (a: Row, b: Row) => a.name.localeCompare(b.name),
    },
    {
      Header: '',
      id: 'actions',
      width: 60,
      sortable: false,
      accessor: (location: Row) => {
        return location.contact && location.contact.email ? (
          <a
            className="pull-right"
            href={`mailto:${location.contact.email}`}
            target="blank"
          >
            <Icon size="lg" />
          </a>
        ) : (
          ''
        )
      },
    },
  ]

  const data: Row[] = props.locations.map(l => {
    return {
      contact: l.contact,
      id: l.id,
      name: l.name,
    }
  })

  return (
    <Table
      noDataText={t('locations.noLocationsFound')}
      pageSize={data.length ? data.length : undefined}
      resizable={false}
      sortable={true}
      showPagination={false}
      columns={columns}
      data={data}
      defaultSorted={[{ id: 'location', desc: false }]}
    />
  )
}
