import { TextField } from '@cimpress/react-components'
import * as React from 'react'
import { ValidationContainer } from '../../../common/components/ValidationContainer'
import { ValidationStatus } from '../../../common/helpers/validation'
import { SpecProps } from './specProps'

interface Props extends SpecProps {
  value: string
}
export default function StringSpecComponent(props: Props) {
  const [isFocused, setFocused] = React.useState(false)

  const onFocus = () => {
    setFocused(true)
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    props.onChange!({ path: props.spec.key, value: e.currentTarget.value })
  }

  const onBlur = (_: any) => {
    props.onBlur!(props.spec.key)
    setFocused(false)
  }

  const validationResult = props.onValidate!(props.spec.key, props.spec)

  const isObject = typeof props.value === 'object'
  const initialValue = isFocused ? '' : 'password'

  const value = isObject ? initialValue : props.value

  return (
    <ValidationContainer validationResult={validationResult}>
      <TextField
        value={value}
        label={props.spec.name}
        onChange={handleChange}
        helpText={props.spec.description}
        required={props.spec.value.isRequired}
        onBlur={onBlur}
        onFocus={onFocus}
        bsStyle={
          validationResult.status === ValidationStatus.Invalid
            ? 'error'
            : undefined
        }
        type={props.spec.value.isSensitive ? 'password' : 'text'}
        ref={props.specRef}
      />
    </ValidationContainer>
  )
}
