import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { throughContainer } from 'react-through'

const breadcrumbsThroughArea = 'breadcrumbs'

interface TitleProps {
  titles?: string[]
}

function Title(props: TitleProps) {
  const { t } = useTranslation()

  return (
    <Helmet>
      <title>{[...(props.titles || []), t('title')].join(' - ')}</title>
    </Helmet>
  )
}

interface BreadcrumbsTitleProps {
  compare: (a: any, b: any) => number
}

function BreadcrumbsTitle_(props: BreadcrumbsTitleProps) {
  const data = props[breadcrumbsThroughArea]
  const itemsValue = Object.keys(data)
    .map(k => data[k])
    .sort(props.compare)
    .map(i => i.children)
    .reverse()
  if (itemsValue.length === 0) {
    return null
  }

  return <Title titles={itemsValue} />
}

export const BreadcrumbsTitle = throughContainer(breadcrumbsThroughArea)(
  BreadcrumbsTitle_
)
